import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import transparentize from "polished/lib/color/transparentize";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import CloseIcon from "@finanzchef24gmbh/design-system/src/Icons/CloseIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockNotificationBarProps } from ".";
import { trackBlockNotificationBar } from "../../../utils/trackingUtils";
import Button from "../../Button";
import Center from "../../Center";
import { GlobalStateContext } from "../../GlobalStateContextProvider";

const StyledSpacingsInsetSquish = styled(Spacings.InsetSquish)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: ${(props) => props.theme.palette.brand[100]};
  border-top: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.gray[100]};
  box-shadow: 0 0 6px 0
    ${(props) => transparentize(0.85, props.theme.palette.black)};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledCenter = styled(Center)`
  position: relative;
`;

const StyledParagraph = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -${(props) => props.theme.spacings.small};
  margin: auto 0;
  cursor: pointer;
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledParagraph>
        {children.filter(
          (child: React.ReactNodeArray | string) => child !== "",
        )}
      </StyledParagraph>
    ),
  },
};

const BottomStickyBar: React.FC<BlockNotificationBarProps> = (props) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showNotificationBar, setShowNotificationBar] = useState(true);

  const closeNotificationBar = () => {
    setShowNotificationBar(false);
    trackBlockNotificationBar("close");
  };

  useEffect(() => {
    setGlobalState({
      ...globalState,
      hideStickyHeader: {
        onDesktop: true,
        onMobile: false,
      },
    });
  }, []);

  return showNotificationBar ? (
    <div>
      <StyledSpacingsInsetSquish scale="medium">
        <StyledCenter intrinsic>
          <Spacings.Inline scale="medium" alignItems="center">
            {props.contentBlock.icon?.file?.url && (
              <img src={props.contentBlock.icon.file.url} alt="" />
            )}

            {props.contentBlock.richText?.json &&
              documentToReactComponents(
                props.contentBlock.richText.json,
                options,
              )}

            {props.contentBlock.button && (
              <Button
                content={{ ...props.contentBlock.button }}
                scale="small"
                trackLink={() =>
                  trackBlockNotificationBar(
                    props.contentBlock.button?.buttonLink,
                  )
                }
              />
            )}
          </Spacings.Inline>

          <StyledCloseIcon onClick={closeNotificationBar} />
        </StyledCenter>
      </StyledSpacingsInsetSquish>
    </div>
  ) : null;
};

export default BottomStickyBar;
