import {
  BlockTableOfContents_ContentBlocksFragment_ContentfulBlockDownloads_Fragment,
  BlockTableOfContents_ContentBlocksFragment_ContentfulBlockFaq_Fragment,
  BlockTableOfContents_ContentBlocksFragment_ContentfulBlockMediaItemListsCard_Fragment,
  BlockTableOfContents_ContentBlocksFragment_ContentfulBlockParagraph_Fragment,
  BlockTableOfContents_ContentBlocksFragmentFragment,
} from "../../../../../types/graphql-types";

export const isListOfBlocksWithTableOfContentsInfo = (
  contentBlocks: Array<BlockTableOfContents_ContentBlocksFragmentFragment | null>,
): contentBlocks is Array<
  | BlockTableOfContents_ContentBlocksFragment_ContentfulBlockMediaItemListsCard_Fragment
  | BlockTableOfContents_ContentBlocksFragment_ContentfulBlockParagraph_Fragment
  | BlockTableOfContents_ContentBlocksFragment_ContentfulBlockDownloads_Fragment
  | BlockTableOfContents_ContentBlocksFragment_ContentfulBlockFaq_Fragment
> =>
  contentBlocks.every(
    (contentBlock) =>
      contentBlock &&
      [
        "ContentfulBlockParagraph",
        "ContentfulBlockMediaItemListsCard",
        "ContentfulBlockDownloads",
        "ContentfulBlockFaq",
      ].includes(contentBlock.type),
  );

export const filterBlocksWithTableOfContentsInfo = (
  contentBlocks: Array<BlockTableOfContents_ContentBlocksFragmentFragment | null>,
) =>
  contentBlocks.filter(
    (contentBlock) =>
      contentBlock &&
      [
        "ContentfulBlockParagraph",
        "ContentfulBlockMediaItemListsCard",
        "ContentfulBlockDownloads",
        "ContentfulBlockFaq",
      ].includes(contentBlock.type),
  );
