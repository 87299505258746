import styled from "styled-components";
import { graphql } from "gatsby";
import React from "react";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Center from "@finanzchef24gmbh/design-system/src/Center";

import { BlockPlaceholder_ContentfulBlockPlaceholderFragment } from "../../../../types/graphql-types";
import BlockCustomerReviews from "./BlockCustomerReviews";
import BlockEmployeeReviews from "./BlockEmployeeReviews";
import BlockTopOffers from "./BlockTopOffers";

export const CENTER_SIDE_PADDING = "medium";

const StyledCenter = styled(Center).attrs((props) => ({
  max: props.theme.layout.containerWidth,
}))`
  padding: 0 ${(props) => props.theme.spacings[CENTER_SIDE_PADDING]};
`;

type BlockPlaceholderProps = {
  contentBlock: BlockPlaceholder_ContentfulBlockPlaceholderFragment;
};

const BlockPlaceholder: React.FC<BlockPlaceholderProps> = ({
  contentBlock,
}) => {
  const cardMode = contentBlock.isCardView || false;
  const Wrapper: React.FC<{ children?: React.ReactNode }> = cardMode
    ? ({ children }) => (
        <StyledCenter>
          <Card>{children}</Card>
        </StyledCenter>
      )
    : React.Fragment;
  return (
    <>
      {contentBlock.blockType === "BlockEmployeeReviews" ? (
        <BlockEmployeeReviews
          useEnglishContent={contentBlock.useEnglishContent || false}
        />
      ) : null}

      {contentBlock.blockType === "BlockCustomerReviews" ? (
        <Wrapper>
          <BlockCustomerReviews cardMode={cardMode} />
        </Wrapper>
      ) : null}

      {contentBlock.blockType === "BlockTopOffers" ? <BlockTopOffers /> : null}
    </>
  );
};

export const blockPlaceholderFragment = graphql`
  fragment BlockPlaceholder_ContentfulBlockPlaceholder on ContentfulBlockPlaceholder {
    blockType
    isCardView
    useEnglishContent
  }
`;

export default BlockPlaceholder;
