import transparentize from "polished/lib/color/transparentize";
import styled from "styled-components";

import { fontSizeByTextStyle } from "@finanzchef24gmbh/design-system/src/Text";

const Filter = styled.button.attrs({
  isUncropped: true,
})<{ isActive: boolean }>`
  color: ${(props) => props.theme.palette.white};
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.gray[200]};
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.brand.dark
      : props.theme.palette.gray[200]};
  outline: none;
  border-radius: ${(props) => props.theme.borders.radius.big}px;
  padding: ${(props) => props.theme.spacings.small}
    ${(props) => props.theme.spacings.medium};
  cursor: pointer;
  font-size: 0.8rem;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).button2}rem;
  }

  /* avoid wrapping filter text */
  white-space: nowrap;

  svg {
    color: ${(props) => props.theme.palette.brand[800]};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.brand.dark};
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.5, props.theme.palette.brand[400])};
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.brand.dark};
  }
`;

export default Filter;
