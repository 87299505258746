import { useCallback, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

const useTableOfContentsInView = (
  includeInTableOfContents: boolean,
  slug?: string | null,
) => {
  if (!includeInTableOfContents || !slug) {
    return null;
  }

  const ref = useRef<HTMLElement | null | undefined>();
  const [inViewRef, inView, entry] = useInView();

  const setRefs = useCallback(
    (node: HTMLElement | null | undefined) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    const anchor = `a[href="#${entry?.target.id}"]`;
    const navElement = document.querySelector(anchor)?.parentElement;

    const scrollableNav = document.getElementById("toc-stickybar");
    const visibleLinkBelongsToStickyBar = scrollableNav?.querySelector(anchor); // safety check
    if (navElement && inView) {
      if (!navElement.classList.contains("active-toc-link")) {
        navElement.classList.add("active-toc-link");

        // if we find the scrollable nav, scroll to the active link
        // we could also reach it by navElement.parentElement.parentElement...
        if (scrollableNav && visibleLinkBelongsToStickyBar) {
          scrollableNav.scroll({
            left: navElement.offsetLeft - 20,
          });
        }

        // Remove initial down arrow on first link.
        document
          .querySelector(".inactive-toc-link")
          ?.classList.remove("inactive-toc-link");
      }
    } else if (navElement?.classList.contains("active-toc-link")) {
      navElement.classList.remove("active-toc-link");

      /**
       * If there are no active links add inactive class to the first link.
       * The first link is the only one using the slug as a class so the down
       * arrow will only show if the user has scrolled above the first section.
       */
      if (!document.querySelector(".active-toc-link")) {
        document.querySelector(`.${slug}`)?.classList.add("inactive-toc-link");
      }
    }
  }, [inView]);

  return setRefs;
};

export default useTableOfContentsInView;
