import styled, { css } from "styled-components";

const Score = styled.span<{ isMaxScore?: boolean }>`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
  margin: 0 0 ${(props) => props.theme.spacings.small} 0;

  ${(props) =>
    props.isMaxScore
      ? css`
          font-size: 2em;
          color: ${props.theme.palette.gray[400]};

          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            font-size: 2.5em;
          }
        `
      : css`
          font-size: 2.5em;
          color: ${props.theme.palette.accent.light};

          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            font-size: 3.5em;
          }
        `}
`;

export default Score;
