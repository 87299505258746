import React from "react";
import styled, { css } from "styled-components";

import Text from "../Text";
import Link from "../Link";
import ResponsiveHelper from "../ResponsiveHelper";
import VisuallyHidden from "../VisuallyHidden";

type BreadcrumbProps = {
  label: string;
  slug?: string;
  children: React.ReactNode;
  replaceTextOnMobileWith?: React.ReactNode;
};

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  ${(props) => css`
    color: ${props.theme.palette.white};
  `}

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    white-space: nowrap;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  span {
    text-decoration: none;
  }

  ${(props) => css`
    color: ${props.theme.palette.white};

    svg {
      color: ${props.theme.palette.white};
    }

    &:hover svg {
      color: ${props.theme.palette.white};
    }
  `}
`;

const BreadcrumbText = ({
  label,
  breakpoint,
  replaceTextOnMobileWith,
}: {
  breakpoint?: string;
  label: string;
  replaceTextOnMobileWith?: React.ReactNode;
}) =>
  breakpoint === "mobile" && replaceTextOnMobileWith ? (
    <>
      {replaceTextOnMobileWith}
      <VisuallyHidden itemProp="name">{label}</VisuallyHidden>
    </>
  ) : (
    <Text
      as="span"
      itemProp="name"
      textStyle="caption"
      isOnDarkBackground
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );

const Breadcrumb: React.FC<React.PropsWithChildren<BreadcrumbProps>> = ({
  label,
  slug,
  children,
  replaceTextOnMobileWith,
}) => (
  <StyledLi
    itemScope
    itemProp="itemListElement"
    itemType="https://schema.org/ListItem"
  >
    {slug ? (
      <StyledLink href={slug} itemProp="item">
        <ResponsiveHelper>
          {(breakpoint) => (
            <BreadcrumbText
              label={label}
              breakpoint={breakpoint}
              replaceTextOnMobileWith={replaceTextOnMobileWith}
            />
          )}
        </ResponsiveHelper>
      </StyledLink>
    ) : (
      <ResponsiveHelper>
        {(breakpoint) => (
          <BreadcrumbText
            label={label}
            breakpoint={breakpoint}
            replaceTextOnMobileWith={replaceTextOnMobileWith}
          />
        )}
      </ResponsiveHelper>
    )}
    {children}
  </StyledLi>
);

export default Breadcrumb;
