import React from "react";

const FocusLink = ({
  offsetTop,
  ...props
}: JSX.IntrinsicElements["a"] & { offsetTop?: number }) => (
  <a
    {...props}
    onClick={(e) => {
      e.preventDefault();
      const href = (e.target as HTMLAnchorElement).href;
      const id = href.substring(href.indexOf("#") + 1);
      const elementToFocus = document.getElementById(id);
      if (!elementToFocus) {
        return;
      }
      elementToFocus.focus();

      if (typeof elementToFocus.scrollIntoView === "function") {
        elementToFocus.scrollIntoView();
      }
      if (offsetTop) {
        // use pageYOffset instead of scrollY for better cross-browser compat
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollX#Notes
        window.scrollTo(0, window.pageYOffset - offsetTop);
      }
    }}
  />
);

export default FocusLink;
