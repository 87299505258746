import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";

import Badge from "@finanzchef24gmbh/design-system/src/Badge";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, {
  fontSizeByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { BlockArticles_ContentfulBlockArticlesFragment } from "../../../types/graphql-types";
import { trackCardLink } from "../../utils/trackingUtils";
import Center from "../Center";

type BlockArticlesProps = {
  contentBlock: BlockArticles_ContentfulBlockArticlesFragment;
  randomArticleCards?: BlockArticles_ContentfulBlockArticlesFragment["articleCards"];
};

const NUMBER_OF_COLUMNS_IN_A_ROW = 3;

const StyledBlock = styled.div<{
  hasGrayBackground: boolean;
  hasBlueGradientBackground: boolean;
}>`
  ${(props) =>
    props.hasGrayBackground
      ? css`
          background-color: ${props.theme.palette.gray[25]};
          border-top: ${props.theme.borders.width.small}px solid
            ${props.theme.palette.gray[100]};
          border-bottom: ${props.theme.borders.width.small}px solid
            ${props.theme.palette.gray[100]};
          padding: ${props.theme.spacings.gigantic} 0;
        `
      : props.hasBlueGradientBackground &&
        css`
          padding: ${props.theme.spacings.gigantic} 0;
          background: linear-gradient(
            ${props.theme.palette.brand[400]},
            ${props.theme.palette.brand[500]},
            ${props.theme.palette.brand[600]}
          );
          z-index: 0;
        `};
`;

const BackgroundImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.2;
  filter: grayscale(100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  > div {
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledCenter = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledHeading = styled(Text)<{ isHeadingCentered: boolean }>`
  ${(props) =>
    props.isHeadingCentered &&
    css`
      text-align: center;
    `}
`;

const CardsContainer = styled.div`
  display: grid;
  grid-auto-rows: 15em;
  gap: ${(props) => props.theme.spacings.medium};

  @media (min-width: 30em) {
    grid-auto-rows: 20rem;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    grid-template-columns: repeat(${NUMBER_OF_COLUMNS_IN_A_ROW}, 1fr);
    grid-auto-rows: 12em;
  }
`;

const StyledCard = styled(Card)<{ bigThumbnailPosition?: string }>`
  border: 0;
  position: relative;

  ${(props) =>
    props.bigThumbnailPosition &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        grid-column: ${props.bigThumbnailPosition === "left"
          ? "1 / 3"
          : "2 / 4"};
        grid-row: ${props.bigThumbnailPosition === "left" ? "1 / 3" : "3 / 5"};
      }
    `};
`;

const StyledBadgeWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledLink = styled.a`
  display: flex;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;

  > img {
    object-fit: cover;
    width: 100%;
  }

  > div:first-child {
    width: 100%;
  }
`;

const StyledBackgroundGradient = styled.div<{ hasBigThumbnail: boolean }>`
  border-radius: inherit;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-top: ${(props) => props.theme.spacings.gigantic};
  padding-left: ${(props) => props.theme.spacings.small};
  padding-right: ${(props) => props.theme.spacings.small};
  padding-bottom: ${(props) => props.theme.spacings.big};

  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        padding: ${props.theme.spacings.gigantic} ${props.theme.spacings.medium}
          ${props.theme.spacings.huge};
      }
    `};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      ${(props) => props.theme.palette.brand[500]} 70%
    );
    opacity: 0.8;
  }
`;

const StyledTitleFirstLine = styled(Text)<{ hasBigThumbnail: boolean }>`
  line-height: ${(props) => props.theme.typography.lineHeights.headline};

  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        font-size: ${fontSizeByTextStyle(props.theme).body}rem;
      }
    `}
`;

const StyledTitleSecondLine = styled(Text)<{ hasBigThumbnail: boolean }>`
  line-height: 1.1;
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        font-size: ${fontSizeByTextStyle(props.theme).headline2}rem;
        margin-top: ${props.theme.spacings.huge};
      }
    `}
`;

const StyledBlockLink = styled(Link)<{ isOnDarkBackground: boolean }>`
  text-align: center;

  ${(props) =>
    props.isOnDarkBackground &&
    css`
      color: ${props.theme.palette.white};

      &:hover {
        color: ${props.theme.palette.gray[200]};
      }
    `}
`;

const BlockArticles: React.FC<BlockArticlesProps> = (props) => {
  const articleCards =
    props.contentBlock.articleCards || props.randomArticleCards;

  const hasBlueGradientBackground =
    props.contentBlock.hasBlueGradientBackground ||
    Boolean(props.contentBlock.backgroundImage) ||
    false;

  return (
    <StyledBlock
      hasGrayBackground={props.contentBlock.hasGrayBackground || false}
      hasBlueGradientBackground={hasBlueGradientBackground}
    >
      {props.contentBlock.backgroundImage?.fluid &&
        props.contentBlock.hasBlueGradientBackground && (
          <BackgroundImgWrapper>
            <Img
              fluid={props.contentBlock.backgroundImage.fluid as FluidObject}
            />
          </BackgroundImgWrapper>
        )}
      <StyledCenter>
        <Spacings.Stack scale="huge">
          <Spacings.Stack scale="medium">
            {props.contentBlock.heading && (
              <StyledHeading
                textStyle="headline4"
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.heading,
                }}
                isHeadingCentered={
                  props.contentBlock.isHeadingCentered || false
                }
                isOnDarkBackground={hasBlueGradientBackground}
              />
            )}
            {props.contentBlock.subheading && (
              <StyledHeading
                textStyle="bodyProlonged"
                as="span"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.subheading,
                }}
                isHeadingCentered={
                  props.contentBlock.isHeadingCentered || false
                }
                isOnDarkBackground={hasBlueGradientBackground}
              />
            )}
          </Spacings.Stack>
          {articleCards && (
            <CardsContainer>
              {articleCards.map((card, index) => {
                if (!card || card.__typename !== "ContentfulBasicLandingPage") {
                  return null;
                }

                const titleFirstLine = card.contentBlocks?.[0]?.titleFirstLine;
                const titleSecondLine =
                  card.contentBlocks?.[0]?.titleSecondLine;

                const allowBigThumbnail =
                  card.hasBigThumbnail && props.contentBlock.hasBigThumbnails;
                const isFirstColumnInARow =
                  index % NUMBER_OF_COLUMNS_IN_A_ROW === 0;
                const bigThumbnailPosition = isFirstColumnInARow
                  ? "left"
                  : "right";

                return (
                  <StyledCard
                    key={index}
                    bigThumbnailPosition={
                      allowBigThumbnail ? bigThumbnailPosition : undefined
                    }
                    isHighlighted={false}
                    isInteractive
                  >
                    {props.contentBlock.hasBadges && card.badgeText && (
                      <StyledBadgeWrapper>
                        <Badge color="mint">
                          <Text
                            as="div"
                            dangerouslySetInnerHTML={{
                              __html: card.badgeText,
                            }}
                            isOnDarkBackground
                          />
                        </Badge>
                      </StyledBadgeWrapper>
                    )}
                    {card.pageLink?.slug && (
                      <StyledLink
                        href={`/${card.pageLink?.slug}`}
                        onClick={() =>
                          trackCardLink("articles", card.pageLink?.slug)
                        }
                      >
                        {card.previewImage?.fluid && (
                          <Img
                            fluid={card.previewImage.fluid as FluidObject}
                            alt={
                              card.previewImage.description ||
                              card.previewImage.title ||
                              ""
                            }
                          />
                        )}
                        {titleSecondLine && (
                          <StyledBackgroundGradient
                            hasBigThumbnail={allowBigThumbnail || false}
                          >
                            <Spacings.Stack scale="medium">
                              {titleFirstLine && card.showTitleFirstLine && (
                                <StyledTitleFirstLine
                                  textStyle="caption"
                                  as="span"
                                  hasBigThumbnail={allowBigThumbnail || false}
                                  isOnDarkBackground
                                  dangerouslySetInnerHTML={{
                                    __html: titleFirstLine,
                                  }}
                                />
                              )}
                              <StyledTitleSecondLine
                                textStyle="body"
                                as="h3"
                                hasBigThumbnail={allowBigThumbnail || false}
                                isOnDarkBackground
                                dangerouslySetInnerHTML={{
                                  __html: titleSecondLine,
                                }}
                              />
                            </Spacings.Stack>
                          </StyledBackgroundGradient>
                        )}
                      </StyledLink>
                    )}
                  </StyledCard>
                );
              })}
            </CardsContainer>
          )}
          {props.contentBlock.linkUrl && (
            <StyledBlockLink
              href={props.contentBlock.linkUrl}
              isOnDarkBackground={hasBlueGradientBackground}
            >
              {props.contentBlock.linkText}
            </StyledBlockLink>
          )}
        </Spacings.Stack>
      </StyledCenter>
    </StyledBlock>
  );
};

/**
 * ... on ContentfulBlockBlogHero {
    titleSecondLine: heading
    titleFirstLine: subheading
  }
  ... on ContentfulBlockKnowledgeHero {
    titleSecondLine: titleFirstLine
    titleFirstLine: titleSecondLine
  }
    Given the difference in use cases between the blocks, we are forced to 
    rename these two to match the existing usage, and avoid major changes in code
    the goal is for the Article "cards" to show the linked page h1 element as the title
    for ContentfulBlockHero && ContentfulBlockInfoHero this was under titleSecondLine 
 */

export const blockArticlesFragment = graphql`
  fragment BlockArticles_ContentfulBlockArticles on ContentfulBlockArticles {
    heading
    subheading
    isHeadingCentered
    hasGrayBackground
    hasBadges
    hasBigThumbnails
    hasBlueGradientBackground
    backgroundImage {
      fluid(quality: 85) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    linkText
    linkUrl
    articleCards: cards {
      __typename
      ... on ContentfulBasicLandingPage {
        contentBlocks {
          ... on ContentfulBlockHero {
            titleFirstLine
            titleSecondLine
          }
          ... on ContentfulBlockInfoHero {
            titleFirstLine
            titleSecondLine
          }
          ... on ContentfulBlockBlogHero {
            titleSecondLine: heading
            titleFirstLine: subheading
          }
          ... on ContentfulBlockKnowledgeHero {
            titleSecondLine: titleFirstLine
            titleFirstLine: titleSecondLine
          }
        }
        showTitleFirstLine
        previewImage {
          title
          description
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        hasBigThumbnail
        badgeText
        pageLink {
          slug
        }
      }
    }
  }
`;

export default BlockArticles;
