/* eslint-disable react/jsx-no-target-blank */
import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject, GatsbyImageProps } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import StarIcon from "@finanzchef24gmbh/design-system/src/Icons/svg/star.svg";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { EkomiSealQuery, Maybe } from "../../../../../types/graphql-types";
import { formatNumber } from "../../../../utils";
import Rating from "../../../Rating";
import SwitchInlineStack from "../../../SwitchInlineStack";
import Score from "./Score";

const StyledSeal = styled(Img)<GatsbyImageProps>`
  width: 60px;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 80px;
  }
`;

const StyledCard = styled(Card)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex: 1;
  }
`;

const StyledRating = styled.div`
  font-size: 0.8rem;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: 1rem;
  }
`;

const StyledSlash = styled.span`
  font-size: 2.2em;
  color: ${(props) => props.theme.palette.gray[400]};
  font-weight: ${(props) => props.theme.typography.weights.regular};
  margin: 0 0 ${(props) => props.theme.spacings.small} 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: 3.2em;
  }
`;

const EKomiHeadline = styled(Text)`
  text-align: center;
`;

const EKomiLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledRatingWrapper = styled(SwitchInlineStack)`
  flex-direction: column-reverse;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
  }
`;

const StyledSealAndRating = styled(Spacings.Inline)`
  > * + * {
    margin: 0 0 0 ${(props) => props.theme.spacings.medium};
  }
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    > * + * {
      margin: 0 0 0 ${(props) => props.theme.spacings.big};
    }
  }
`;

type EKomiCardProps = {
  description: string;
  reviewsCount: number;
  rating: number;
  buttonLink?: string;
  buttonTitle: string;
};

const EKomiCard: React.FC<EKomiCardProps> = (props) => {
  const data = useStaticQuery<Maybe<EkomiSealQuery>>(graphql`
    query EkomiSeal {
      ekomiSeal: contentfulAsset(
        contentful_id: { eq: "4mxgfVujaxmFHzWdFNPial" }
      ) {
        fluid(maxWidth: 80, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <StyledCard>
      <Spacings.Inset scale="big">
        <Spacings.Stack scale="big" alignItems="center">
          {props.description && (
            <EKomiHeadline
              textStyle="headline6"
              as="span"
              dangerouslySetInnerHTML={{
                __html: props.description,
              }}
            />
          )}
          <StyledSealAndRating alignItems="center">
            {data?.ekomiSeal?.fluid && (
              <StyledSeal
                fluid={data.ekomiSeal.fluid as FluidObject}
                alt="Goldenes eKomi-Siegel für Finanzchef24"
              />
            )}
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "Product",
                    "aggregateRating":
                    {
                      "@type":"AggregateRating",
                      "author":"eKomi",
                      "ratingValue":"${props.rating}",
                      "bestRating":"5",
                      "reviewCount":"${props.reviewsCount}"
                    },
                    "description": "Deutschlands großer digitaler Versicherungsmakler für Unternehmer und Selbstständige",
                    "name": "Finanzchef24 GmbH"
                  }
                `,
              }}
            />
            <StyledRatingWrapper
              breakOn="mobileBreakpoint"
              inlineScale="medium"
            >
              <Spacings.Stack scale="medium">
                <StyledRating>
                  <Rating
                    icon={StarIcon}
                    score={(props.rating / 5) * 100}
                    iconScale="huge"
                    spacingScale="tiny"
                  />
                </StyledRating>
                <Text textStyle="caption" priority="secondary">
                  bei {formatNumber(props.reviewsCount)} Bewertungen
                </Text>
              </Spacings.Stack>
              <Spacings.Inline>
                <Score>{props.rating}</Score>
                <StyledSlash>/</StyledSlash>
                <Score isMaxScore>5</Score>
              </Spacings.Inline>
            </StyledRatingWrapper>
          </StyledSealAndRating>
          <EKomiLinkWrapper>
            <a href={props.buttonLink} target="_blank" rel="nofollow noopener">
              <Button buttonType="flat" tabIndex={-1}>
                {props.buttonTitle}
              </Button>
            </a>
          </EKomiLinkWrapper>
        </Spacings.Stack>
      </Spacings.Inset>
    </StyledCard>
  );
};

export default EKomiCard;
