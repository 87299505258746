import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";

import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockNotificationBarProps } from ".";
import { trackTopNewsBarLink } from "../../../utils/trackingUtils";
import Responsive from "../../Responsive";
import RichTextLink from "../../RichTextLink";

const StyledTextContainer = styled.div`
  padding: ${(props) => props.theme.spacings.small} 0;
`;

const StyledText = styled(Text)`
  line-height: ${(props) => props.theme.typography.lineHeights.headline};
  padding: ${(props) => props.theme.spacings.small} 0;

  a {
    color: ${(props) => props.theme.palette.white};

    &:hover,
    &:hover svg {
      color: ${(props) => props.theme.palette.gray[200]};
    }

    @media (max-width: ${(props) =>
        props.theme.layout.mobileMaxWidthBreakpoint}) {
      text-decoration: none;

      span {
        text-decoration: none;
      }
    }
  }
`;

const desktopOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledText textStyle="caption" isOnDarkBackground>
        {children.filter(
          (child: React.ReactNodeArray | string) => child !== "",
        )}
      </StyledText>
    ),
    [INLINES.HYPERLINK]: (node, children: any) => (
      <RichTextLink
        link={node.data.uri}
        trackLink={() => trackTopNewsBarLink(node.data.uri)}
      >
        {children[0]}
      </RichTextLink>
    ),
  },
};

/**
 * node.content is an array of rich text content
 * content[0] maps to the paragraph text which needs to be wrapped in an anchor tag on mobile
 * content[1] maps to the hyperlink
 */
const mobileOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any) => (
      <StyledText textStyle="caption" isOnDarkBackground>
        <RichTextLink
          link={node.content[1].data.uri}
          trackLink={() => trackTopNewsBarLink(node.data.uri)}
        >
          <Spacings.Inline scale="tiny" alignItems="center">
            <span>{node.content[0].value.replace(":", "")}</span>
            <ChevronRightIcon scale="medium" isOnDarkBackground />
          </Spacings.Inline>
        </RichTextLink>
      </StyledText>
    ),
  },
};

const TopNewsBar: React.FC<BlockNotificationBarProps> = (props) => (
  <Spacings.Inline scale="small" alignItems="center">
    {props.contentBlock.icon?.file?.url ? (
      <img
        src={props.contentBlock.icon.file.url}
        alt=""
        height="32"
        width="32"
      />
    ) : null}

    {props.contentBlock.richText?.json ? (
      <StyledTextContainer>
        <Responsive hideOn={["mobile"]}>
          {documentToReactComponents(
            props.contentBlock.richText.json,
            desktopOptions,
          )}
        </Responsive>

        <Responsive hideOn={["tablet", "desktop"]}>
          {documentToReactComponents(
            props.contentBlock.richText.json,
            mobileOptions,
          )}
        </Responsive>
      </StyledTextContainer>
    ) : null}
  </Spacings.Inline>
);

export default TopNewsBar;
