import { TenantSettingsInstance } from "@finanzchef24gmbh/tenant-settings";
import { css } from "styled-components";
import reset from "styled-reset";

const defaultVariables = css`
  --illustration-gray-800: ${(props) => props.theme.palette.brand[800]};
  --illustration-gray-700: ${(props) => props.theme.palette.brand[600]};
  --illustration-gray-600: ${(props) => props.theme.palette.brand[400]};
  --illustration-gray-400: ${(props) => props.theme.palette.mint[600]};
  --illustration-gray-150: ${(props) => props.theme.palette.brand[200]};
  --illustration-gray-100: ${(props) => props.theme.palette.mint[200]};
  --illustration-gray-50: ${(props) => props.theme.palette.mint[100]};
  --illustration-white: ${(props) => props.theme.palette.white};
  --illustration-black: ${(props) => props.theme.palette.black};
`;

type ThemeColors = ReturnType<TenantSettingsInstance["getThemeColors"]>;

const getGlobalStyles = (themeColors?: ThemeColors) => css`
  ${reset};

  /* Forms
   ========================================================================== */

  /**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: ${(props) => props.theme.typography.lineHeights.form}; /* 1 */
    margin: 0; /* 2 */
  }

  /**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  /**
 * Correct the inability to style clickable types in iOS and Safari.
 */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: button;
  }

  /**
 * Correct the padding in Firefox.
 */

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  /**
 * Remove the default vertical scrollbar in IE 10+.
 */

  textarea {
    overflow: auto;
  }

  /**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  svg {
    fill: currentColor;
  }

  [tabindex="-1"] {
    outline: none;
  }

  /* Define CSS variables used for colors in illustrations and icons. */
  :root {
    ${defaultVariables}
    ${themeColors
      ? themeColors.illustrationColors.map(
          (illustrationColor) =>
            `${illustrationColor.variableName}: ${illustrationColor.color};`,
        )
      : ""}
  }
`;

export default getGlobalStyles;
