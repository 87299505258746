export const getCookieConsentLevels = () => {
  let cookieConsentLevels = {} as { [key: string]: boolean };
  if (typeof window === "undefined") {
    return cookieConsentLevels;
  }
  try {
    const cookieConsentLevelEntries = document.cookie
      .split(";")
      .filter((cookie) => cookie.includes("cookie_consent_level"));

    if (cookieConsentLevelEntries && cookieConsentLevelEntries.length) {
      const encodedCookieConsentLevels = cookieConsentLevelEntries[0]
        .split("=")
        .pop();
      if (encodedCookieConsentLevels) {
        cookieConsentLevels = JSON.parse(
          decodeURIComponent(encodedCookieConsentLevels),
        );
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return cookieConsentLevels;
};

export const checkCookieConsent = (level: string) => {
  let isCookieConsentLevelAccepted = false;
  try {
    isCookieConsentLevelAccepted = getCookieConsentLevels()[level];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return isCookieConsentLevelAccepted;
};

export const getCookiesMap = () =>
  document.cookie.split(";").reduce(
    (cookieObject, cookieString) => {
      const cookieNameAndValue = cookieString
        .split("=")
        .map((cookiePart) => cookiePart.trim());

      try {
        cookieObject[cookieNameAndValue[0]] = JSON.parse(cookieNameAndValue[1]);
      } catch (error) {
        cookieObject[cookieNameAndValue[0]] = cookieNameAndValue[1];
      }

      return cookieObject;
    },
    {} as Record<string, any>,
  );
