import React from "react";
import styled, { css } from "styled-components";

import StarIcon from "@finanzchef24gmbh/design-system/src/Icons/svg/star.svg";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import Markdown from "./Markdown";
import Rating from "./Rating";

type StyledFeedbackStackOProps = {
  desktopOnly?: boolean;
};

const StyledFeedbackStack = styled(Spacings.Stack)<StyledFeedbackStackOProps>`
  justify-content: center;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex: 1;
  }

  ${(props) =>
    props.desktopOnly &&
    css`
      display: none;
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        display: initial;
      }
    `}
`;

type CustomerFeedbackProps = {
  feedback: string;
  date: string;
  rating: number;
  desktopOnly?: boolean;
};

const CustomerFeedback: React.FC<CustomerFeedbackProps> = (props) => (
  <StyledFeedbackStack scale="small" desktopOnly={props.desktopOnly}>
    <Text textStyle="caption" priority="secondary">
      eKomi-Bewertung vom {props.date}
    </Text>
    <Spacings.Stack scale="big">
      <Rating
        icon={StarIcon}
        score={(props.rating / 5) * 100}
        iconScale="small"
        spacingScale="tiny"
      />
      <Markdown
        dangerouslySetInnerHTML={{
          __html: props.feedback,
        }}
      />
    </Spacings.Stack>
  </StyledFeedbackStack>
);

export default CustomerFeedback;
