import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { CardsProps } from ".";
import { trackCardLink } from "../../../utils/trackingUtils";
import Image from "../../Image";
import RelativeLink from "../../RelativeLink";
import {
  StyledCard,
  StyledCardImage,
  StyledContainerLink,
  StyledLinkText,
} from "./SharedStyles";

const StyledBigCardGrid = styled.div`
  display: grid;
  gap: ${(props) => props.theme.spacings.medium};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledBigCardImage = styled(StyledCardImage)`
  height: 11rem;

  @media (min-width: 25em) {
    height: 15.5rem;
  }

  @media (min-width: 30em) {
    height: 20rem;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    height: 15.5rem;
  }
`;

const StyledDescription = styled(Text)`
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
`;

const StyledLink = styled(RelativeLink)`
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
`;

const BigCards: React.FC<CardsProps> = ({ cards }) => (
  <StyledBigCardGrid>
    {cards?.map((card, index) => {
      if (!card) {
        return null;
      }

      return (
        <StyledContainerLink
          key={index}
          href={card.linkUrl || undefined}
          onClick={() => trackCardLink("categories", card.linkUrl)}
        >
          <StyledCard isHighlighted={false} isInteractive>
            {(card.image?.fluid || card.image?.file?.url) && (
              <StyledBigCardImage>
                <Image asset={card.image} loading="lazy" />
              </StyledBigCardImage>
            )}
            <Spacings.Inset scale="big">
              <Spacings.Stack scale="big">
                {card.title && (
                  <Text
                    textStyle="headline4"
                    as="span"
                    dangerouslySetInnerHTML={{
                      __html: card.title,
                    }}
                  />
                )}
                {card.description?.childMarkdownRemark?.html && (
                  <StyledDescription
                    as="div"
                    dangerouslySetInnerHTML={{
                      __html: card.description.childMarkdownRemark.html,
                    }}
                  />
                )}
                {card.linkText && card.linkUrl && (
                  <StyledLink href={card.linkUrl}>
                    <StyledLinkText textStyle="bodyProlonged">
                      {card.linkText}
                    </StyledLinkText>
                  </StyledLink>
                )}
              </Spacings.Stack>
            </Spacings.Inset>
          </StyledCard>
        </StyledContainerLink>
      );
    })}
  </StyledBigCardGrid>
);

export default BigCards;
