import { graphql } from "gatsby";
import React from "react";

import Dialog from "@finanzchef24gmbh/design-system/src/Dialog";
import { DialogState } from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import { NewsletterRegistration_ContentfulNewsletterRegistrationFragment } from "../../../types/graphql-types";
import HubspotForm from "./HubspotForm";
import NewsletterTwoGoForm from "./NewsletterTwoGoForm";

export type NewsletterRegistrationDialogProps = {
  form: NonNullable<NewsletterRegistration_ContentfulNewsletterRegistrationFragment>;
  dialogState: DialogState;
  downloadLink: string;
};

const NewsletterRegistrationDialog: React.FC<
  NewsletterRegistrationDialogProps
> = ({ form, dialogState, downloadLink }) => (
  <Dialog
    visible={dialogState.visible}
    onClose={dialogState.close}
    closeLabel="Dialog schließen"
    toggleRef={dialogState.toggle.ref}
    content={
      form.useHubSpotForm ? (
        <HubspotForm form={form} />
      ) : (
        <NewsletterTwoGoForm
          form={form}
          dialogState={dialogState}
          downloadLink={downloadLink}
        />
      )
    }
  />
);

export const newsletterRegistrationFragment = graphql`
  fragment NewsletterRegistration_ContentfulNewsletterRegistration on ContentfulNewsletterRegistration {
    contentful_id
    formId
    useHubSpotForm
    title
    label
    buttonText
    illustration {
      file {
        url
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    inputFieldPlaceholder
    privacyPolicy {
      childMarkdownRemark {
        html
      }
    }
    invalidEmailError
    errorTitle
    errorDescription {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export default NewsletterRegistrationDialog;
