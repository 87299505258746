import React from "react";

import InfoBox from "@finanzchef24gmbh/design-system/src/InfoBox";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import styled, { css } from "styled-components";
import { StyledNewsletterRegistrationWrapperProps } from ".";
import { BlockNewsletterRegistration_ContentfulBlockNewsletterRegistrationFragment } from "../../../../types/graphql-types";
import useHubspotForm from "../../../hooks/useHubspotForm";
import Markdown from "../../Markdown";

type NewsletterContentProps = {
  content: BlockNewsletterRegistration_ContentfulBlockNewsletterRegistrationFragment;
};

const StyledMediaItemImage = styled.img`
  width: 4em;
  height: 4em;
`;

const StyledInfoBox = styled(InfoBox)<StyledNewsletterRegistrationWrapperProps>`
  background: ${(props) => props.theme.palette.backgrounds.white};
  ${(props) =>
    (props.isCardView || props.hasBackground) &&
    css`
      border: none;
    `};
`;

const NewsletterContent: React.FC<NewsletterContentProps> = ({ content }) => {
  useHubspotForm({
    contentfulId: content.form?.contentful_id,
    formId: content.form?.formId,
  });

  return (
    <Spacings.Stack>
      <Spacings.Stack scale="big">
        {content.headline && (
          <Text
            textStyle="headline4"
            as="h2"
            isOnDarkBackground={Boolean(content.hasBackground)}
          >
            {content.headline}
          </Text>
        )}

        <StyledInfoBox
          isCardView={Boolean(content.isCardView)}
          icon={
            content.icon?.file?.url && (
              <StyledMediaItemImage
                src={content.icon.file.url}
                alt=""
                loading="lazy"
              />
            )
          }
        >
          <Spacings.Inset scale="medium">
            <Spacings.Stack scale="big">
              <Spacings.Stack scale="medium">
                {content.form?.title && (
                  <Text textStyle="headline5" as="h2">
                    {content.form.title}
                  </Text>
                )}
                {content.form?.description?.childMarkdownRemark?.html && (
                  <Markdown
                    priority="secondary"
                    dangerouslySetInnerHTML={{
                      __html: content.form.description.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Spacings.Stack>

              <div className={`hbspt-form-${content.form?.contentful_id}`} />
            </Spacings.Stack>
          </Spacings.Inset>
        </StyledInfoBox>
      </Spacings.Stack>
    </Spacings.Stack>
  );
};

export default NewsletterContent;
