type Prices = {
  priceToShow?: string | null;
  expensivePrice?: string | null;
  favourablePrice?: string | null;
};

export const priceOrPercentage = (prices: Prices) => {
  if (prices.priceToShow) {
    return prices.priceToShow;
  }

  const expensivePrice = parseFloat(
    String(prices.expensivePrice).replace(",", "."),
  );
  const favourablePrice = parseFloat(
    String(prices.favourablePrice).replace(",", "."),
  );
  const savingsPercentage = `${Math.round(
    ((expensivePrice - favourablePrice) / expensivePrice) * 100,
  )}%`;

  return savingsPercentage;
};
