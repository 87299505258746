import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { HighlightsProps } from ".";
import { addExternalLinkAttributes } from "../../../utils/index";
import InsurerLogosGray from "../../InsurerLogosGray";
import Markdown from "../../Markdown";
import SwitchInlineStack from "../../SwitchInlineStack";

const StyledInsurerImagesInset = styled(Spacings.Inset)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledSwitchInlineStack = styled(SwitchInlineStack)`
  align-self: center;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    align-self: stretch;
    > * {
      flex: 1;
    }
  }
`;

const StyledHighlight = styled(Spacings.Stack)`
  text-align: center;
`;

type StyledHorizontalHighlightsImageProps = {
  size?: "small" | "big" | null;
};

const StyledHorizontalHighlightsImage = styled.img<StyledHorizontalHighlightsImageProps>`
  width: ${(props) => (props.size === "big" ? "12em" : "4em")};
  height: ${(props) => (props.size === "big" ? "7em" : "4em")};
  max-width: 100%;
  object-fit: contain;
  align-self: center;
`;

const Horizontal: React.FC<HighlightsProps> = (props) => {
  const content = (
    <>
      {props.contentBlock.showListOfInsurancePartners ? (
        <StyledInsurerImagesInset scale="medium">
          <InsurerLogosGray />
        </StyledInsurerImagesInset>
      ) : null}
      <Spacings.Inset scale="big">
        <Spacings.Stack scale="big" alignItems="center">
          {props.contentBlock.title ? (
            <Text
              textStyle="headline4"
              as="h2"
              dangerouslySetInnerHTML={{ __html: props.contentBlock.title }}
            />
          ) : null}
          <StyledSwitchInlineStack
            breakOn="mobileBreakpoint"
            stackScale="huge"
            inlineScale="big"
          >
            <StyledHighlight scale="big">
              {props.contentBlock.firstHighlightImage?.file?.url ? (
                <StyledHorizontalHighlightsImage
                  src={props.contentBlock.firstHighlightImage.file.url}
                  size={
                    props.contentBlock
                      .highlightImageSize as StyledHorizontalHighlightsImageProps["size"]
                  }
                  alt={
                    props.contentBlock.firstHighlightImage.description ||
                    props.contentBlock.firstHighlightImage.title ||
                    ""
                  }
                  loading="lazy"
                />
              ) : null}

              {props.contentBlock.firstHighlightTitle ? (
                <Text
                  textStyle="headline5"
                  as="h3"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock.firstHighlightTitle,
                  }}
                />
              ) : null}

              {props.contentBlock.firstHighlightDescription?.childMarkdownRemark
                ?.html ? (
                <Markdown
                  priority="secondary"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock
                      .overrideFirstDefaultExternalLinkAttributes
                      ? props.contentBlock.firstHighlightDescription
                          .childMarkdownRemark.html
                      : addExternalLinkAttributes(
                          props.contentBlock.firstHighlightDescription
                            .childMarkdownRemark.html,
                        ),
                  }}
                />
              ) : null}
            </StyledHighlight>

            <StyledHighlight scale="big">
              {props.contentBlock.secondHighlightImage?.file?.url ? (
                <StyledHorizontalHighlightsImage
                  src={props.contentBlock.secondHighlightImage.file.url}
                  size={
                    props.contentBlock
                      .highlightImageSize as StyledHorizontalHighlightsImageProps["size"]
                  }
                  alt={
                    props.contentBlock.secondHighlightImage.description ||
                    props.contentBlock.secondHighlightImage.title ||
                    ""
                  }
                  loading="lazy"
                />
              ) : null}

              {props.contentBlock.secondHighlightTitle ? (
                <Text
                  textStyle="headline5"
                  as="h3"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock.secondHighlightTitle,
                  }}
                />
              ) : null}

              {props.contentBlock.secondHighlightDescription
                ?.childMarkdownRemark?.html ? (
                <Markdown
                  priority="secondary"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock
                      .overrideSecondDefaultExternalLinkAttributes
                      ? props.contentBlock.secondHighlightDescription
                          .childMarkdownRemark.html
                      : addExternalLinkAttributes(
                          props.contentBlock.secondHighlightDescription
                            .childMarkdownRemark.html,
                        ),
                  }}
                />
              ) : null}
            </StyledHighlight>

            <StyledHighlight scale="big">
              {props.contentBlock.thirdHighlightImage?.file?.url ? (
                <StyledHorizontalHighlightsImage
                  src={props.contentBlock.thirdHighlightImage.file.url}
                  size={
                    props.contentBlock
                      .highlightImageSize as StyledHorizontalHighlightsImageProps["size"]
                  }
                  alt={
                    props.contentBlock.thirdHighlightImage.description ||
                    props.contentBlock.thirdHighlightImage.title ||
                    ""
                  }
                  loading="lazy"
                />
              ) : null}

              {props.contentBlock.thirdHighlightTitle ? (
                <Text
                  textStyle="headline5"
                  as="h3"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock.thirdHighlightTitle,
                  }}
                />
              ) : null}

              {props.contentBlock.thirdHighlightDescription?.childMarkdownRemark
                ?.html ? (
                <Markdown
                  priority="secondary"
                  dangerouslySetInnerHTML={{
                    __html: props.contentBlock
                      .overrideThirdDefaultExternalLinkAttributes
                      ? props.contentBlock.thirdHighlightDescription
                          .childMarkdownRemark.html
                      : addExternalLinkAttributes(
                          props.contentBlock.thirdHighlightDescription
                            .childMarkdownRemark.html,
                        ),
                  }}
                />
              ) : null}
            </StyledHighlight>
          </StyledSwitchInlineStack>

          {props.contentBlock.ctaButton?.buttonText &&
          props.contentBlock.ctaButton.buttonLink ? (
            <div>
              <a href={props.contentBlock.ctaButton.buttonLink} tabIndex={-1}>
                <Button buttonType="primary" scale="big">
                  {props.contentBlock.ctaButton.buttonText}
                </Button>
              </a>
            </div>
          ) : null}
        </Spacings.Stack>
      </Spacings.Inset>
    </>
  );

  return props.contentBlock.isCardView ? (
    <Card>{content}</Card>
  ) : (
    <div>{content}</div>
  );
};

export default Horizontal;
