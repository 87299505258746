import { Theme } from "../designTokens";

type Color = "brand" | "accent" | "mint" | "gray";

export const mapBadgeColorToTheme = (color: Color, theme: Theme) => {
  switch (color) {
    case "mint":
      return theme.palette.mint[600];
    case "accent":
      return theme.palette.accent[500];
    case "gray":
      return theme.palette.gray[400];
    default:
      return theme.palette.brand[600];
  }
};

export const mapWrapColorToTheme = (color: Color, theme: Theme) => {
  switch (color) {
    case "mint":
      return theme.palette.mint[700];
    case "accent":
      return theme.palette.accent[700];
    case "gray":
      return theme.palette.gray[400];
    default:
      return theme.palette.brand[900];
  }
};
