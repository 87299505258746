import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { InView } from "react-intersection-observer";
import styled, { css } from "styled-components";

import ExpandIcon from "@finanzchef24gmbh/design-system/src/Icons/ExpandIcon";
import MinimizeIcon from "@finanzchef24gmbh/design-system/src/Icons/MinimizeIcon";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { Maybe, TopOffersIconQuery } from "../../../../../types/graphql-types";
import { trackTopOffersLink } from "../../../../utils/trackingUtils";
import Center from "../../../Center";
import Responsive from "../../../Responsive";
import RichTextLink from "../../../RichTextLink";
import SwitchInlineStack from "../../../SwitchInlineStack";
import contentBlock from "./contentBlock";

type MinimizeProps = {
  toggleMinimizeOffers: boolean;
};

const StyledTopOffersContainer = styled.div<{ hideTopOffers: boolean }>`
  ${(props) =>
    props.hideTopOffers &&
    css`
      transform: translateY(50vh);
    `};
`;

const StyledContainer = styled.div<MinimizeProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  ${(props) =>
    props.toggleMinimizeOffers &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        left: auto;
      }
    `};
`;

const StyledCenter = styled(Center)<MinimizeProps>`
  @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    align-items: stretch;
    padding-left: ${(props) => props.theme.spacings.small};
    padding-right: ${(props) => props.theme.spacings.small};
  }

  ${(props) =>
    props.toggleMinimizeOffers &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        margin-left: 0;
      }
    `};
`;

const StyledGrid = styled.div<MinimizeProps>`
  display: grid;
  grid-template-columns: 1.25rem 1fr minmax(1.25rem, auto);
  column-gap: ${(props) => props.theme.spacings.medium};
  padding-top: ${(props) => props.theme.spacings.small};
  padding-bottom: ${(props) => props.theme.spacings.small};

  ${(props) =>
    !props.toggleMinimizeOffers &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        padding-left: ${props.theme.spacings.huge};
        padding-right: ${props.theme.spacings.huge};
      }
    `};
`;

const StyledGridHeader = styled(StyledGrid)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.mint[100]};
  padding-left: ${(props) => props.theme.spacings.medium};
  padding-right: ${(props) => props.theme.spacings.medium};
  text-decoration: none;
`;

const StyledGridOffers = styled(StyledGrid)`
  position: absolute;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    position: static;
  }

  ${(props) =>
    props.toggleMinimizeOffers &&
    css`
      position: static;

      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        position: absolute;
      }
    `};
`;

const StyledMinimizeText = styled(Text)`
  color: ${(props) => props.theme.palette.brand[700]};
`;

const StyledMinimizeIcon = styled(MinimizeIcon)`
  color: ${(props) => props.theme.palette.brand[700]};
`;

const StyledExpandIcon = styled(ExpandIcon)`
  color: ${(props) => props.theme.palette.brand[700]};
`;

const StyledSwitchInlineStack = styled(SwitchInlineStack)`
  padding: ${(props) => props.theme.spacings.small} 0;
  grid-column: 2 / 3;
`;

const StyledRichTextLink = styled(RichTextLink)`
  display: block;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: inline-block;
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <Text textStyle="caption" isStrong>
        {children.filter(
          (child: React.ReactNodeArray | string) => child !== "",
        )}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children: any) => (
      <StyledRichTextLink
        link={node.data.uri}
        trackLink={() => trackTopOffersLink(node.data.uri)}
      >
        {children[0]}
      </StyledRichTextLink>
    ),
  },
};

const BlockTopOffers: React.FC = () => {
  const data = useStaticQuery<Maybe<TopOffersIconQuery>>(graphql`
    query TopOffersIcon {
      icon: contentfulAsset(contentful_id: { eq: "2q1AXTFM8NZVKkndLJFry0" }) {
        file {
          url
        }
      }
    }
  `);

  const [toggleMinimizeOffers, setToggleMinimizeOffers] = useState(false);

  const minimizeOffers = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setToggleMinimizeOffers(!toggleMinimizeOffers);
  };

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <StyledTopOffersContainer ref={ref} hideTopOffers={!inView}>
          {inView && (
            <StyledContainer toggleMinimizeOffers={toggleMinimizeOffers}>
              <StyledCenter
                toggleMinimizeOffers={toggleMinimizeOffers}
                intrinsic
              >
                <Layer.Raised>
                  <StyledGridHeader
                    as="a"
                    href="#"
                    toggleMinimizeOffers={toggleMinimizeOffers}
                    onClick={minimizeOffers}
                  >
                    {data?.icon?.file?.url && (
                      <img src={data.icon.file.url} width="20" alt="" />
                    )}
                    {contentBlock.heading && (
                      <Text as="span" textStyle="headline6" isUncropped>
                        {contentBlock.heading}
                      </Text>
                    )}

                    <Responsive hideOn={["mobile", "tablet"]}>
                      <Spacings.Inline scale="small" alignItems="center">
                        {!toggleMinimizeOffers ? (
                          <>
                            <StyledMinimizeText textStyle="caption">
                              minimieren
                            </StyledMinimizeText>
                            <StyledMinimizeIcon />
                          </>
                        ) : (
                          <StyledExpandIcon />
                        )}
                      </Spacings.Inline>
                    </Responsive>

                    <Responsive hideOn={["desktop"]}>
                      <Spacings.Inline scale="small" alignItems="center">
                        {!toggleMinimizeOffers ? (
                          <>
                            <StyledMinimizeText textStyle="caption">
                              anzeigen
                            </StyledMinimizeText>
                            <StyledExpandIcon />
                          </>
                        ) : (
                          <>
                            <StyledMinimizeText textStyle="caption">
                              minimieren
                            </StyledMinimizeText>
                            <StyledMinimizeIcon />
                          </>
                        )}
                      </Spacings.Inline>
                    </Responsive>
                  </StyledGridHeader>

                  <StyledGridOffers toggleMinimizeOffers={toggleMinimizeOffers}>
                    <StyledSwitchInlineStack
                      breakOn="tabletBreakpoint"
                      inlineScale="huge"
                      stackScale="big"
                    >
                      {contentBlock.richTextOffers?.json &&
                        documentToReactComponents(
                          contentBlock.richTextOffers.json,
                          options,
                        )}
                    </StyledSwitchInlineStack>
                  </StyledGridOffers>
                </Layer.Raised>
              </StyledCenter>
            </StyledContainer>
          )}
        </StyledTopOffersContainer>
      )}
    </InView>
  );
};

export default BlockTopOffers;
