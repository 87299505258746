import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { Contentful_ContentfulMediaItemListFragment } from "../../../../../types/graphql-types";
import CollapsibleList from "./CollapsibleList";
import MediaItem from "./MediaItem";

const StyledMediaItemList = styled.div<{
  isSingleColumn: boolean;
  mediaItemsHaveCardView: boolean;
  hasCollapsibleList: boolean;
}>`
  display: flex;
  flex-direction: column;

  > * + * {
    margin: ${(props) =>
        props.hasCollapsibleList
          ? props.theme.spacings.medium
          : props.theme.spacings.huge}
      0 0 0;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: grid;
    grid-template-columns: ${(props) =>
      props.isSingleColumn ? "1fr" : "1fr 1fr"};

    ${(props) =>
      props.mediaItemsHaveCardView
        ? css`
            gap: ${props.theme.spacings.huge} ${props.theme.spacings.medium};
          `
        : css`
            gap: ${props.hasCollapsibleList
              ? props.theme.spacings.medium
              : props.theme.spacings.gigantic};
          `};

    > * + * {
      margin: 0;
    }
  }
`;

type MediaItemListProps = {
  content: Contentful_ContentfulMediaItemListFragment;
};

const MediaItemList: React.FC<MediaItemListProps> = ({ content }) => {
  const mediaItemsType =
    content.mediaItems?.[0]?.__typename === "ContentfulCollapsible"
      ? "ContentfulCollapsible"
      : "ContentfulMediaItem";

  return (
    <Spacings.Stack scale="huge">
      {content.title ? (
        <Text
          textStyle="headline5"
          as="h3"
          dangerouslySetInnerHTML={{
            __html: content.title,
          }}
        />
      ) : null}

      <StyledMediaItemList
        isSingleColumn={content.isSingleColumn || false}
        mediaItemsHaveCardView={content.wrapEachMediaItemInCard || false}
        hasCollapsibleList={mediaItemsType === "ContentfulCollapsible"}
      >
        {mediaItemsType === "ContentfulMediaItem"
          ? content.mediaItems?.map((mediaItem) =>
              mediaItem?.__typename === "ContentfulMediaItem" ? (
                <MediaItem
                  key={mediaItem.id}
                  mediaItem={mediaItem}
                  isCardView={content.wrapEachMediaItemInCard || false}
                />
              ) : null,
            )
          : null}

        {mediaItemsType === "ContentfulCollapsible" ? (
          <CollapsibleList mediaItems={content.mediaItems} />
        ) : null}
      </StyledMediaItemList>
    </Spacings.Stack>
  );
};

export const mediaItemListFragment = graphql`
  fragment Contentful_ContentfulMediaItemList on ContentfulMediaItemList {
    title
    mediaItems {
      ...MediaItemFragment
      ...Collapsible_ContentfulCollapsible
    }
    isSingleColumn
    wrapEachMediaItemInCard
  }
`;

export default MediaItemList;
