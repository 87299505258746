import styled from "styled-components";

import Text, {
  cssByTextStyle,
  fontSizeByTextStyle,
  lineHeightByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";

const Markdown = styled(Text).attrs({
  as: "div",
  textStyle: "body",
})`
  p + p {
    margin-top: ${(props) => props.theme.spacings.medium};
  }
  h1 {
    ${cssByTextStyle.headline1 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline1}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline1};
    margin-bottom: ${(props) => props.theme.spacings.big};
  }
  * + h1 {
    margin-top: ${(props) => props.theme.spacings.huge};
  }
  h2 {
    ${cssByTextStyle.headline2 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline2}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline2};
    margin-bottom: ${(props) => props.theme.spacings.big};
  }
  * + h2 {
    margin-top: ${(props) => props.theme.spacings.huge};
  }
  h3 {
    ${cssByTextStyle.headline3 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline3}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline3};
    margin-bottom: ${(props) => props.theme.spacings.medium};
  }
  * + h3 {
    margin-top: ${(props) => props.theme.spacings.big};
  }
  h4 {
    ${cssByTextStyle.headline4 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline4}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline4};
    margin-bottom: ${(props) => props.theme.spacings.medium};
  }
  * + h4 {
    margin-top: ${(props) => props.theme.spacings.big};
  }
  h5 {
    ${cssByTextStyle.headline5 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline5}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline5};
    margin-bottom: ${(props) => props.theme.spacings.medium};
  }
  * + h5 {
    margin-top: ${(props) => props.theme.spacings.big};
  }
  h6 {
    ${cssByTextStyle.headline6 as any};
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline6}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline6};
    margin-bottom: ${(props) => props.theme.spacings.medium};
  }
  * + h6 {
    margin-top: ${(props) => props.theme.spacings.big};
  }
  ul {
    display: block;
    list-style-type: disc;
    counter-reset: list-item;
    list-style-image: none;
    list-style-position: outside;
    margin: ${(props) => props.theme.spacings.medium} 0;
    padding: 0 0 0 ${(props) => props.theme.spacings.huge};
  }
  ol {
    display: block;
    list-style-type: decimal;
    counter-reset: list-item;
    list-style-image: none;
    list-style-position: outside;
    margin: ${(props) => props.theme.spacings.medium} 0;
    padding: 0 0 0 ${(props) => props.theme.spacings.huge};
  }
  li + li {
    margin-top: ${(props) => props.theme.spacings.small};
  }
`;

export default Markdown;
