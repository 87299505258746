import React, { createRef, useContext, useEffect, useState } from "react";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Center from "@finanzchef24gmbh/design-system/src/Center";
import Collapsible from "@finanzchef24gmbh/design-system/src/Collapsible";
import Dialog from "@finanzchef24gmbh/design-system/src/Dialog";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import Check1Icon from "@finanzchef24gmbh/design-system/src/Icons/Check1Icon";
import LabelledCheckbox from "@finanzchef24gmbh/design-system/src/LabelledCheckbox";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import ResponsiveContainer from "@finanzchef24gmbh/design-system/src/ResponsiveContainer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import styled from "styled-components";
import { COOKIES, COOKIE_TABLE_CONTENTFUL } from "./constants";

import CookieConsentContextProvider, {
  CookieConsentContext,
  CookieConsentLevels,
} from "../CookieConsentContextProvider";

type StyledDialogProps = {
  isOptionsView: boolean;
};

const StyledDialog = styled(Dialog)<StyledDialogProps>`
  max-width: ${(props) => (props.isOptionsView ? "35em" : "40em")};
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-height: 40em;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-width: 300px;
  }
`;

const BoldCheckboxLabel = styled.span`
  font-weight: bold;
`;

const CookieOptionsHeadlineWrapper = styled.div`
  margin: ${(props) => props.theme.spacings.medium} 0;
`;

const DescriptionContainer = styled.div`
  margin-left: 35px;
`;

const CookiesActionsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  > * + * {
    margin: ${(props) => props.theme.spacings.big} 0;
  }
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
    justify-content: flex-end;
    > * + * {
      margin: 0 0 0 ${(props) => props.theme.spacings.big};
    }
  }
`;

const StyledIllustration = styled.img`
  height: 10em;
  width: 10em;
  display: block;
`;

const Content = styled(Spacings.Inline)`
  flex-grow: 1;
  margin-top: ${(props) => props.theme.spacings.medium};
  margin-bottom: ${(props) => props.theme.spacings.big};
`;

const StyledHeading = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.regular};
`;

export const runScriptsForCookieConsentLevels = (
  cookieConsentLevels: CookieConsentLevels,
) => {
  Object.entries(cookieConsentLevels).forEach(
    ([key, isCookieConsentLevelAccepted]) => {
      if (!isCookieConsentLevelAccepted) {
        // Only run scripts for levels that the user accepted, i.e. where the value
        // is `true`.
        return;
      }
      const scriptNodes = document.querySelectorAll(
        `script[cookie-consent='${key}']`,
      );
      // IE11 doesn't support `.forEach` or `.entries` on a NodeList, so we use
      // a basic for loop.
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < scriptNodes.length; index += 1) {
        const scriptElement = scriptNodes[index];
        if (scriptElement instanceof HTMLScriptElement) {
          const newScript = document.createElement("script");
          newScript.setAttribute("type", "text/javascript");
          newScript.setAttribute("initial-cookie-consent", key);
          const src = scriptElement.getAttribute("src");
          if (src) {
            newScript.setAttribute("src", src);
          }
          newScript.text = scriptElement.innerHTML;
          scriptElement.parentNode?.appendChild(newScript);
          scriptElement.parentNode?.removeChild(scriptElement);
        }
      }
    },
  );
};

const CookieOptions: React.FC<
  React.PropsWithChildren<{
    cookieConsentLevels: CookieConsentLevels | null;
    setCookieConsentLevels: (levels: CookieConsentLevels) => void;
  }>
> = (props) => {
  // If there is no consent cookie yet, i.e. if the user never accepted any
  // cookies yet, then we are legally required to disable the checkboxed by
  // default. The user has to explicitly allow us to use certain kinds of
  // cookies.
  const [functionality, setFunctionality] = useState(
    props.cookieConsentLevels?.functionality || false,
  );
  const [tracking, setTracking] = useState(
    props.cookieConsentLevels?.tracking || false,
  );
  const [targeting, setTargeting] = useState(
    props.cookieConsentLevels?.targeting || false,
  );
  return (
    <Spacings.Stack scale="gigantic">
      <Spacings.Stack scale="huge">
        <Text textStyle="body">{COOKIES.OPTIONS_DESCRIPTION}</Text>
        <StyledButton
          buttonType="primary"
          onClick={() => {
            const levels = {
              functionality: true,
              "strictly-necessary": true,
              targeting: true,
              tracking: true,
            };
            props.setCookieConsentLevels(levels);
          }}
        >
          Alle akzeptieren
        </StyledButton>
      </Spacings.Stack>
      <Spacings.Stack scale="huge">
        <Spacings.Stack>
          <Spacings.Inline alignItems="center" scale="medium">
            <Check1Icon color="auxiliary" scale="medium" />
            <Text textStyle="headline6">
              {COOKIES.LEVELS.STRICTLY_NECESSARY.HEADLINE}
            </Text>
          </Spacings.Inline>
          <DescriptionContainer>
            <Text textStyle="caption">
              {COOKIES.LEVELS.STRICTLY_NECESSARY.DESCRIPTION}
            </Text>
          </DescriptionContainer>
        </Spacings.Stack>
        <ResponsiveContainer showOn={["desktop", "tablet"]}>
          <Spacings.Stack>
            <LabelledCheckbox
              checked={functionality}
              onChange={() => setFunctionality((value) => !value)}
              name="functionality"
              label={
                <BoldCheckboxLabel>
                  {COOKIES.LEVELS.FUNCTIONALITY.HEADLINE}
                </BoldCheckboxLabel>
              }
              value="yes"
              aria-describedby="functionality-description"
            />
            <DescriptionContainer>
              <Text textStyle="caption" id="functionality-description">
                {COOKIES.LEVELS.FUNCTIONALITY.DESCRIPTION}
              </Text>
            </DescriptionContainer>
          </Spacings.Stack>
          <Spacings.Stack>
            <LabelledCheckbox
              checked={tracking}
              onChange={() => setTracking((value) => !value)}
              name="tracking"
              label={
                <BoldCheckboxLabel>
                  {COOKIES.LEVELS.TRACKING.HEADLINE}
                </BoldCheckboxLabel>
              }
              value="yes"
              aria-describedby="tracking-description"
            />
            <DescriptionContainer>
              <Text textStyle="caption" id="tracking-description">
                {COOKIES.LEVELS.TRACKING.DESCRIPTION}
              </Text>
            </DescriptionContainer>
          </Spacings.Stack>
          <Spacings.Stack>
            <LabelledCheckbox
              checked={targeting}
              onChange={() => setTargeting((value) => !value)}
              name="targeting"
              label={
                <BoldCheckboxLabel>
                  {COOKIES.LEVELS.TARGETING.HEADLINE}
                </BoldCheckboxLabel>
              }
              value="yes"
              aria-describedby="targeting-description"
            />
            <DescriptionContainer>
              <Text textStyle="caption" id="targeting-description">
                {COOKIES.LEVELS.TARGETING.DESCRIPTION}
              </Text>
            </DescriptionContainer>
          </Spacings.Stack>
        </ResponsiveContainer>
        <ResponsiveContainer showOn={["mobile"]}>
          <Collapsible>
            <Collapsible.Header>
              <Collapsible.Headline>
                <LabelledCheckbox
                  checked={functionality}
                  onChange={() => setFunctionality((value) => !value)}
                  name="functionality"
                  label={
                    <BoldCheckboxLabel>
                      {COOKIES.LEVELS.FUNCTIONALITY.HEADLINE}
                    </BoldCheckboxLabel>
                  }
                  value="yes"
                  aria-describedby="functionality-description"
                />
              </Collapsible.Headline>
              <Collapsible.Chevron />
            </Collapsible.Header>
            <Collapsible.Content>
              <Content>
                <DescriptionContainer>
                  <Text textStyle="caption" id="functionality-description">
                    {COOKIES.LEVELS.FUNCTIONALITY.DESCRIPTION}
                  </Text>
                </DescriptionContainer>
              </Content>
            </Collapsible.Content>
          </Collapsible>
          <Collapsible>
            <Collapsible.Header>
              <Collapsible.Headline>
                <LabelledCheckbox
                  checked={tracking}
                  onChange={() => setTracking((value) => !value)}
                  name="tracking"
                  label={
                    <BoldCheckboxLabel>
                      {COOKIES.LEVELS.TRACKING.HEADLINE}
                    </BoldCheckboxLabel>
                  }
                  value="yes"
                  aria-describedby="tracking-description"
                />
              </Collapsible.Headline>
              <Collapsible.Chevron />
            </Collapsible.Header>
            <Collapsible.Content>
              <Content>
                <DescriptionContainer>
                  <Text textStyle="caption" id="tracking-description">
                    {COOKIES.LEVELS.TRACKING.DESCRIPTION}
                  </Text>
                </DescriptionContainer>
              </Content>
            </Collapsible.Content>
          </Collapsible>
          <Collapsible>
            <Collapsible.Header>
              <Collapsible.Headline>
                <LabelledCheckbox
                  checked={targeting}
                  onChange={() => setTargeting((value) => !value)}
                  name="targeting"
                  label={
                    <BoldCheckboxLabel>
                      {COOKIES.LEVELS.TARGETING.HEADLINE}
                    </BoldCheckboxLabel>
                  }
                  value="yes"
                  aria-describedby="targeting-description"
                />
              </Collapsible.Headline>
              <Collapsible.Chevron />
            </Collapsible.Header>
            <Collapsible.Content>
              <Content>
                <DescriptionContainer>
                  <Text textStyle="caption" id="targeting-description">
                    {COOKIES.LEVELS.TARGETING.DESCRIPTION}
                  </Text>
                </DescriptionContainer>
              </Content>
            </Collapsible.Content>
          </Collapsible>
        </ResponsiveContainer>
        <StyledButton
          buttonType="primary"
          onClick={() => {
            props.setCookieConsentLevels({
              functionality,
              "strictly-necessary": true,
              targeting,
              tracking,
            });
          }}
        >
          Einstellungen speichern
        </StyledButton>
      </Spacings.Stack>
    </Spacings.Stack>
  );
};

const CookieConsent: React.FC<
  React.PropsWithChildren<{
    origin: string;
    renderLink: (linkProps: {
      href: string;
      onClick: () => void;
    }) => React.ReactNode;
  }>
> = (props) => {
  const toggleRef = createRef<HTMLButtonElement>();
  const dialogState = useDialogState();
  const [showOptions, setShowOptions] = useState(false);
  const { cookieConsentLevels, setCookieConsentLevels } =
    useContext(CookieConsentContext);

  useEffect(() => {
    const matchCookie = document.cookie.match(
      /(^|;)\s*cookie_consent_level=([{}":\-,a-zA-Z0-9]+)\s*($|;)/,
    );

    const isPrivacyPolicyPage = location.pathname.includes(
      "ueber-uns/datenschutz",
    );

    if (!matchCookie) {
      if (isPrivacyPolicyPage) return;
      dialogState.open();
      return;
    }

    let levels: CookieConsentLevels;
    try {
      levels = JSON.parse(matchCookie[2]);
      if (!levels || typeof levels !== "object") {
        throw new Error("Malformed consent cookie");
      }
    } catch (error) {
      dialogState.open();
      return;
    }

    setCookieConsentLevels(levels);
    runScriptsForCookieConsentLevels(levels);
  }, []);

  const getCookieString = (levels: CookieConsentLevels) => {
    // To be able to read this cookie in an iframe on a different website
    // it has to have samesite=none but it does not work without secure=true.
    // https://web.dev/samesite-cookies-explained/
    // https://web.dev/samesite-cookie-recipes/
    const extraCookieFlags =
      location.protocol === "https:" ? ";samesite=none;secure=true" : "";
    let cookie = `cookie_consent_level=${JSON.stringify(
      levels,
    )};path=/;max-age=31536000${extraCookieFlags}`;

    const domain = props.origin.replace(/^https?:\/\/(www\.)?/, "");
    if (location.host.match(/finanzchef24\.de/)) {
      cookie += `;domain=${domain}`;
    }

    return cookie;
  };

  return (
    <CookieConsentContextProvider>
      {props.renderLink({
        href: "#",
        onClick: () => {
          setShowOptions(true);
          dialogState.open();
        },
      })}
      <StyledDialog
        focusTrapOptions={{
          initialFocus: '[role="dialog"]',
        }}
        closeLabel="Dialog schließen"
        visible={dialogState.visible}
        toggleRef={toggleRef}
        isClosable={showOptions}
        isOptionsView={showOptions}
        content={
          <Spacings.Inline scale="big">
            <Spacings.Stack scale="big">
              {showOptions ? (
                <CookieOptions
                  cookieConsentLevels={cookieConsentLevels}
                  setCookieConsentLevels={(levels) => {
                    document.cookie = getCookieString(levels);
                    if (cookieConsentLevels !== null) {
                      // If the user wants to change his settings (i.e. if there
                      // already exists a consent cookie) we have to reload the
                      // page in order to execute (or not execute) scripts according
                      // to the updated settings.
                      if (
                        levels.functionality !==
                          cookieConsentLevels.functionality ||
                        levels.tracking !== cookieConsentLevels.tracking ||
                        levels.targeting !== cookieConsentLevels.targeting
                      ) {
                        // However, we only need to reload the page if the user actually
                        // changed some of the settings.
                        location.reload();
                      } else {
                        // Otherwise we just close the dialog.
                        dialogState.close();
                      }
                    } else {
                      // Otherwise we store the selected settings in local state
                      // (in case the user opens up the dialog later in the same
                      // session) and close the dialog.
                      setCookieConsentLevels(levels);
                      dialogState.close();
                      runScriptsForCookieConsentLevels(levels);
                    }
                  }}
                />
              ) : (
                <>
                  <Spacings.Inline alignItems="flex-start">
                    <Spacings.Stack scale="huge">
                      <StyledHeading textStyle="headline4" as="h2">
                        {COOKIES.GENERAL_HEADLINE}
                      </StyledHeading>
                      <Text>
                        {COOKIES.GENERAL_DESCRIPTION}{" "}
                        <Link
                          href={`${props.origin}/ueber-uns/datenschutz`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Datenschutzhinweisen
                        </Link>
                        . {COOKIES.GENERAL_OVERVIEW}{" "}
                        <Link
                          href={COOKIE_TABLE_CONTENTFUL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hier
                        </Link>
                        .
                      </Text>
                    </Spacings.Stack>
                    <ResponsiveContainer showOn={["desktop", "tablet"]}>
                      <div>
                        <StyledIllustration
                          src="https://images.ctfassets.net/ta73me28xrs3/4hSGkVXjwCkpgGIAt4OhmN/b78d1e9bb606de3d36d7df4d3716eaa9/cookie-consent-illustration.svg"
                          alt="Finanzchef24 cookies"
                          loading="eager"
                        />
                      </div>
                    </ResponsiveContainer>
                  </Spacings.Inline>
                  <CookiesActionsContainer>
                    <StyledButton
                      buttonType="primary"
                      onClick={() => {
                        // If the user clicks this button we may use only necessary cookies.
                        const levels = {
                          functionality: false,
                          "strictly-necessary": true,
                          targeting: false,
                          tracking: false,
                        };
                        setCookieConsentLevels(levels);
                        document.cookie = getCookieString(levels);
                        dialogState.close();
                        runScriptsForCookieConsentLevels(levels);
                      }}
                    >
                      Nur erforderliche Cookies
                    </StyledButton>
                    <StyledButton
                      buttonType="primary"
                      onClick={() => {
                        // If the user clicks this button we may use all kinds of cookies.
                        const levels = {
                          functionality: true,
                          "strictly-necessary": true,
                          targeting: true,
                          tracking: true,
                        };
                        setCookieConsentLevels(levels);
                        document.cookie = getCookieString(levels);
                        dialogState.close();
                        runScriptsForCookieConsentLevels(levels);
                      }}
                    >
                      Akzeptieren
                    </StyledButton>
                  </CookiesActionsContainer>
                  <Center>
                    <Link
                      href="#"
                      onClick={(event) => {
                        // We need to stop propagating the event, because otherwise
                        // the user also sees the error message after the options
                        // show up. This is only an issue in Preact (which we use
                        // for the homepage). It works fine with React.
                        // To be more specific, I guess that Preact immediately rerenders
                        // when the state is updated. The dialog also catches the click
                        // event, but the link is already not part of the dialog content
                        // anymore. So the dialog behaves like the user clicked outside the
                        // dialog and tries to close it, which in this case leads to the
                        // error message showing up.
                        event.stopPropagation();
                        setShowOptions(true);
                      }}
                    >
                      Cookie-Einstellungen
                    </Link>
                  </Center>
                </>
              )}
            </Spacings.Stack>
          </Spacings.Inline>
        }
        header={
          showOptions && (
            <CookieOptionsHeadlineWrapper>
              <StyledHeading textStyle="headline4" as="h2">
                {COOKIES.OPTIONS_HEADLINE}
              </StyledHeading>
            </CookieOptionsHeadlineWrapper>
          )
        }
        onClose={() =>
          cookieConsentLevels !== null
            ? dialogState.close()
            : setShowOptions(false)
        }
      />
    </CookieConsentContextProvider>
  );
};

export default CookieConsent;
