import React from "react";
import styled from "styled-components";

import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { SavingsBadge_ContentfulSavingsBadgeFragment } from "../../../../../types/graphql-types";
import Image from "../../../Image";

const StyledLogoPriceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 1rem;
  align-items: center;
  height: 2.25rem;
`;

const StyledPriceContainer = styled.div`
  display: flex;
`;

const StyledPrice = styled(Text)<{ color: "mint" | "error" }>`
  color: ${(props) =>
    props.theme.palette[props.color][props.color === "mint" ? 600 : 500]};
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  :nth-of-type(2) {
    align-self: flex-end;
  }
`;

const StyledPriceSmallText = styled(Text)`
  font-size: 0.625rem;
`;

type PriceCardProps = {
  color: "mint" | "error";
  logo:
    | SavingsBadge_ContentfulSavingsBadgeFragment["overlayFirstCardLogo"]
    | SavingsBadge_ContentfulSavingsBadgeFragment["overlaySecondCardLogo"];
  price:
    | SavingsBadge_ContentfulSavingsBadgeFragment["overlayFirstCardFavourablePrice"]
    | SavingsBadge_ContentfulSavingsBadgeFragment["overlaySecondCardExpensivePrice"];
};

const PriceCard: React.FC<PriceCardProps> = ({ color, logo, price }) => (
  <Card>
    <Spacings.Inset scale="medium">
      <StyledLogoPriceContainer>
        {(logo?.fixed || logo?.file?.url) && (
          <Image asset={logo} width="109" maxHeight="35" />
        )}
        <Spacings.Stack scale="small">
          <StyledPriceContainer>
            <StyledPrice textStyle="headline4" as="span" color={color}>
              {price?.split(",")[0]}
            </StyledPrice>
            <StyledPrice as="span" color={color}>
              ,
            </StyledPrice>
            <StyledPrice as="span" color={color}>
              {price?.split(",")[1]} €
            </StyledPrice>
          </StyledPriceContainer>
          <StyledPriceSmallText priority="secondary">
            Jährlich
          </StyledPriceSmallText>
        </Spacings.Stack>
      </StyledLogoPriceContainer>
    </Spacings.Inset>
  </Card>
);

export default PriceCard;
