import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockPromotions_ContentfulBlockPromotionsFragment } from "../../../../types/graphql-types";
import {
  trackBlockBottomLink,
  trackCardLink,
} from "../../../utils/trackingUtils";
import Button from "../../Button";
import Center from "../../Center";
import Markdown from "../../Markdown";
import ImageWithBadge from "./ImageWithBadge";
import SolutionCard from "./SolutionCard";

type BlockPromotionsProps = {
  contentBlock: BlockPromotions_ContentfulBlockPromotionsFragment;
};

const StyledBlock = styled.div<{ dontUseGrayBackground?: boolean }>`
  ${(props) =>
    !props.dontUseGrayBackground &&
    css`
      background-color: ${props.theme.palette.gray[25]};
      border-top: ${props.theme.borders.width.small}px solid
        ${props.theme.palette.gray[100]};
      border-bottom: ${props.theme.borders.width.small}px solid
        ${props.theme.palette.gray[100]};
      padding: ${props.theme.spacings.gigantic} 0;
    `};
`;

const StyledCenter = styled(Center)`
  max-width: calc(
    ${(props) => props.theme.layout.articleWidth} +
      ${(props) => props.theme.spacings.medium}
  );
`;

const StyledHeading = styled(Text)<{ isHeadingCentred?: boolean }>`
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding: 0 ${(props) => props.theme.spacings.small};
  }

  ${(props) =>
    props.isHeadingCentred &&
    css`
      text-align: center;
    `}
`;

const StyledSpacingsInset = styled(Spacings.Inset)`
  margin-left: -${(props) => props.theme.spacings.medium};
  margin-right: -${(props) => props.theme.spacings.medium};
  overflow-x: auto;
`;

const StyledGridUl = styled.ul<{ numberOfCards: number }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${(props) => props.theme.spacings.huge};

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    ${(props) =>
      props.numberOfCards === 2
        ? css`
            grid-template-columns: repeat(2, auto);
            justify-content: center;
          `
        : css`
            grid-template-columns: repeat(3, 1fr);
          `};

    padding: 0 ${(props) => props.theme.spacings.small};
  }
`;

const StyledLi = styled.li<{ lastCardIndex: number }>`
  display: flex;

  ${(props) =>
    props.lastCardIndex < 4
      ? css`
          &:first-of-type {
            margin-left: auto;
          }

          &:last-of-type {
            margin-right: auto;
            padding-right: ${props.theme.spacings.medium};
          }
        `
      : css`
          &:nth-of-type(4) {
            padding-right: ${props.theme.spacings.medium};
          }

          @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
            &:nth-of-type(3) {
              padding-right: ${props.theme.spacings.medium};
            }

            &:nth-of-type(4) {
              padding-right: 0;
            }
          }
        `};
`;

const StyledSolutionLi = styled.li`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: block;
    grid-column: 2 / 4;
    padding-right: ${(props) => props.theme.spacings.medium};
  }
`;

const StyledMobileSolutionCardWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: none;
  }
`;

const StyledStackCard = styled(Spacings.Stack)`
  width: 16.5rem;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 17.5rem;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: auto;
  margin-top: auto;
`;

const StyledBlockLink = styled(Link)`
  text-align: center;
`;

const BlockPromotions: React.FC<BlockPromotionsProps> = (props) => (
  <StyledBlock
    dontUseGrayBackground={props.contentBlock.dontUseGrayBackground || false}
  >
    <StyledCenter>
      <Spacings.Stack scale="huge">
        {props.contentBlock.heading ? (
          <StyledHeading
            isHeadingCentred={props.contentBlock.isHeadingCentred || false}
            textStyle="headline4"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: props.contentBlock.heading,
            }}
          />
        ) : null}

        <StyledSpacingsInset scale="medium">
          <StyledGridUl numberOfCards={props.contentBlock?.cards?.length || 0}>
            {props.contentBlock.cards?.map((card, index) => {
              if (!card) {
                return null;
              }

              const link =
                card.button?.assetDownload?.file?.url ||
                card.button?.buttonLink;

              return (
                <StyledLi
                  key={index}
                  lastCardIndex={props.contentBlock?.cards?.length || 0}
                >
                  <StyledStackCard scale="big">
                    <ImageWithBadge card={card} />

                    {card.title ? (
                      <Text
                        textStyle="headline5"
                        as="span"
                        dangerouslySetInnerHTML={{
                          __html: card.title,
                        }}
                      />
                    ) : null}

                    {card.description?.childMarkdownRemark?.html ? (
                      <Markdown
                        as="div"
                        dangerouslySetInnerHTML={{
                          __html: card.description.childMarkdownRemark.html,
                        }}
                      />
                    ) : null}

                    {card.button?.newsletterRegistration?.useHubSpotForm ? (
                      <StyledButtonWrapper>
                        <StyledButton content={{ ...card.button }} />
                      </StyledButtonWrapper>
                    ) : card.button && link ? (
                      <StyledButtonWrapper>
                        <StyledButton
                          content={{ ...card.button }}
                          trackLink={() => trackCardLink("promotions", link)}
                        />
                      </StyledButtonWrapper>
                    ) : null}
                  </StyledStackCard>
                </StyledLi>
              );
            })}

            {props.contentBlock.cards?.length === 4 &&
              props.contentBlock.solutionCard && (
                <StyledSolutionLi>
                  <SolutionCard
                    content={{ ...props.contentBlock.solutionCard }}
                  />
                </StyledSolutionLi>
              )}
          </StyledGridUl>
        </StyledSpacingsInset>

        {props.contentBlock.cards?.length === 4 &&
          props.contentBlock.solutionCard && (
            <StyledMobileSolutionCardWrapper>
              <SolutionCard content={{ ...props.contentBlock.solutionCard }} />
            </StyledMobileSolutionCardWrapper>
          )}

        {props.contentBlock.linkUrl && (
          <StyledBlockLink
            href={props.contentBlock.linkUrl}
            onClick={() =>
              trackBlockBottomLink("promotions", props.contentBlock.linkUrl)
            }
          >
            {props.contentBlock.linkText}
          </StyledBlockLink>
        )}
      </Spacings.Stack>
    </StyledCenter>
  </StyledBlock>
);

export const blockPromotionsFragment = graphql`
  fragment BlockPromotions_ContentfulBlockPromotions on ContentfulBlockPromotions {
    heading
    isHeadingCentred
    linkText
    linkUrl
    dontUseGrayBackground
    cards {
      badgeText
      badgeColor
      image {
        title
        description
        file {
          url
        }
        fixed(width: 280, height: 160, quality: 100) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      button {
        ...Button_ContentfulButton
      }
    }
    solutionCard {
      ...SolutionCard_ContentfulSolutionCard
    }
  }
`;

export default BlockPromotions;
