import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import TuvLogo from "../../../../images/tuev.svg";
import { Maybe, TrustSealsQuery } from "../../../../../types/graphql-types";

const StyledContainerInline = styled.div<{
  hideOnDesktop: boolean;
  useNewConcept?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > * + * {
    margin: 0 0 0 ${(props) => props.theme.spacings.small};
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    justify-content: flex-start;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    /*
    * Fixes a Safari rendering intersection bug that causes an image with transform: rotateY to
    * partially overlap the dialog.
    * Example page: https://www.finanzchef24.de/versicherung/architekt
    * Solution: https://stackoverflow.com/a/18167565/5133172
    */
    transform: translateZ(100vw);
    z-index: 1;

    ${(props) =>
      props.hideOnDesktop &&
      (props.useNewConcept
        ? css`
            display: none;
          `
        : css`
            visibility: hidden;
          `)};
  }
`;

const StyledTuvLink = styled.a<{ useNewConcept?: boolean }>`
  height: 2.75rem;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    height: ${(props) => (props.useNewConcept ? "3.3125rem" : "3.5rem")};
  }
`;

const StyledEkomiLink = styled.a<{ useNewConcept?: boolean }>`
  width: 2.75rem;
  height: 2.75rem;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: ${(props) => (props.useNewConcept ? "3.3125rem" : "3rem")};
    height: ${(props) => (props.useNewConcept ? "3.3125rem" : "3rem")};
  }
`;

type TrustSealsSelfHostedProps = {
  hideOnDesktop: boolean;
  useNewConcept?: boolean;
};

// TODO: remove useNewConcept once OldHeroConcept has been deleted
const TrustSealsSelfHosted: React.FC<TrustSealsSelfHostedProps> = ({
  hideOnDesktop,
  useNewConcept,
}) => {
  const data = useStaticQuery<Maybe<TrustSealsQuery>>(graphql`
    query TrustSeals {
      ekomiSeal: contentfulAsset(
        contentful_id: { eq: "4mxgfVujaxmFHzWdFNPial" }
      ) {
        fluid(maxWidth: 53, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      ekomiInfo {
        fb_avg
      }
    }
  `);

  return (
    <StyledContainerInline
      hideOnDesktop={hideOnDesktop}
      useNewConcept={useNewConcept}
    >
      <StyledTuvLink
        href="https://www.tuev-saar.de/SC45304"
        target="_blank"
        useNewConcept={useNewConcept}
      >
        <TuvLogo height="100%" />
      </StyledTuvLink>

      <Spacings.Inline>
        {data?.ekomiSeal?.fluid && (
          <StyledEkomiLink
            href="https://www.ekomi.de/bewertungen-finanzchef24.html"
            target="_blank"
            useNewConcept={useNewConcept}
          >
            <Img
              fluid={data.ekomiSeal.fluid as FluidObject}
              alt="eKomi Siegel Gold für Finanzchef24"
              loading="eager"
              fadeIn={false}
            />
          </StyledEkomiLink>
        )}
      </Spacings.Inline>
    </StyledContainerInline>
  );
};

export default TrustSealsSelfHosted;
