import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, {
  fontSizeByTextStyle,
  lineHeightByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { BlockParagraph_ContentfulBlockParagraphFragment } from "../../../types/graphql-types";
import useTableOfContentsInView from "../../hooks/useTableOfContentsInView";
import { addExternalLinkAttributes } from "../../utils/index";
import { trackBlockParagraphButton } from "../../utils/trackingUtils";
import Button from "../Button";
import Center from "../Center";
import Markdown from "../Markdown";

const StyledCtaButton = styled.div`
  align-self: flex-start;
`;

const StyledParagraphBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-left: ${(props) => props.theme.spacings.big};
    padding-right: ${(props) => props.theme.spacings.big};
  }
`;

const StyledMarkdown = styled(Markdown)`
  blockquote {
    position: relative;
    padding: ${(props) => props.theme.spacings.small}
      ${(props) => props.theme.spacings.medium};

    &:before {
      content: "";
      width: 0.25rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${(props) => props.theme.palette.mint[600]};
      border-radius: ${(props) => props.theme.borders.radius.big}px;
    }

    @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
      padding: ${(props) => props.theme.spacings.medium}
        ${(props) => props.theme.spacings.gigantic};

      &:before {
        left: ${(props) => props.theme.spacings.huge};
      }
    }

    p {
      &:not(:last-of-type) {
        font-style: italic;
        font-size: ${(props) => fontSizeByTextStyle(props.theme).headline4}rem;
        line-height: ${(props) => lineHeightByTextStyle(props.theme).headline4};
        font-weight: ${(props) => props.theme.typography.weights.regular};
        color: ${(props) => props.theme.palette.gray[700]};
      }

      &:last-of-type {
        font-size: ${(props) =>
          fontSizeByTextStyle(props.theme).bodyProlonged}rem;
        line-height: ${(props) =>
          lineHeightByTextStyle(props.theme).bodyProlonged};
        color: ${(props) => props.theme.palette.mint[600]};
        margin-top: ${(props) => props.theme.spacings.big};
      }
    }
  }
`;

type BlockParagraphProps = {
  indexNumber?: number;
  contentBlock: BlockParagraph_ContentfulBlockParagraphFragment;
  className?: string;
};

const BlockParagraph: React.FC<BlockParagraphProps> = (props) => {
  const buttonLink =
    props.contentBlock.ctaButton?.assetDownload?.file?.url ||
    props.contentBlock.ctaButton?.buttonLink;

  const setRefs = useTableOfContentsInView(
    props.contentBlock.includeInTableOfContents || false,
    props.contentBlock.slug,
  );

  return (
    <StyledParagraphBlock
      ref={setRefs}
      id={props.contentBlock.slug || props.contentBlock.id}
      className={props.className}
    >
      <Spacings.Stack scale="gigantic">
        {props.contentBlock.title ? (
          <Text
            textStyle="headline3"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: `${props.indexNumber ? `${props.indexNumber}. ` : ""}${
                props.contentBlock.title
              }`,
            }}
          />
        ) : null}

        {props.contentBlock.copy?.childMarkdownRemark?.html && (
          <StyledMarkdown
            textStyle="bodyProlonged"
            priority="secondary"
            dangerouslySetInnerHTML={{
              __html: props.contentBlock.overrideDefaultExternalLinkAttributes
                ? props.contentBlock.copy.childMarkdownRemark.html
                : addExternalLinkAttributes(
                    props.contentBlock.copy.childMarkdownRemark.html,
                  ),
            }}
          />
        )}

        {props.contentBlock.ctaButton?.newsletterRegistration
          ?.useHubSpotForm ? (
          <StyledCtaButton>
            <Button scale="big" content={{ ...props.contentBlock.ctaButton }} />
          </StyledCtaButton>
        ) : props.contentBlock.ctaButton?.buttonText && buttonLink ? (
          <StyledCtaButton>
            <Button
              scale="big"
              content={{ ...props.contentBlock.ctaButton }}
              trackLink={() => trackBlockParagraphButton(buttonLink)}
            />
          </StyledCtaButton>
        ) : null}
      </Spacings.Stack>
    </StyledParagraphBlock>
  );
};

export const blockParagraphFragment = graphql`
  fragment BlockParagraph_ContentfulBlockParagraph on ContentfulBlockParagraph {
    id
    type: __typename
    title
    slug
    includeInTableOfContents
    copy {
      childMarkdownRemark {
        html
      }
    }
    overrideDefaultExternalLinkAttributes
    ctaButton {
      ...Button_ContentfulButton
    }
  }
`;

export default BlockParagraph;
