import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import {
  ContentfulAssetFile,
  ContentfulFixed,
  Internal,
  Maybe,
  OpenGraphImageQuery,
} from "../../types/graphql-types";

const Seo: React.FC<SeoProps> = ({
  description,
  lang = "de",
  meta = [],
  keywords = [],
  title,
  metaRobotsTag = "index, follow",
  metaStructuredData,
  slug,
  previewImage,
}) => {
  const data = useStaticQuery<Maybe<OpenGraphImageQuery>>(graphql`
    query OpenGraphImage {
      contentfulAsset(contentful_id: { eq: "zPEmfJk5GCPjkBj4YLqZI" }) {
        file {
          url
          contentType
        }
      }
    }
  `);
  const image = {
    contentType:
      previewImage?.file?.contentType ||
      data?.contentfulAsset?.file?.contentType,
    url: previewImage?.fixed?.src || data?.contentfulAsset?.file?.url,
  };
  if (!process.env.GATSBY_ORIGIN) {
    throw new Error("GATSBY_ORIGIN environment variable not set!");
  }
  if (!process.env.GATSBY_GOOGLE_SITE_VERIFICATION) {
    throw new Error(
      "GATSBY_GOOGLE_SITE_VERIFICATION environment variable not set!",
    );
  }

  const structuredDataScript = metaStructuredData ? (
    <script type="application/ld+json">
      {metaStructuredData.internal.content}
    </script>
  ) : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          content: description,
          name: "description",
        },
        {
          content: metaRobotsTag,
          name: "robots",
        },
        {
          content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION,
          name: "google-site-verification",
        },
        {
          content: title,
          property: "og:title",
        },
        {
          content: description,
          property: "og:description",
        },
        {
          content: "website",
          property: "og:type",
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                content: keywords.join(", "),
                name: "keywords",
              }
            : [],
        )
        .concat(
          slug
            ? {
                content: `${process.env.GATSBY_ORIGIN}${
                  slug.startsWith("/") ||
                  process.env.GATSBY_ORIGIN.endsWith("/")
                    ? slug
                    : `/${slug}`
                }`,
                property: "og:url",
              }
            : [],
        )
        .concat(
          image.url && image.contentType
            ? [
                {
                  content: image.url,
                  property: "og:image",
                },
                {
                  content: image.contentType,
                  property: "og:image:type",
                },
                {
                  content: "1200",
                  property: "og:image:width",
                },
                {
                  content: "630",
                  property: "og:image:height",
                },
              ]
            : [],
        )
        .concat(
          slug === "/"
            ? {
                content: "il92st4ku59nqmv7tjyxvuqyfdubi4",
                name: "facebook-domain-verification",
              }
            : [],
        )
        .concat(meta)}
    >
      {structuredDataScript}
    </Helmet>
  );
};

type SeoProps = {
  description?: string;
  lang?: string;
  slug?: string;
  meta?: any[];
  keywords?: string[];
  title: string;
  metaRobotsTag?: string;
  metaStructuredData?: Maybe<{ internal: Pick<Internal, "content"> }> | null;
  previewImage?: {
    file?: Pick<ContentfulAssetFile, "contentType"> | null;
    fixed?: Pick<ContentfulFixed, "src"> | null;
  };
};

export default Seo;
