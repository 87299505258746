import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { InsurerLogosWhiteQuery, Maybe } from "../../../../types/graphql-types";

const StyledInsurerLogos = styled(Spacings.Inline)`
  opacity: 0.7;

  @media (max-width: ${(props) =>
      props.theme.layout.mobileMaxWidthBreakpoint}) {
    justify-content: center;
  }
`;

const StyledInsurerLogo = styled.img`
  &:nth-child(n + 5) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    &:nth-child(n + 5) {
      display: block;
    }

    &:nth-child(n + 10) {
      display: none;
    }
  }
`;

const InsurerLogosWhite: React.FC<{ className?: string }> = ({ className }) => {
  const data = useStaticQuery<Maybe<InsurerLogosWhiteQuery>>(graphql`
    query InsurerLogosWhite {
      allianz: contentfulAsset(
        contentful_id: { eq: "2Rny0nFPFi77WecH8Po650" }
      ) {
        file {
          url
        }
      }
      hdi: contentfulAsset(contentful_id: { eq: "2dLgVIuIpnTcOIEEKkBtN5" }) {
        file {
          url
        }
      }
      rplusv: contentfulAsset(contentful_id: { eq: "5WJPCDDSNANHwhgK7x8rFE" }) {
        file {
          url
        }
      }
      ergo: contentfulAsset(contentful_id: { eq: "40wqXiGqak8IKnjU8W1sfz" }) {
        file {
          url
        }
      }
      axa: contentfulAsset(contentful_id: { eq: "6A06BD7R7gjDbFH4nplo3H" }) {
        file {
          url
        }
      }
      roland: contentfulAsset(contentful_id: { eq: "45ZvcS05TrpVb0iyMjOGNA" }) {
        file {
          url
        }
      }
      markel: contentfulAsset(contentful_id: { eq: "6FPXl5gyLfFrrR9E994IAS" }) {
        file {
          url
        }
      }
      rhion: contentfulAsset(contentful_id: { eq: "HpL3kZ04UHUI1fzoRCz5u" }) {
        file {
          url
        }
      }
      gothaer: contentfulAsset(
        contentful_id: { eq: "2avcrf6enkU7USB8vTPiol" }
      ) {
        file {
          url
        }
      }
    }
  `);

  const insurerLogos = data
    ? [
        { src: data.allianz, alt: "Allianz", width: 84, height: 23 },
        { src: data.hdi, alt: "HDI", width: 46, height: 17 },
        { src: data.rplusv, alt: "R+V", width: 46, height: 20 },
        { src: data.ergo, alt: "Ergo", width: 54, height: 17 },
        { src: data.axa, alt: "Axa", width: 29, height: 29 },
        { src: data.roland, alt: "Roland", width: 59, height: 29 },
        { src: data.markel, alt: "Markel", width: 24, height: 29 },
        { src: data.rhion, alt: "Rhion", width: 84, height: 16 },
        { src: data.gothaer, alt: "Gothaer", width: 83, height: 24 },
      ]
    : null;

  return (
    <StyledInsurerLogos
      className={className}
      scale="medium"
      alignItems="center"
    >
      {insurerLogos?.map(
        (insurerLogo) =>
          insurerLogo.src?.file?.url && (
            <StyledInsurerLogo
              key={insurerLogo.alt}
              src={insurerLogo.src.file.url}
              width={insurerLogo.width}
              height={insurerLogo.height}
              alt={insurerLogo.alt}
              loading="lazy"
            />
          ),
      )}
    </StyledInsurerLogos>
  );
};

export default InsurerLogosWhite;
