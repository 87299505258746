import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  MediaItemFragmentFragment,
  Scalars,
} from "../../../../../types/graphql-types";
import List from "../../../List";
import RichTextLink from "../../../RichTextLink";

const StyledText = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const StyledUl = styled.ul`
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
  padding: 0 0 0 ${(props) => props.theme.spacings.huge};

  p {
    /* Needed to align disc with first line on Safari */
    display: inline-table;
  }
`;

type RichTextDescriptionProps = {
  richText: MediaItemFragmentFragment["richTextDescription"];
  designSystemIcon: MediaItemFragmentFragment["designSystemIcon"];
  designSystemIconSize: MediaItemFragmentFragment["designSystemIconSize"];
};

const RichTextDescription: React.FC<RichTextDescriptionProps> = ({
  designSystemIcon,
  designSystemIconSize,
  richText,
}) => (
  <>
    {richText?.json
      ? documentToReactComponents(richText.json, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_NODE, children: any) => {
              const filteredChildren = children.filter(
                (child: React.ReactNodeArray | string) => child !== "",
              );

              return filteredChildren.length ? (
                <StyledText priority="secondary">
                  {filteredChildren.map(
                    (child: React.ReactNodeArray | string) =>
                      typeof child === "string" ? (
                        <span dangerouslySetInnerHTML={{ __html: child }} />
                      ) : (
                        child
                      ),
                  )}
                </StyledText>
              ) : null;
            },
            [INLINES.HYPERLINK]: (node, children: any) => (
              <RichTextLink link={node.data.uri}>
                <span dangerouslySetInnerHTML={{ __html: children[0] }} />
              </RichTextLink>
            ),
            [BLOCKS.UL_LIST]: (node: Scalars["JSON"], children) =>
              designSystemIcon ? (
                <List
                  content={{
                    items: node.content.map((item: Scalars["JSON"]) => ({
                      designSystemIcon,
                      designSystemIconSize,
                      richText: {
                        json: {
                          content: item.content,
                          data: {},
                          nodeType: "document",
                        },
                      },
                    })),
                    listType: "ul",
                  }}
                />
              ) : (
                <StyledUl>
                  <Spacings.Stack scale="medium">
                    {Array.isArray(children)
                      ? children.map((child) => (
                          <Text
                            priority="secondary"
                            dangerouslySetInnerHTML={{
                              __html: child as string,
                            }}
                          />
                        ))
                      : null}
                  </Spacings.Stack>
                </StyledUl>
              ),
          },
        })
      : null}
  </>
);

export default RichTextDescription;
