import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Check1Icon from "@finanzchef24gmbh/design-system/src/Icons/Check1Icon";
import CheckShieldIcon from "@finanzchef24gmbh/design-system/src/Icons/CheckShieldIcon";
import CloseIcon from "@finanzchef24gmbh/design-system/src/Icons/CloseIcon";
import RatingPositiveIcon from "@finanzchef24gmbh/design-system/src/Icons/RatingPositiveIcon";
import {
  PassedProps,
  Scale as DesignSystemIconSize,
} from "@finanzchef24gmbh/design-system/src/Icons/useMappedProps";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { List_ContentfulListFragment } from "../../types/graphql-types";
import RichTextLink from "./RichTextLink";

const StyledSpacingsStack = styled(Spacings.Stack)<{
  designSystemIconSize: DesignSystemIconSize;
}>`
  ${(props) =>
    props.designSystemIconSize === "small"
      ? css`
          padding-top: calc(${props.theme.spacings.tiny} / 2);
        `
      : props.designSystemIconSize === "medium"
        ? css`
            padding-top: ${props.theme.spacings.tiny};
          `
        : props.designSystemIconSize === "big"
          ? css`
              padding-top: calc(
                ${props.theme.spacings.tiny} + ${props.theme.spacings.tiny} / 2
              );
            `
          : null};
`;

const StyledText = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_6]: (_NODE, children) => (
      <Text
        textStyle="headline6"
        dangerouslySetInnerHTML={{ __html: children as string }}
      />
    ),
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => {
      const filteredChildren = children.filter(
        (child: React.ReactNodeArray | string) => child !== "",
      );

      return filteredChildren.length ? (
        <StyledText priority="secondary">
          {filteredChildren.map((child: React.ReactNodeArray | string) =>
            typeof child === "string" ? (
              <span dangerouslySetInnerHTML={{ __html: child }} />
            ) : (
              child
            ),
          )}
        </StyledText>
      ) : null;
    },
    [INLINES.HYPERLINK]: (node, children: any) => (
      <RichTextLink link={node.data.uri}>
        <span dangerouslySetInnerHTML={{ __html: children[0] }} />
      </RichTextLink>
    ),
  },
};

type ListProps = {
  content: List_ContentfulListFragment;
};

const List: React.FC<ListProps> = ({ content }) => {
  const listType = content.listType === "ordered" ? "ol" : "ul";

  const iconComponents: {
    [iconName: string]: React.ComponentType<PassedProps>;
  } = { Check1Icon, CheckShieldIcon, CloseIcon, RatingPositiveIcon };

  return (
    <Spacings.Stack as={listType} scale="big">
      {content.items &&
        content.items.map((item, index) => {
          if (!item) {
            return null;
          }

          const ListItemIcon = item.designSystemIcon
            ? iconComponents[item.designSystemIcon]
            : null;

          const designSystemIconSize =
            (item.designSystemIconSize as DesignSystemIconSize) || "tiny";

          return (
            <Spacings.Inline
              key={index}
              as="li"
              scale="medium"
              alignItems="flex-start"
            >
              {item.icon?.file?.url ? (
                <img src={item.icon.file.url} alt={item.icon.title || ""} />
              ) : (
                ListItemIcon && (
                  <div>
                    <ListItemIcon
                      color={
                        item.designSystemIcon === "CloseIcon"
                          ? "error"
                          : "auxiliary"
                      }
                      scale={designSystemIconSize}
                    />
                  </div>
                )
              )}
              <StyledSpacingsStack
                scale="medium"
                designSystemIconSize={designSystemIconSize}
              >
                {item.richText?.json
                  ? documentToReactComponents(item.richText.json, options)
                  : null}
              </StyledSpacingsStack>
            </Spacings.Inline>
          );
        })}
    </Spacings.Stack>
  );
};

export const ListFragment = graphql`
  fragment List_ContentfulList on ContentfulList {
    listType
    items {
      icon {
        title
        file {
          url
        }
      }
      designSystemIcon
      designSystemIconSize
      richText {
        json
      }
    }
  }
`;

export default List;
