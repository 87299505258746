import React from "react";
import styled from "styled-components";

import Text from "@finanzchef24gmbh/design-system/src/Text";
import Center from "./Center";

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.palette.brand[600]};
  position: relative;
  z-index: 1;
`;

const StyledText = styled(Text)`
  padding: ${(props) => props.theme.spacings.small} 0;
`;

const UnsupportedBrowsersBar: React.FC = () => (
  <StyledContainer>
    <Center intrinsic>
      <StyledText textStyle="caption" isOnDarkBackground>
        Ihr Browser wird nicht mehr unterstützt. Es kann zu Einschränkungen
        kommen.
      </StyledText>
    </Center>
  </StyledContainer>
);

export default UnsupportedBrowsersBar;
