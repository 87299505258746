import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Badge from "@finanzchef24gmbh/design-system/src/Badge";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { SolutionCard_ContentfulSolutionCardFragment } from "../../../../types/graphql-types";
import { trackCardLink } from "../../../utils/trackingUtils";
import Button from "../../Button";
import SwitchInlineStack from "../../SwitchInlineStack";

const StyledCard = styled.div`
  background-color: ${(props) => props.theme.palette.brand[100]};
  height: 100%;
`;

const StyledSpacingsInset = styled(Spacings.Inset)`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: 0 ${(props) => props.theme.spacings.medium}
      ${(props) => props.theme.spacings.big};
  }
`;

const StyledSpacingsStack = styled(Spacings.Stack)`
  flex: 1.5;
`;

const StyledImgWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 13.125rem;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    order: -1;
    margin: 0 0 ${(props) => props.theme.spacings.huge};
  }
`;

const StyledParagraph = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledParagraph>
        {children.filter(
          (child: React.ReactNodeArray | string) => child !== "",
        )}
      </StyledParagraph>
    ),
    [BLOCKS.HEADING_4]: (_NODE, children) => (
      <Text textStyle="headline4" as="span">
        {children}
      </Text>
    ),
  },
};

type SolutionCardProps = {
  content: SolutionCard_ContentfulSolutionCardFragment;
};

const SolutionCard: React.FC<SolutionCardProps> = ({ content }) => (
  <StyledCard>
    {content.badgeText && (
      <Badge outset="small">
        <Text
          as="div"
          dangerouslySetInnerHTML={{
            __html: content.badgeText,
          }}
          isOnDarkBackground
        />
      </Badge>
    )}
    <StyledSpacingsInset scale="huge">
      <SwitchInlineStack
        alignItems="center"
        breakOn="mobileBreakpoint"
        inlineScale="big"
      >
        <StyledSpacingsStack scale="huge">
          {content.richText?.json &&
            documentToReactComponents(content.richText.json, options)}
          {content.button && (
            <div>
              <Button
                content={{ ...content.button }}
                scale="big"
                trackLink={() =>
                  trackCardLink("promotions", content.button?.buttonLink)
                }
              />
            </div>
          )}
        </StyledSpacingsStack>
        {content.image?.fluid && (
          <StyledImgWrapper>
            <Img
              fluid={content.image.fluid as FluidObject}
              alt={content.image.description || content.image.title || ""}
            />
          </StyledImgWrapper>
        )}
      </SwitchInlineStack>
    </StyledSpacingsInset>
  </StyledCard>
);

export const SolutionCardFragment = graphql`
  fragment SolutionCard_ContentfulSolutionCard on ContentfulSolutionCard {
    badgeText
    richText {
      json
    }
    button {
      ...Button_ContentfulButton
    }
    image {
      title
      description
      fluid(maxWidth: 210, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

export default SolutionCard;
