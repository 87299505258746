import Text, {
  fontSizeByTextStyle,
  lineHeightByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { BlockHeroProps } from ".";
import { formatNumber } from "../../../utils";

const StyledSocialProofContainer = styled.div<{ hideOnMobile?: boolean }>`
  ${({ hideOnMobile, theme }) =>
    hideOnMobile &&
    `
    @media (max-width: ${theme.layout.mobileBreakpoint}) {
      display: none;
    }
  `}
`;

const StyledText = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.regular};
  line-height: ${(props) => props.theme.typography.lineHeights.body};
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline4}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline4};
  }
`;

type SocialProofProps = {
  hideOnMobile?: boolean;
  socialProofHtml?: string;
  socialProofRichText?: BlockHeroProps["contentBlock"]["socialProofRichText"];
};

function animateValue(obj: HTMLElement, end: number) {
  // check if SSR
  if (typeof window === "undefined") return;

  let startTimestamp = 0;
  const start = 0;
  const duration = 2500;

  const step = (timestamp: number) => {
    if (startTimestamp === 0) {
      startTimestamp = timestamp;
    }

    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const currentCount = Math.floor(progress * (end - start) + start);

    obj.innerText = formatNumber(currentCount);

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
}

const SocialProof: React.FC<SocialProofProps> = ({
  socialProofHtml,
  socialProofRichText,
  hideOnMobile = true,
}) => {
  const socialCounterRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const counter = socialCounterRef.current;
    const endValue = counter?.getAttribute("data-value");

    if (counter && endValue) {
      animateValue(counter, Number(endValue));
    }
  }, []);

  return (
    <StyledSocialProofContainer hideOnMobile={hideOnMobile}>
      {socialProofHtml ? (
        <StyledText
          isOnDarkBackground
          as="div"
          textStyle="headline4"
          dangerouslySetInnerHTML={{ __html: socialProofHtml }}
        />
      ) : (
        socialProofRichText?.json &&
        documentToReactComponents(socialProofRichText.json, {
          renderMark: {
            [MARKS.BOLD]: (text: any) => {
              const socialCounterNumber = Number(text?.replace(".", ""));

              if (!isNaN(socialCounterNumber)) {
                return (
                  <b ref={socialCounterRef} data-value={socialCounterNumber}>
                    {socialCounterNumber}
                  </b>
                );
              }

              return <b>{text}</b>;
            },
          },
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
              <StyledText textStyle="headline4" as="span" isOnDarkBackground>
                {children}
              </StyledText>
            ),
          },
        })
      )}
    </StyledSocialProofContainer>
  );
};

export default SocialProof;
