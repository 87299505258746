/* eslint-disable react/static-property-placement */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
import React from "react";

export type ChildFunctionProps = {
  isOpen: boolean;
  onToggle: () => void;
};

type BaseProps = {
  children: (props: ChildFunctionProps) => React.ReactNode;
};

type UncontrolledProps = BaseProps & {
  isDefaultOpened?: boolean;
  isOpen?: undefined;
  onToggle?: (isOpen: boolean) => void;
};

type ControlledProps = BaseProps & {
  isDefaultOpened?: undefined;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
};

export type CollapsibleStateContainerProps =
  | UncontrolledProps
  | ControlledProps;

type CollapsibleStateContainerState = { isOpen: boolean };

export default class CollapsibleStateContainer extends React.Component<
  CollapsibleStateContainerProps,
  CollapsibleStateContainerState
> {
  static defaultProps = {
    isDefaultOpened: false,
  };

  state = { isOpen: this.props.isDefaultOpened! };

  componentDidUpdate(prevProps: CollapsibleStateContainerProps) {
    if (this.props.isDefaultOpened === prevProps.isDefaultOpened) {
      return;
    }

    if (typeof this.props.isDefaultOpened === "undefined") {
      this.setState({
        isOpen: CollapsibleStateContainer.defaultProps.isDefaultOpened,
      });
    } else {
      this.setState({ isOpen: this.props.isDefaultOpened });
    }
  }

  handleToggle = () => {
    if (this.props.onToggle) {
      this.props.onToggle(
        this.isControlled() ? !this.props.isOpen : !this.state.isOpen,
      );
    }
    if (!this.isControlled()) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  isControlled = () =>
    Boolean(this.props.onToggle) && this.props.isOpen !== undefined;

  render() {
    return this.props.children({
      isOpen: this.isControlled() ? this.props.isOpen! : this.state.isOpen,
      onToggle: this.handleToggle,
    });
  }
}
