import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { EntryRouterResult } from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearch";
import IndustrySearchDialog, {
  HighlightedIndustry,
} from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearchDialog";
import { UnknownIndustryEntry_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";
import {
  trackQuickIndustrySelection,
  trackStickyBarButton,
} from "../../../../utils/trackingUtils";
import Responsive from "../../../Responsive";
import IndustrySearch from "../IndustrySearch";
import MobileTextIcon from "./MobileTextIcon";

const StyledContainer = styled.div`
  @media (max-width: ${(props) =>
      props.theme.layout.mobileMaxWidthBreakpoint}) {
    width: 100%;
  }
`;

const IndustrySearchContainer = styled.div`
  display: none;
  width: 35em;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const StyledButtonMobile = styled(StyledButton)`
  width: 100%;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props) => props.theme.spacings.small};
  margin: auto 0;
`;

type UnknownIndustryEntryProps = {
  contentBlock: UnknownIndustryEntry_ContentfulBlockHeroFragment;
};

const UnknownIndustryEntry: React.FC<UnknownIndustryEntryProps> = ({
  contentBlock,
}) => {
  const industrySearchDialogState = useDialogState();

  const highlightedIndustries = contentBlock.quickIndustrySelections
    ? (contentBlock.quickIndustrySelections
        .map((quickIndustrySelection) => {
          if (!quickIndustrySelection) {
            return null;
          }

          return {
            id: quickIndustrySelection.industryId,
            name: quickIndustrySelection.title,
          };
        })
        .filter(Boolean) as HighlightedIndustry[])
    : [];

  const onNavigation = (entryRouterResult: EntryRouterResult) => {
    if (entryRouterResult.application === "customer-frontend") {
      location.assign(
        `${process.env.GATSBY_CUSTOMER_FRONTEND_ORIGIN || ""}${
          entryRouterResult.maskedPath || entryRouterResult.path
        }`,
      );
    } else {
      location.assign(
        `${process.env.GATSBY_ORIGIN || ""}/app/#${entryRouterResult.path}`,
      );
    }
  };

  return (
    <StyledContainer>
      <IndustrySearchContainer>
        <IndustrySearch
          elementId="fc24-ac-input-sticky"
          placeholder={
            contentBlock.industryAutocompletePlaceholder || undefined
          }
          selectedProductTypeCombination={
            contentBlock.industrySelectionProductCombinationId || undefined
          }
          buttonText={
            contentBlock.stickyHeaderCTAButtonText ||
            contentBlock.ctaButton?.buttonText ||
            undefined
          }
          buttonTextMobile={
            contentBlock.stickyHeaderCtaButtonTextMobile || undefined
          }
          showHighlightedIndustriesInTheAutocomplete
          highlightedIndustries={highlightedIndustries}
          onHighlightedIndustrySelection={(industry) => {
            trackQuickIndustrySelection({
              industryId: industry.id,
              productTypeCombinationId:
                contentBlock.industrySelectionProductCombinationId,
              title: industry.name,
            });
          }}
        />
      </IndustrySearchContainer>

      <Responsive hideOn={["tablet", "desktop"]}>
        <Spacings.Stack scale="small" alignItems="center">
          <StyledButtonMobile
            type="button"
            buttonType="primary"
            scale="big"
            {...industrySearchDialogState.toggle}
            onClick={() => {
              industrySearchDialogState.toggle.onClick();
              trackStickyBarButton("unknown-industry-entry");
            }}
          >
            {contentBlock.stickyHeaderCtaButtonTextMobile ||
              contentBlock.stickyHeaderCTAButtonText ||
              contentBlock.ctaButton?.buttonText}
            <StyledChevronRightIcon scale="big" isOnDarkBackground />
          </StyledButtonMobile>

          <MobileTextIcon
            stickyHeaderIconMobile={contentBlock.stickyHeaderIconMobile}
            stickyHeaderTextMobile={contentBlock.stickyHeaderTextMobile}
          />
        </Spacings.Stack>
      </Responsive>

      <Responsive hideOn={["mobile", "desktop"]}>
        <StyledButton
          type="button"
          buttonType="primary"
          scale="medium"
          {...industrySearchDialogState.toggle}
          onClick={() => {
            industrySearchDialogState.toggle.onClick();
            trackStickyBarButton("unknown-industry-entry");
          }}
        >
          {contentBlock.stickyHeaderCTAButtonText ||
            contentBlock.ctaButton?.buttonText}
        </StyledButton>
      </Responsive>

      <IndustrySearchDialog
        allowSubmitWithoutSelection
        backendUrl={`${process.env.GATSBY_ORIGIN}/backend`}
        dialogState={industrySearchDialogState}
        highlightedIndustries={highlightedIndustries}
        onHighlightedIndustrySelection={(industry) => {
          trackQuickIndustrySelection({
            industryId: industry.id,
            productTypeCombinationId:
              contentBlock.industrySelectionProductCombinationId,
            title: industry.name,
          });
        }}
        messages={{
          dialogHeadline: "Ihre Tätigkeit:",
          errorMessageEmptyInput: "Bitte geben Sie Ihre Tätigkeit ein.",
          errorMessageEntryRouter:
            "Es gab ein Problem bei der Auswahl Ihrer Tätigkeit. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
          errorMessageQuery:
            "Es gab ein Problem beim Laden der Tätigkeiten. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
          errorTitle: "Es gibt ein Problem.",
          highlightedIndustriesHeadline: "Meist gewählte Tätigkeiten:",
          notFound: (inputValue) =>
            `Tätigkeit **${inputValue}** nicht gefunden? **Kontaktieren Sie uns!**`,
        }}
        onNavigation={onNavigation}
        placeholder={
          contentBlock.industryAutocompletePlaceholder ||
          "Bitte Tätigkeit eingeben..."
        }
        selectedProductTypeCombination={
          contentBlock.industrySelectionProductCombinationId || undefined
        }
        tenant="fc24"
      />
    </StyledContainer>
  );
};

export const unknownIndustryEntryFragments = graphql`
  fragment UnknownIndustryEntry_ContentfulBlockHero on ContentfulBlockHero {
    industryId
    ctaButton {
      buttonText
    }
    industryAutocompletePlaceholder
    industrySelectionProductCombinationId
    stickyHeaderCTAButtonText
    stickyHeaderCtaButtonTextMobile
    stickyHeaderTextMobile
    stickyHeaderIconMobile
    quickIndustrySelections {
      id
      title
      industryId
    }
  }
`;

export default UnknownIndustryEntry;
