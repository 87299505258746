import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";

import Collapsible from "@finanzchef24gmbh/design-system/src/Collapsible";
import RatingPositiveIcon from "@finanzchef24gmbh/design-system/src/Icons/RatingPositiveIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { SavingsBadge_ContentfulSavingsBadgeFragment } from "../../../../../types/graphql-types";
import SwitchInlineStack from "../../../SwitchInlineStack";
import PriceCard from "./PriceCard";
import { priceOrPercentage } from "./utils";

const StyledPriceBarContainer = styled.div`
  background-color: ${(props) => props.theme.palette.gray[100]};
  padding: 0 ${(props) => props.theme.spacings.huge};

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding: 0 ${(props) => props.theme.spacings.gigantic};
  }
`;

const StyledPriceBar = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.palette.mint[600]};
  height: 0.5rem;

  :before,
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: ${(props) => props.theme.palette.black};
    width: 1px;
  }

  :before {
    left: 0;
    height: 2.5rem;
  }

  :after {
    right: 0;
    height: 8.5rem;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    :after {
      height: 2.5rem;
    }
  }
`;

const StyledSavingsPercentage = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
  color: ${(props) => props.theme.palette.mint[600]};

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    margin-top: 0.4rem;
    font-size: 1.5rem;
  }
`;

const StyledParagraph = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};

    :first-of-type {
      color: ${(props) => props.theme.palette.mint[600]};
    }

    :last-of-type {
      color: ${(props) => props.theme.palette.error[500]};
    }
  }
`;

const overlayDescriptionRichTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledParagraph>{children}</StyledParagraph>
    ),
  },
};

const StyledPriceSwitchInlineStack = styled(SwitchInlineStack)`
  z-index: 1;

  > * + * {
    align-self: flex-end;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    justify-content: space-between;
  }
`;

const StyledCollapsibleWrapper = styled.div`
  border-top: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.light};
  border-bottom: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.light};
  padding: ${(props) => props.theme.spacings.big} 0;
  width: 100%;
`;

const StyledCollapsibleSwitchInlineStack = styled(SwitchInlineStack)`
  margin-top: ${(props) => props.theme.spacings.huge};
`;

const StyledLiText = styled(Text)`
  b {
    color: ${(props) => props.theme.palette.black};
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
    margin-left: ${(props) => props.theme.spacings.small};
  }
`;

const overlayCollapsibleRichTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) =>
      children.filter((child: React.ReactNodeArray | string) => child !== ""),
    [BLOCKS.HEADING_6]: (_NODE, children) => (
      <Text textStyle="headline6" as="span">
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (_NODE, children) => (
      <Spacings.Stack scale="medium" as="ul">
        {children}
      </Spacings.Stack>
    ),
    [BLOCKS.LIST_ITEM]: (_NODE, children: any) =>
      children?.map((child: React.ReactNodeArray, index: number) => (
        <Spacings.Inline
          key={`savings-badge-${index}`}
          scale="small"
          as="li"
          alignItems="center"
        >
          <RatingPositiveIcon scale="tiny" color="auxiliary" />
          <StyledLiText textStyle="caption" priority="secondary">
            {child}
          </StyledLiText>
        </Spacings.Inline>
      )),
  },
};

type OverlayProps = {
  content: SavingsBadge_ContentfulSavingsBadgeFragment;
};

const Overlay: React.FC<OverlayProps> = ({ content }) => (
  <>
    {content.overlayDescriptionRichText?.json &&
      documentToReactComponents(
        content.overlayDescriptionRichText.json,
        overlayDescriptionRichTextOptions,
      )}

    <StyledPriceSwitchInlineStack
      breakOn="tabletBreakpoint"
      inlineScale="gigantic"
      stackScale="medium"
      alignItems="flex-start"
      switchColumnOrder
    >
      <PriceCard
        color="mint"
        logo={content.overlayFirstCardLogo}
        price={content.overlayFirstCardFavourablePrice}
      />
      <PriceCard
        color="error"
        logo={content.overlaySecondCardLogo}
        price={content.overlaySecondCardExpensivePrice}
      />
    </StyledPriceSwitchInlineStack>

    <Spacings.Stack scale="medium">
      <StyledPriceBarContainer>
        <StyledPriceBar />
      </StyledPriceBarContainer>

      <Spacings.Stack scale="small" alignItems="center">
        <StyledSavingsPercentage textStyle="headline3" as="span">
          {priceOrPercentage({
            expensivePrice: content.overlaySecondCardExpensivePrice,
            favourablePrice: content.overlayFirstCardFavourablePrice,
            priceToShow: content.overlayPriceDifference,
          })}
        </StyledSavingsPercentage>
        <Text>Sparpotenzial</Text>
      </Spacings.Stack>
    </Spacings.Stack>

    <Spacings.Stack scale="medium" alignItems="flex-end">
      <StyledCollapsibleWrapper>
        <Collapsible>
          <Collapsible.Header>
            <Collapsible.Headline>
              <Text textStyle="headline6" as="span">
                Berechnungsgrundlage
              </Text>
            </Collapsible.Headline>
            <Collapsible.Chevron />
          </Collapsible.Header>
          <Collapsible.Content>
            <StyledCollapsibleSwitchInlineStack
              breakOn="tabletBreakpoint"
              inlineScale="huge"
              stackScale="huge"
            >
              {content.overlayCollapsibleFirstColumnRichText?.json && (
                <Spacings.Stack scale="big">
                  {documentToReactComponents(
                    content.overlayCollapsibleFirstColumnRichText.json,
                    overlayCollapsibleRichTextOptions,
                  )}
                </Spacings.Stack>
              )}

              {content.overlayCollapsibleSecondColumnRichText?.json && (
                <Spacings.Stack scale="big">
                  {documentToReactComponents(
                    content.overlayCollapsibleSecondColumnRichText.json,
                    overlayCollapsibleRichTextOptions,
                  )}
                </Spacings.Stack>
              )}
            </StyledCollapsibleSwitchInlineStack>
          </Collapsible.Content>
        </Collapsible>
      </StyledCollapsibleWrapper>
      <Text textStyle="caption" priority="secondary">
        {content.date}
      </Text>
    </Spacings.Stack>
  </>
);

export default Overlay;
