import React from "react";
import styled from "styled-components";

import {
  Button as DesignSystemButton,
  ButtonProps as DesignSystemButtonProps,
} from "@finanzchef24gmbh/design-system/src/Button";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import DownloadIcon from "@finanzchef24gmbh/design-system/src/Icons/DownloadIcon";
import PhoneCallbackIcon from "@finanzchef24gmbh/design-system/src/Icons/PhoneCallbackIcon";
import { Scale as IconScale } from "@finanzchef24gmbh/design-system/src/Icons/useMappedProps";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { ButtonProps, CustomTextStyles } from ".";

const StyledButton = styled(DesignSystemButton)`
  width: inherit;
  height: 100%;
`;

const ButtonContentWrapper = styled(Spacings.Inline)`
  justify-content: center;
`;

const StyledButtonText = styled.div<{ customTextStyles?: CustomTextStyles }>`
  ${(props) => (props.customTextStyles ? props.customTextStyles : "")};
`;

type ButtonContentProps = ButtonProps & {
  onClick?: () => void;
};

const ButtonContent: React.FC<ButtonContentProps> = ({
  className,
  content,
  customTextStyles,
  onClick,
  scale,
}) => {
  const iconComponents: {
    [iconName: string]: React.ComponentType<{
      isOnDarkBackground?: boolean;
      scale?: IconScale;
    }>;
  } = { ChevronRightIcon, DownloadIcon, PhoneCallbackIcon };

  const ButtonIcon = content.buttonIcon
    ? iconComponents[content.buttonIcon]
    : null;

  return (
    <StyledButton
      className={className}
      tabIndex={-1}
      buttonType={content.buttonType as DesignSystemButtonProps["buttonType"]}
      scale={scale || "medium"}
      onClick={onClick}
    >
      <ButtonContentWrapper alignItems="center">
        {ButtonIcon && content.buttonIconPosition === "left" ? (
          <ButtonIcon
            isOnDarkBackground={content.buttonType === "primary"}
            scale={scale === "big" ? "big" : "small"}
          />
        ) : null}

        <StyledButtonText
          customTextStyles={customTextStyles}
          dangerouslySetInnerHTML={{
            __html: content.buttonText || "",
          }}
        />

        {ButtonIcon && content.buttonIconPosition === "right" ? (
          <ButtonIcon
            isOnDarkBackground={content.buttonType === "primary"}
            scale={scale === "big" ? "big" : "small"}
          />
        ) : null}
      </ButtonContentWrapper>
    </StyledButton>
  );
};

export default ButtonContent;
