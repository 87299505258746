import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import DesignSystemCollapsible from "@finanzchef24gmbh/design-system/src/Collapsible";
import CollapsibleStateContainer from "@finanzchef24gmbh/design-system/src/CollapsibleStateContainer";
import PlusIcon from "@finanzchef24gmbh/design-system/src/Icons/PlusIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { Collapsible_ContentfulCollapsibleFragment } from "../../types/graphql-types";
import { trackCollapsibleButton } from "../utils/trackingUtils";
import RichTextButton from "./RichTextButton";

const StyledCollapsibleWrapper = styled.div`
  box-shadow: 0px 2px 15px
    ${(props) => transparentize(0.85, props.theme.palette.black)};
  border-radius: ${(props) => props.theme.borders.radius.small}px;

  > div {
    box-sizing: border-box;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    :hover {
      background-color: ${(props) => props.theme.palette.brand[100]};
    }
  }
`;

const StyledPlusIcon = styled(PlusIcon)<{ isCollapsibleOpen: boolean }>`
  color: ${(props) => props.theme.palette.brand[500]};

  ${(props) =>
    props.isCollapsibleOpen &&
    css`
      transform: rotate(-45deg);
    `};
`;

const StyledPlusIconWrapper = styled.span`
  display: flex;
`;

const StyledDesignSystemCollapsibleHeader = styled(
  DesignSystemCollapsible.Header,
)`
  :hover {
    box-shadow: none;
  }

  :focus {
    box-shadow: none;

    ${StyledPlusIconWrapper} {
      box-shadow: 0 0 0 3px
        ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
    }
  }
`;

const StyledRichTextStack = styled(Spacings.Stack)`
  padding: ${(props) => props.theme.spacings.small}
    ${(props) => props.theme.spacings.big}
    ${(props) => props.theme.spacings.big};
`;

const StyledText = styled(Text)`
  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => {
      const filteredChildren = children.filter(
        (child: React.ReactNodeArray | string) => child !== "",
      );

      return filteredChildren.length ? (
        <StyledText textStyle="caption" priority="secondary">
          {filteredChildren.map((child: React.ReactNodeArray | string) =>
            typeof child === "string" ? (
              <span dangerouslySetInnerHTML={{ __html: child }} />
            ) : (
              child
            ),
          )}
        </StyledText>
      ) : null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => (
      <div>
        <RichTextButton
          nodeFields={node.data.target.fields}
          trackingUtil={trackCollapsibleButton}
          scale="small"
        />
      </div>
    ),
  },
};

StyledDesignSystemCollapsibleHeader.displayName =
  DesignSystemCollapsible.Header.displayName;
StyledPlusIconWrapper.displayName = DesignSystemCollapsible.Chevron.displayName;

type CollapsibleProps = {
  content: Collapsible_ContentfulCollapsibleFragment;
};

const Collapsible: React.FC<CollapsibleProps> = ({ content }) => (
  <CollapsibleStateContainer>
    {({ isOpen, onToggle }) => (
      <StyledCollapsibleWrapper>
        <DesignSystemCollapsible isOpen={isOpen} onToggle={onToggle}>
          <StyledDesignSystemCollapsibleHeader insetSquishScale="big">
            <DesignSystemCollapsible.Headline>
              <Text textStyle="headline5" as="span">
                {content.label}
              </Text>
            </DesignSystemCollapsible.Headline>

            <StyledPlusIconWrapper>
              <StyledPlusIcon isCollapsibleOpen={isOpen} />
            </StyledPlusIconWrapper>
          </StyledDesignSystemCollapsibleHeader>

          <DesignSystemCollapsible.Content>
            {content.richTextContent?.json ? (
              <StyledRichTextStack scale="big">
                {documentToReactComponents(
                  content.richTextContent.json,
                  options,
                )}
              </StyledRichTextStack>
            ) : null}
          </DesignSystemCollapsible.Content>
        </DesignSystemCollapsible>
      </StyledCollapsibleWrapper>
    )}
  </CollapsibleStateContainer>
);

export const CollapsibleFragment = graphql`
  fragment Collapsible_ContentfulCollapsible on ContentfulCollapsible {
    __typename
    id
    label
    richTextContent {
      json
    }
  }
`;

export default Collapsible;
