import { graphql } from "gatsby";
import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockUsp_ContentfulBlockUspFragment } from "../../../../types/graphql-types";
import Button from "../../Button";
import Center from "../../Center";
import Markdown from "../../Markdown";
import Image from "./Image";

const StyledBlock = styled.div<{
  dontUseGrayBackground?: boolean;
  hasBackgroundImage: boolean;
}>`
  ${(props) =>
    !props.dontUseGrayBackground && !props.hasBackgroundImage
      ? css`
          background-color: ${props.theme.palette.gray[25]};
          border-top: ${props.theme.borders.width.small}px solid
            ${props.theme.palette.gray[100]};
          border-bottom: ${props.theme.borders.width.small}px solid
            ${props.theme.palette.gray[100]};
        `
      : null};

  ${(props) =>
    !props.dontUseGrayBackground || props.hasBackgroundImage
      ? css`
          padding: ${props.theme.spacings.gigantic} 0;
        `
      : null};

  ${(props) =>
    props.hasBackgroundImage &&
    css`
      background: radial-gradient(
        200% 125% at 35% 100%,
        ${props.theme.palette.brand[500]} 0%,
        ${transparentize(0.003445, props.theme.palette.brand[500])} 51.87%,
        ${transparentize(0.5, props.theme.palette.brand[500])} 58.98%,
        ${transparentize(0.97, props.theme.palette.brand[500])} 64.1%
      );

      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        background: radial-gradient(
          71.95% 227.88% at 8.83% 0%,
          ${props.theme.palette.brand[500]} 0.5%,
          ${transparentize(0.5, props.theme.palette.brand[500])} 63.02%,
          ${transparentize(1, props.theme.palette.brand[500])} 87.52%
        );
      }
    `};
`;

const StyledBackgroundImg = styled(Img)<GatsbyImageFluidProps>`
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  z-index: -1;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    height: 100%;
  }
`;

const StyledCenter = styled(Center)<{ hasBackgroundImage: boolean }>`
  ${(props) =>
    props.hasBackgroundImage &&
    css`
      max-width: ${props.theme.layout.articleWidth};
    `};
`;

const StyledSpacingsInset = styled(Spacings.Inset)<{
  hasBackgroundImage: boolean;
}>`
  ${(props) =>
    props.hasBackgroundImage &&
    css`
      padding: 0;
    `};
`;

type TextPosition = "left" | "right";

const StyledInline = styled.div<{
  hasBackgroundImage: boolean;
  textPosition: TextPosition;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.textPosition === "right"
      ? css`
          > * + * {
            margin: ${props.theme.spacings.gigantic} 0 0;
          }

          > :first-child {
            flex: 1;
          }

          > :last-child {
            flex: ${props.hasBackgroundImage ? 3 : 2};
          }
        `
      : css`
          > * + * {
            margin: 0 0 ${props.theme.spacings.gigantic};
          }

          > :first-child {
            flex: ${props.hasBackgroundImage ? 3 : 2};
          }

          > :last-child {
            flex: 1;
            order: -1;
          }
        `};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;

    > * + * {
      margin: 0 0 0 ${(props) => props.theme.spacings.gigantic};
    }

    > :last-child {
      order: 1;
    }
  }
`;

const StyledCtaButton = styled(Button)`
  align-self: flex-start;
`;

type BlockUspProps = {
  contentBlock: BlockUsp_ContentfulBlockUspFragment;
};

const BlockUsp: React.FC<BlockUspProps> = (props) => {
  const textPosition =
    (props.contentBlock.textPosition as TextPosition) || "right";

  return (
    <StyledBlock
      dontUseGrayBackground={props.contentBlock.dontUseGrayBackground || false}
      hasBackgroundImage={Boolean(props.contentBlock.backgroundImage)}
    >
      {props.contentBlock.backgroundImage?.fluid ? (
        <StyledBackgroundImg
          fluid={props.contentBlock.backgroundImage.fluid as FluidObject}
          alt={
            props.contentBlock.backgroundImage.description ||
            props.contentBlock.backgroundImage.title ||
            ""
          }
          style={{ position: "absolute" }}
          imgStyle={{ objectPosition: "80% center" }}
        />
      ) : null}

      <StyledCenter
        hasBackgroundImage={Boolean(props.contentBlock.backgroundImage)}
      >
        <StyledSpacingsInset
          scale="medium"
          hasBackgroundImage={Boolean(props.contentBlock.backgroundImage)}
        >
          <StyledInline
            textPosition={textPosition}
            hasBackgroundImage={Boolean(props.contentBlock.backgroundImage)}
          >
            {textPosition === "right" ? (
              <Image image={props.contentBlock.image} />
            ) : null}

            <Spacings.Stack scale="huge">
              {props.contentBlock.title ? (
                <Text
                  textStyle="headline4"
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: props.contentBlock.title }}
                  isOnDarkBackground={Boolean(
                    props.contentBlock.backgroundImage,
                  )}
                />
              ) : null}

              {props.contentBlock.description?.childMarkdownRemark?.html ? (
                <Markdown
                  textStyle="bodyProlonged"
                  priority={
                    Boolean(props.contentBlock.backgroundImage)
                      ? "primary"
                      : "secondary"
                  }
                  dangerouslySetInnerHTML={{
                    __html:
                      props.contentBlock.description.childMarkdownRemark.html,
                  }}
                  isOnDarkBackground={Boolean(
                    props.contentBlock.backgroundImage,
                  )}
                />
              ) : null}

              {props.contentBlock.ctaButton?.buttonLink ? (
                <StyledCtaButton
                  content={{ ...props.contentBlock.ctaButton }}
                  scale="big"
                />
              ) : null}
            </Spacings.Stack>

            {textPosition === "left" ? (
              <Image image={props.contentBlock.image} />
            ) : null}
          </StyledInline>
        </StyledSpacingsInset>
      </StyledCenter>
    </StyledBlock>
  );
};

export const blockUspFragment = graphql`
  fragment BlockUsp_ContentfulBlockUsp on ContentfulBlockUsp {
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    ctaButton {
      ...Button_ContentfulButton
    }
    textPosition
    image {
      title
      description
      file {
        url
      }
    }
    dontUseGrayBackground
    backgroundImage {
      title
      description
      fluid(maxWidth: 1600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

export default BlockUsp;
