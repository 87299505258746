import { ContentfulQuickIndustrySelection } from "../../types/graphql-types";

type KeyValuePair = { key: string; value?: string | number | null };

const gA4CustomEvents = ["quick_industry_selection"];

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const constructGA4EventParameters = (eventParameters: KeyValuePair[]) =>
  Object.fromEntries(
    eventParameters
      .filter(({ value }) => !(value == null))
      .map(({ key, value }) => [camelToSnakeCase(key), value]),
  );

const constructLabel = (labelValues: KeyValuePair[]): string =>
  labelValues
    .filter(({ value }) => !(value == null))
    .map(({ key, value }) => `${key}:${value}`)
    .join("~");

const trackHomepageEvent = (
  eventAction: string,
  labelValues: KeyValuePair[],
) => {
  const { gtag } = window as any;
  if (typeof gtag !== "function") {
    return;
  }

  gtag("event", eventAction, {
    event_category: "homepage",
    event_label: constructLabel(labelValues),
  });

  // Standard event names and parameters in GA4 are using snake_case.
  // We adapt those to conform to that standard. For universal analytics,
  // we keep the old way, to not break any definitions of conversion goals or
  // our pipeline. Once we finally switch, we should use snake-case from the beginning.

  const ga4EventAction = eventAction.replaceAll("-", "_");

  if (gA4CustomEvents.includes(ga4EventAction)) {
    gtag("event", ga4EventAction, {
      ...constructGA4EventParameters(labelValues),
      category: "homepage",
      send_to: "ga4",
    });
  }
};

export const trackQuickIndustrySelection = (
  quickIndustrySelection: Pick<
    ContentfulQuickIndustrySelection,
    "title" | "industryId" | "productTypeCombinationId"
  >,
) => {
  const labelValues = [
    {
      key: "eventId",
      value:
        Math.random().toString(36).substr(2, 10) +
        Math.random().toString(36).substr(2, 10),
    },
    { key: "timestamp", value: Date.now() },
    {
      key: "industryLabel",
      value: quickIndustrySelection.title,
    },

    {
      key: "industryId",
      value: quickIndustrySelection.industryId,
    },

    {
      key: "productCombinationId",
      value: quickIndustrySelection.productTypeCombinationId,
    },
  ];
  trackHomepageEvent("quick-industry-selection", labelValues);
};

export const trackSecondaryHeroLink = (industryId?: string) => {
  trackHomepageEvent("hero-secondary-link", [
    { key: "industryId", value: industryId },
  ]);
};

export const trackVideoPlayer = (src?: string) => {
  trackHomepageEvent("video-player", [{ key: "video", value: src }]);
};

export const trackStickyBarButton = (type: string) => {
  trackHomepageEvent("sticky-bar-button", [{ key: "button", value: type }]);
};

export const trackCardLink = (
  type: "promotions" | "categories" | "articles" | "blogs",
  link: string | null | undefined,
) => {
  trackHomepageEvent("card-link", [
    { key: "card", value: type },
    { key: "link", value: link },
  ]);
};

export const trackMediaItemButton = (link: string | null | undefined) => {
  trackHomepageEvent("cta-button", [{ key: "media-item-link", value: link }]);
};

export const trackBlockParagraphButton = (link: string | null | undefined) => {
  trackHomepageEvent("cta-button", [
    { key: "block-paragraph-link", value: link },
  ]);
};

export const trackSuccessfulNewsletterRegistration = (
  formId: string | null | undefined,
) => {
  trackHomepageEvent("cta-button", [
    { key: "newsletter-registration", value: formId },
  ]);
};

export const trackMainNavigationLink = (link: string | null | undefined) => {
  trackHomepageEvent("main-navigation", [{ key: "nav-link", value: link }]);
};

export const trackProcessItemButton = (link: string | null | undefined) => {
  trackHomepageEvent("cta-button", [{ key: "process-item-link", value: link }]);
};

export const trackBlockBottomLink = (
  type: "promotions",
  link: string | null | undefined,
) => {
  trackHomepageEvent("block-bottom-link", [
    { key: "block", value: type },
    { key: "link", value: link },
  ]);
};

export const trackBlockNotificationBar = (
  link: "close" | string | null | undefined,
) => {
  trackHomepageEvent("link", [
    { key: "block-notification-bar-link", value: link },
  ]);
};

export const trackTopNewsBarLink = (link: string | null | undefined) => {
  trackHomepageEvent("link", [{ key: "top-news-bar-link", value: link }]);
};

export const trackTopOffersLink = (link: string | null | undefined) => {
  trackHomepageEvent("link", [{ key: "top-offers-link", value: link }]);
};

export const trackStickyTableOfContentsButton = (
  link: string | null | undefined,
) => {
  trackHomepageEvent("button", [
    { key: "sticky-table-of-contents-link", value: link },
  ]);
};

export const trackCollapsibleButton = (link: string | null | undefined) => {
  trackHomepageEvent("button", [
    { key: "collapsible-button-link", value: link },
  ]);
};
