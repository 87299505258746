/* eslint-disable sort-keys */
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";

type ContentBlock = {
  heading: string;
  richTextOffers: {
    json: Document;
  };
};

const contentBlock: ContentBlock = {
  heading: "Top-Angebote",
  richTextOffers: {
    json: {
      nodeType: BLOCKS.DOCUMENT,
      data: {},
      content: [
        {
          nodeType: BLOCKS.PARAGRAPH,
          content: [
            {
              nodeType: "text",
              value: "Geschäftskonto ab 0 € ",
              marks: [],
              data: {},
            },
            {
              nodeType: INLINES.HYPERLINK,
              content: [
                {
                  nodeType: "text",
                  value: "→ Jetzt Anbieter vergleichen",
                  marks: [],
                  data: {},
                },
              ],
              data: {
                uri: "/geschaeftskonten",
              },
            },
            {
              nodeType: "text",
              value: "",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: BLOCKS.PARAGRAPH,
          content: [
            {
              nodeType: "text",
              value: "Private Krankenversicherung ",
              marks: [],
              data: {},
            },
            {
              nodeType: INLINES.HYPERLINK,
              content: [
                {
                  nodeType: "text",
                  value: "→ Jetzt unverbindlich beraten lassen",
                  marks: [],
                  data: {},
                },
              ],
              data: {
                uri: "/wissen/versicherungsratgeber/private-krankenversicherung",
              },
            },
            {
              nodeType: "text",
              value: "",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: BLOCKS.PARAGRAPH,
          content: [
            {
              nodeType: "text",
              value: "Digitale Buchhaltung mit sevDesk ",
              marks: [],
              data: {},
            },
            {
              nodeType: INLINES.HYPERLINK,
              content: [
                {
                  nodeType: "text",
                  value: "→ Jetzt kostenlos testen",
                  marks: [],
                  data: {},
                },
              ],
              data: {
                uri: "https://www.awin1.com/awclick.php?gid=361518&mid=15944&awinaffid=377591&linkid=2458873&clickref=",
              },
            },
            {
              nodeType: "text",
              value: "",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
};

export default contentBlock;
