import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import CollapsibleStateContainer from "@finanzchef24gmbh/design-system/src/CollapsibleStateContainer";
import ChevronBottomIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronBottomIcon";
import ChevronTopIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronTopIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { SpacingScales } from "@finanzchef24gmbh/design-system/src/Spacings/helpers";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { FaqQuestion_ContentfulFaqQuestionFragment } from "../../../../types/graphql-types";
import { addExternalLinkAttributes } from "../../../utils/index";
import Markdown from "../../Markdown";

type ContainerProps = {
  scale: SpacingScales;
};

const Container = styled(Spacings.Stack)<ContainerProps>`
  border-top: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.light};
  border-bottom: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.light};
  padding-top: ${(props) => props.theme.spacings[props.scale]};
  padding-bottom: ${(props) => props.theme.spacings[props.scale]};

  & + & {
    border-top: 0;
  }
`;

const ToggleIconContainer = styled.span`
  margin-left: ${(props) => props.theme.spacings.small};
  height: 1em;
  display: inline-flex;
`;

const HeadlineButton = styled.button`
  all: inherit;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:focus {
    ${ToggleIconContainer} {
      outline: 3px solid ${(props) => props.theme.palette.brand.light};
    }
  }
`;

const Section = styled.section`
  &[hidden] {
    display: none;
  }
`;

const Question: React.FC<FaqQuestion_ContentfulFaqQuestionFragment> = ({
  question,
  answer,
  overrideDefaultExternalLinkAttributes,
  showAnswerByDefault,
}) => (
  <CollapsibleStateContainer isDefaultOpened={Boolean(showAnswerByDefault)}>
    {({ isOpen, onToggle }) => (
      <Container scale="big" as="li">
        <Text textStyle="headline5" as="h3" isUncropped>
          <HeadlineButton onClick={onToggle} aria-expanded={isOpen}>
            <span dangerouslySetInnerHTML={{ __html: question || "" }} />

            <ToggleIconContainer aria-hidden="true">
              {isOpen ? (
                <ChevronTopIcon color="brand" />
              ) : (
                <ChevronBottomIcon color="brand" />
              )}
            </ToggleIconContainer>
          </HeadlineButton>
        </Text>

        <Section hidden={!isOpen}>
          <Spacings.Inset scale="medium">
            {answer?.childMarkdownRemark?.html ? (
              <Markdown
                priority="secondary"
                textStyle="bodyProlonged"
                dangerouslySetInnerHTML={{
                  __html: overrideDefaultExternalLinkAttributes
                    ? answer.childMarkdownRemark.html
                    : addExternalLinkAttributes(
                        answer.childMarkdownRemark.html,
                      ),
                }}
              />
            ) : null}
          </Spacings.Inset>
        </Section>
      </Container>
    )}
  </CollapsibleStateContainer>
);

export const FaqQuestionFragment = graphql`
  fragment FaqQuestion_ContentfulFaqQuestion on ContentfulFaqQuestion {
    question
    answer {
      childMarkdownRemark {
        html
      }
    }
    overrideDefaultExternalLinkAttributes
    showAnswerByDefault
  }
`;

export default Question;
