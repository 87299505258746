import { graphql } from "gatsby";
import Img, { FluidObject, GatsbyImageProps } from "gatsby-image";
import React from "react";

import Card from "@finanzchef24gmbh/design-system/src/Card";
import styled, { css } from "styled-components";
import { BlockNewsletterRegistration_ContentfulBlockNewsletterRegistrationFragment } from "../../../../types/graphql-types";
import Center from "../../Center";
import NewsletterContent from "./NewsletterContent";

const StyledNewsletterRegistrationBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: ${(props) => props.theme.spacings.big};
  }
`;

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.spacings.medium};
`;

export type StyledNewsletterRegistrationWrapperProps = {
  hasBackground?: boolean;
  isCardView?: boolean;
};

const StyledNewsletterRegistrationWrapper = styled.div<StyledNewsletterRegistrationWrapperProps>`
  ${(props) =>
    props.hasBackground &&
    css`
      padding-top: ${props.theme.spacings.big};
      padding-bottom: ${props.theme.spacings.big};
      background: linear-gradient(
        ${props.theme.palette.brand[400]},
        ${props.theme.palette.brand[500]},
        ${props.theme.palette.brand[600]}
      );
      z-index: 0;

      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        padding-left: ${props.theme.spacings.big};
        padding-right: ${props.theme.spacings.big};
      }
    `};
`;

type BlockNewsletterRegistrationProps = {
  contentBlock: BlockNewsletterRegistration_ContentfulBlockNewsletterRegistrationFragment;
};

const BackgroundImg: React.ComponentType<GatsbyImageProps> = styled(Img)`
  width: 100%;
  height: 100%;
  opacity: 0.2;
  filter: grayscale(100%);
  position: absolute !important; /*gatsby-image-wrapper inlines relative position*/
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const BlockNewsletterRegistration: React.FC<
  BlockNewsletterRegistrationProps
> = (props) => (
  <StyledNewsletterRegistrationWrapper
    hasBackground={Boolean(props.contentBlock.hasBackground)}
    isCardView={Boolean(props.contentBlock.isCardView)}
  >
    {props.contentBlock.backgroundImage?.fluid && (
      <BackgroundImg
        fluid={props.contentBlock.backgroundImage.fluid as FluidObject}
      />
    )}
    <StyledNewsletterRegistrationBlock>
      {props.contentBlock.isCardView ? (
        <StyledCard>
          <NewsletterContent content={props.contentBlock} />
        </StyledCard>
      ) : (
        <NewsletterContent content={props.contentBlock} />
      )}
    </StyledNewsletterRegistrationBlock>
  </StyledNewsletterRegistrationWrapper>
);

export const NewsletterRegistrationFragment = graphql`
  fragment BlockNewsletterRegistration_ContentfulBlockNewsletterRegistration on ContentfulBlockNewsletterRegistration {
    id
    form {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      formId
      contentful_id
    }
    type: __typename
    icon {
      file {
        url
      }
    }
    hasBackground
    backgroundImage {
      fluid(quality: 85) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    isCardView
    headline
  }
`;

export default BlockNewsletterRegistration;
