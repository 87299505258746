import React from "react";
import styled, { css } from "styled-components";

import Badge from "@finanzchef24gmbh/design-system/src/Badge";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { SpacingScales } from "@finanzchef24gmbh/design-system/src/Spacings/helpers";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockHero_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";
import constants from "../constants";
import AddonBox from "./AddonBox";

const StyledCard = styled(Card)`
  position: relative;
  z-index: 1;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-width: ${constants.BLOCK_HERO.ctaCard.width};
  }
`;

const StyledInset = styled(Spacings.Inset)<{ paddingTop?: SpacingScales }>`
  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props.theme.spacings[props.paddingTop]};
    `}
`;

type CardWithBadgeProps = {
  badge?: string | null;
  image?: BlockHero_ContentfulBlockHeroFragment["addonBoxImage"];
  richTextSingleLine?: BlockHero_ContentfulBlockHeroFragment["addonBoxSingleLineRichText"];
  children: React.ReactNode;
};

const CardWithBadge: React.FC<CardWithBadgeProps> = (props) => (
  <StyledCard>
    {props.badge && (
      <Badge>
        <Text isOnDarkBackground>{props.badge}</Text>
      </Badge>
    )}
    <StyledInset scale="big" paddingTop={props.badge ? "small" : undefined}>
      {props.children}
    </StyledInset>

    {props.richTextSingleLine?.json && (
      <AddonBox
        image={props.image}
        richTextSingleLine={props.richTextSingleLine}
      />
    )}
  </StyledCard>
);

export default CardWithBadge;
