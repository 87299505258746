import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, { TextStyleProps } from "@finanzchef24gmbh/design-system/src/Text";
import { Scalars } from "../../types/graphql-types";
import RichTextButton from "./RichTextButton";
import RichTextList from "./RichTextList";

const StyledUl = styled.ul`
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
  padding: 0 0 0 ${(props) => props.theme.spacings.huge};

  p {
    /* Needed to align disc with first line on Safari */
    display: inline-table;
  }
`;

export type RichTextProps = {
  document: Scalars["JSON"];
  isOnDarkBackground?: TextStyleProps["isOnDarkBackground"];
  isStrong?: TextStyleProps["isStrong"];
  isUncropped?: TextStyleProps["isUncropped"];
  priority?: TextStyleProps["priority"];
  textStyle?: TextStyleProps["textStyle"];
  trackingUtil?: (_: string | null | undefined) => void;
};

const RichText: React.FC<RichTextProps> = ({
  document,
  isOnDarkBackground,
  isStrong,
  isUncropped,
  priority,
  textStyle,
  trackingUtil,
}) => (
  <>
    {documentToReactComponents(document, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_NODE, children: any) =>
          children.map((child: React.ReactNodeArray) => {
            // Don't render empty paragraphs
            if (child[1] !== "") {
              return (
                <Text
                  {...(priority && { priority })}
                  {...(textStyle && { textStyle })}
                  {...(isOnDarkBackground && { isOnDarkBackground })}
                  {...(isStrong && { isStrong })}
                  {...(isUncropped && { isUncropped })}
                >
                  {child}
                </Text>
              );
            }
          }),
        [BLOCKS.HEADING_4]: (_NODE, children) => (
          <Text
            {...(priority && { priority })}
            {...(isOnDarkBackground && { isOnDarkBackground })}
            {...(isStrong && { isStrong })}
            {...(isUncropped && { isUncropped })}
            textStyle={textStyle || "headline4"}
          >
            {children}
          </Text>
        ),
        [BLOCKS.UL_LIST]: (_NODE, children) => (
          <StyledUl>
            <Spacings.Stack scale="medium">{children}</Spacings.Stack>
          </StyledUl>
        ),
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const component = node.data.target.sys.contentType.sys.contentful_id;

          switch (component) {
            case "list": {
              return <RichTextList nodeFields={node.data.target.fields} />;
            }
            case "button": {
              return (
                <div>
                  <RichTextButton
                    nodeFields={node.data.target.fields}
                    trackingUtil={trackingUtil}
                  />
                </div>
              );
            }
            default:
              return null;
          }
        },
      },
      renderText: (text) =>
        text
          .split("\n")
          .reduce(
            (children, textSegment, index) => [
              ...children,
              index > 0 && <br key={index} />,
              textSegment,
            ],
            [] as React.ReactNode[],
          ),
    })}
  </>
);

export default RichText;
