import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { NewsletterRegistrationDialogProps } from ".";
import useHubspotForm from "../../hooks/useHubspotForm";
import Center from "../Center";
import Markdown from "../Markdown";

const StyledOuterCenter = styled(Center)`
  max-width: 80ch;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: 0 ${(props) => props.theme.spacings.big}
      ${(props) => props.theme.spacings.big};
  }
`;

const StyledInnerCenter = styled(Center)`
  padding: 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const StyledImg = styled.img`
  width: 12rem;
  order: -1;
  margin-bottom: ${(props) => props.theme.spacings.big};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 15rem;
    order: 1;
    margin-left: ${(props) => props.theme.spacings.huge};
    margin-bottom: 0;
  }
`;

const StyledDescription = styled(Markdown)`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: block;
  }
`;

const HubspotForm: React.FC<{
  form: NewsletterRegistrationDialogProps["form"];
}> = ({ form }) => {
  useHubspotForm({
    contentfulId: form.contentful_id,
    formId: form.formId,
  });

  return (
    <StyledOuterCenter intrinsic>
      <Spacings.Stack scale="huge">
        <StyledInnerCenter intrinsic>
          <Spacings.Stack scale="huge">
            {form.title ? (
              <Text textStyle="headline3">{form.title}</Text>
            ) : null}

            {form.description?.childMarkdownRemark?.html ? (
              <StyledDescription
                priority="secondary"
                textStyle="headline4"
                as="p"
                dangerouslySetInnerHTML={{
                  __html: form.description.childMarkdownRemark.html,
                }}
              />
            ) : null}
          </Spacings.Stack>

          {form.illustration?.file?.url ? (
            <StyledImg src={form.illustration.file.url} alt="" loading="lazy" />
          ) : null}
        </StyledInnerCenter>

        <div className={`hbspt-form-${form.contentful_id}`} />
      </Spacings.Stack>
    </StyledOuterCenter>
  );
};

export default HubspotForm;
