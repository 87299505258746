import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { EkomiInfoQuery, Maybe } from "../../../../../types/graphql-types";
import { formatDate, truncateText } from "../../../../utils";
import Center from "../../../Center";
import CustomerFeedback from "../../../CustomerFeedback";
import SwitchInlineStack from "../../../SwitchInlineStack";
import contentBlock from "./contentBlock";
import EKomiCard from "./EKomiCard";

const fetchTopFeedbacks = (data: Maybe<EkomiInfoQuery>) =>
  data?.ekomiInfo?.feedbacks?.filter(
    (feedback) => feedback && Number(feedback.rating) > 2,
  ) || [];

const StyledCustomerReviewsBlockWrapper = styled.div<{ cardMode: boolean }>`
  ${(props) =>
    props.cardMode
      ? `padding: ${props.theme.spacings.medium} 0;`
      : `
      background-color: ${props.theme.palette.gray[25]};
      border-top: 1px solid ${props.theme.palette.gray[100]};
      border-bottom: 1px solid ${props.theme.palette.gray[100]};
      padding: ${props.theme.spacings.medium} 0;`}
`;

const CustomerFeedbacksWrapper = styled(SwitchInlineStack)`
  width: 100%;
  > :nth-child(2) {
    margin-top: 0;
  }
  > :first-child {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    > :first-child {
      display: block;
    }
  }
`;

type BlockCustomerReviewsProps = {
  cardMode?: boolean;
};

const BlockCustomerReviews: React.FC<BlockCustomerReviewsProps> = ({
  cardMode = false,
}) => {
  const data = useStaticQuery<Maybe<EkomiInfoQuery>>(graphql`
    query EkomiInfo {
      ekomiInfo {
        id
        fb_avg
        number_of_ratings
        ekomi_certificate
        feedbacks {
          rating
          message
          delivered
        }
      }
    }
  `);
  const topFeedbacks = fetchTopFeedbacks(data);

  useEffect(() => {
    if (!data?.ekomiInfo?.fb_avg) {
      // eslint-disable-next-line no-console
      console.error(
        new Error(`Ekomi rating is missing: ${JSON.stringify(data)}`),
      );
    }
    if (!data?.ekomiInfo?.number_of_ratings) {
      // eslint-disable-next-line no-console
      console.error(
        new Error(
          `Number of Ekomi reviews is missing: ${JSON.stringify(data)}`,
        ),
      );
    }
  }, []);

  const rating = parseFloat(data?.ekomiInfo?.fb_avg || "") || 4.9;
  const reviewsCount =
    parseInt(data?.ekomiInfo?.number_of_ratings || "", 10) || 1910;

  return (
    <StyledCustomerReviewsBlockWrapper cardMode={cardMode}>
      <Center>
        <Spacings.Inset scale="medium">
          <Spacings.Stack alignItems="center" scale="huge">
            {contentBlock.title && (
              <Text textStyle="headline4" as="h2">
                {contentBlock.title}
              </Text>
            )}
            <CustomerFeedbacksWrapper
              breakOn="tabletBreakpoint"
              alignItems="center"
              stackScale="huge"
              inlineScale="huge"
            >
              {topFeedbacks[1]?.message && topFeedbacks[1]?.rating ? (
                <CustomerFeedback
                  desktopOnly
                  feedback={truncateText(topFeedbacks[1].message, 150)}
                  date={formatDate(topFeedbacks[1].delivered)}
                  rating={Number(topFeedbacks[1].rating)}
                />
              ) : null}
              <EKomiCard
                description={contentBlock.description}
                rating={rating}
                reviewsCount={reviewsCount}
                buttonLink={data?.ekomiInfo?.ekomi_certificate || undefined}
                buttonTitle={contentBlock.buttonTitle}
              />
              {topFeedbacks[0]?.message && topFeedbacks[0]?.rating ? (
                <CustomerFeedback
                  feedback={truncateText(topFeedbacks[0].message, 150)}
                  date={formatDate(topFeedbacks[0].delivered)}
                  rating={Number(topFeedbacks[0].rating)}
                />
              ) : null}
            </CustomerFeedbacksWrapper>
          </Spacings.Stack>
        </Spacings.Inset>
      </Center>
    </StyledCustomerReviewsBlockWrapper>
  );
};

export default BlockCustomerReviews;
