import { useState, useEffect, useRef } from "react";

const useCarouselNavigation = (
  slidesLength: number,
  autoSlide: boolean,
  autoSlideInterval: number,
) => {
  const [currentSlide, setCurr] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const clearAndSetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (autoSlide) {
      intervalRef.current = setInterval(next, autoSlideInterval);
    }
  };

  const previous = () => {
    setCurr((curr) => (curr === 0 ? slidesLength - 1 : curr - 1));
    clearAndSetInterval();
  };

  const next = () => {
    setCurr((curr) => (curr === slidesLength - 1 ? 0 : curr + 1));
    clearAndSetInterval();
  };

  useEffect(() => {
    clearAndSetInterval();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [autoSlide, autoSlideInterval]);

  return { currentSlide, previous, next, setCurr };
};

export default useCarouselNavigation;
