import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { InsurerLogosGrayQuery, Maybe } from "../../types/graphql-types";

const StyledInsurerLogos = styled(Spacings.Inline)`
  justify-content: center;
`;

const StyledInsurerLogo = styled.img`
  &:nth-child(n + 4) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    &:nth-child(n + 4) {
      display: block;
    }

    &:nth-child(n + 8) {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    &:nth-child(n + 8) {
      display: block;
    }

    &:nth-child(n + 10) {
      display: none;
    }
  }
`;

const InsurerLogosGray: React.FC<{ className?: string }> = ({ className }) => {
  const data = useStaticQuery<Maybe<InsurerLogosGrayQuery>>(graphql`
    query InsurerLogosGray {
      allianz: contentfulAsset(
        contentful_id: { eq: "01MKPKrnCr7QIo1KOerLPY" }
      ) {
        file {
          url
        }
      }
      hdi: contentfulAsset(contentful_id: { eq: "2to4Co6unYlkqEbbhEf0VR" }) {
        file {
          url
        }
      }
      rplusv: contentfulAsset(contentful_id: { eq: "3omsTNuRUpMpYjl4EHIGhA" }) {
        file {
          url
        }
      }
      ergo: contentfulAsset(contentful_id: { eq: "nHYsZOKZ7yhPlNdH2DKzF" }) {
        file {
          url
        }
      }
      axa: contentfulAsset(contentful_id: { eq: "3EfZU1NZN5mEv4Gpl8sbIZ" }) {
        file {
          url
        }
      }
      hiscox: contentfulAsset(contentful_id: { eq: "2mxqAEAH6IDHDpTJWhoUXF" }) {
        file {
          url
        }
      }
      markel: contentfulAsset(contentful_id: { eq: "6V5iPRFmI3xmzfh8EPE7Sr" }) {
        file {
          url
        }
      }
      rhion: contentfulAsset(contentful_id: { eq: "3wOOns9GmMQ3CKVfp8bwsu" }) {
        file {
          url
        }
      }
      barmenia: contentfulAsset(
        contentful_id: { eq: "2yWh5Jy4DkJxGads7buq0z" }
      ) {
        file {
          url
        }
      }
    }
  `);

  const insurerLogos = data
    ? [
        { src: data.allianz, alt: "Allianz", width: 84, height: 23 },
        { src: data.hdi, alt: "HDI", width: 46, height: 17 },
        { src: data.rplusv, alt: "R+V", width: 49, height: 21 },
        { src: data.ergo, alt: "Ergo", width: 54, height: 17 },
        { src: data.axa, alt: "Axa", width: 32, height: 32 },
        { src: data.hiscox, alt: "Hiscox", width: 65, height: 32 },
        { src: data.markel, alt: "Markel", width: 28, height: 34 },
        { src: data.rhion, alt: "Rhion", width: 96, height: 18 },
        { src: data.barmenia, alt: "Barmenia", width: 96, height: 28 },
      ]
    : null;

  return (
    <StyledInsurerLogos className={className} scale="big" alignItems="center">
      {insurerLogos?.map(
        (insurerLogo) =>
          insurerLogo.src?.file?.url && (
            <StyledInsurerLogo
              key={insurerLogo.alt}
              src={insurerLogo.src.file.url}
              width={insurerLogo.width}
              height={insurerLogo.height}
              alt={insurerLogo.alt}
              loading="lazy"
            />
          ),
      )}
    </StyledInsurerLogos>
  );
};

export default InsurerLogosGray;
