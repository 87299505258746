import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockImage_ContentfulBlockImageFragment } from "../../../types/graphql-types";
import { isSvg } from "../../utils";
import Center from "../Center";
import Image from "../Image";
import VideoPlayer from "../VideoPlayer";
import YouTubePlayer from "../YouTubePlayer";

type BlockImageProps = {
  contentBlock: BlockImage_ContentfulBlockImageFragment;
};

const StyledMediaStack = styled(Spacings.Stack)<{
  dontShowMediaFullWidth: boolean;
}>`
  width: 100%;

  ${(props) =>
    props.dontShowMediaFullWidth &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        padding: 0 ${props.theme.spacings.astronomic};
        box-sizing: border-box;
      }

      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        padding: 0 9.375rem;
      }
    `};
`;

const StyledImageWrapper = styled.div<{ hasRoundedCorners: boolean }>`
  width: 100%;

  > * {
    width: 100%;
  }

  ${(props) =>
    props.hasRoundedCorners &&
    css`
      border-radius: ${props.theme.borders.radius.medium}px;
      overflow: hidden;
    `};
`;

const StyledCaption = styled(Text)`
  text-align: center;
`;

const Video: React.FC<BlockImageProps & { contentType: string }> = (props) =>
  props.contentBlock.media?.file?.url && !isSvg(props.contentType) ? (
    <VideoPlayer
      src={props.contentBlock.media.file.url}
      poster={props.contentBlock.thumbnail?.file?.url || undefined}
    />
  ) : props.contentBlock.youtubeUrl ? (
    <YouTubePlayer src={props.contentBlock.youtubeUrl} />
  ) : null;

const BlockImage: React.FC<BlockImageProps> = (props) => {
  const contentType = props.contentBlock.media?.file?.contentType || "";

  return (
    <Center intrinsic>
      <StyledMediaStack
        scale="medium"
        dontShowMediaFullWidth={
          props.contentBlock.dontShowMediaFullWidth || false
        }
      >
        {props.contentBlock.media?.fluid ||
        (props.contentBlock.media?.file?.url && isSvg(contentType)) ? (
          <StyledImageWrapper
            hasRoundedCorners={Boolean(props.contentBlock.media.fluid)}
          >
            <Image asset={props.contentBlock.media} loading="lazy" />
          </StyledImageWrapper>
        ) : (
          <Video {...props} contentType={contentType} />
        )}

        {props.contentBlock.caption && (
          <StyledCaption priority="secondary">
            {props.contentBlock.caption}
          </StyledCaption>
        )}
      </StyledMediaStack>
    </Center>
  );
};

export const blockImageFragment = graphql`
  fragment BlockImage_ContentfulBlockImage on ContentfulBlockImage {
    dontShowMediaFullWidth
    media {
      title
      description
      fluid(maxWidth: $containerWidth) {
        ...GatsbyContentfulFluid_withWebp
      }
      file {
        contentType
        url
      }
    }
    thumbnail {
      file {
        url
      }
    }
    youtubeUrl
    caption
  }
`;

export default BlockImage;
