import React from "react";

import ResponsiveHelper from "../ResponsiveHelper";

export type ResponsiveContainerProps = {
  showOn: Array<"desktop" | "tablet" | "mobile">;
};

export const ResponsiveContainer: React.FC<
  React.PropsWithChildren<ResponsiveContainerProps>
> = ({ children, showOn }) => (
  <ResponsiveHelper>
    {(breakpoint) => (showOn.includes(breakpoint) ? children : null)}
  </ResponsiveHelper>
);

export default ResponsiveContainer;
