import { useContext, useEffect } from "react";
import { useTheme } from "styled-components";

import { CookieConsentContext } from "@finanzchef24gmbh/insurance-product-system/src/components/CookieConsentContextProvider";
import { NewsletterRegistration_ContentfulNewsletterRegistrationFragment } from "../../types/graphql-types";

declare global {
  interface Window {
    hbspt: any;
  }
}

type HubspotFormProps = {
  contentfulId: NewsletterRegistration_ContentfulNewsletterRegistrationFragment["contentful_id"];
  formId: NewsletterRegistration_ContentfulNewsletterRegistrationFragment["formId"];
};

const useHubspotForm = ({ contentfulId, formId }: HubspotFormProps) => {
  const { cookieConsentLevels } = useContext(CookieConsentContext);
  const theme = useTheme();

  useEffect(() => {
    if (cookieConsentLevels) {
      const hubspotScript = document.createElement("script");
      hubspotScript.src = "https://js-eu1.hsforms.net/forms/v2.js";

      hubspotScript.onload = () => {
        const hubSpotFormStyles = `
          .actions { margin: 0; }
          .field { margin-bottom: 0.625rem; }
          .input + .hs-error-msgs { margin-top: 0.3125rem; }
          a { color: ${theme.palette.brand[500]}; }
          a:hover { color: ${theme.palette.brand[600]}; }
        `;

        window.hbspt.forms.create({
          cssRequired: hubSpotFormStyles.replace(/\n/g, ""),
          formId,
          portalId: "24877130",
          region: "eu1",
          target: `.hbspt-form-${contentfulId}`,
        });
      };

      document.head.appendChild(hubspotScript);

      return () => {
        document.head.removeChild(hubspotScript);
      };
    }
  }, [cookieConsentLevels]);
};

export default useHubspotForm;
