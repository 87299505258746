export const formatDate = (date: string) => {
  const dateAndTime = date.split(" ");
  const options: { day: "2-digit"; month: "2-digit"; year: "numeric" } = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Date(dateAndTime[0]).toLocaleDateString("de-DE", options);
};

export const formatNumber = (value: number) =>
  new Intl.NumberFormat("de-DE").format(value);

export const truncateText = (text: string, maxLength: number) =>
  text.length >= maxLength
    ? text.substring(0, maxLength - 3).concat("...")
    : text;

export const addTargetBlankToAssetLinks = (markdown: string) => {
  let html = markdown;
  const downloadLinks = html.match(/href=".+\.(jpg|png|svg|pdf)"/g);
  downloadLinks?.forEach((link) => {
    html = html.replace(link, `${link} target="_blank"`);
  });
  return html;
};

export const addExternalLinkAttributes = (markdown: string) => {
  let html = markdown;
  const links = html.match(/href=".{0,30}/g);

  links?.forEach((link) => {
    // eslint-disable-next-line prefer-regex-literals
    const internalLinkPattern = new RegExp(
      /finanzchef24\.de|ctfassets.net|^href="\/|^href="#/,
    );
    const internalLink = internalLinkPattern.test(link);

    if (!internalLink) {
      const escapedLink = link.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const replacePattern = new RegExp(escapedLink, "g");

      html = html.replace(
        replacePattern,
        `rel="nofollow noopener" target="_blank" ${link}`,
      );
    }
  });

  return html;
};

export const isSvg = (contentType: string) => contentType === "image/svg+xml";

export const isExternalLink = (link: string) => {
  // eslint-disable-next-line prefer-regex-literals
  const externalLinkPattern = new RegExp(/^https?/);
  return externalLinkPattern.test(link);
};

export const isEven = (value: number) => value % 2 === 0;

export const isProduction = () =>
  process.env.GATSBY_ORIGIN === "https://www.finanzchef24.de";
