import Img, { FixedObject, FluidObject } from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";

import {
  ContentfulAsset,
  ContentfulAssetFile,
  GatsbyContentfulFixed_WithWebpFragment,
  GatsbyContentfulFluid_WithWebpFragment,
  Maybe,
} from "../../types/graphql-types";

const StyledSvgImg = styled.img<{
  maxWidth?: string;
  maxHeight?: string;
}>`
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `};

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
`;

export type ImageProps = {
  asset?: Pick<ContentfulAsset, "title" | "description"> & {
    fluid?: Maybe<GatsbyContentfulFluid_WithWebpFragment>;
    fixed?: Maybe<GatsbyContentfulFixed_WithWebpFragment>;
    file?: Maybe<Pick<ContentfulAssetFile, "url">>;
  };
  alt?: string | null;
  loading?: "lazy" | "eager";
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  className?: string;
};

const Image: React.FC<ImageProps> = ({
  alt,
  asset,
  className,
  loading,
  width,
  height,
  maxWidth,
  maxHeight,
}) =>
  asset?.fluid ? (
    <Img
      fluid={asset.fluid as FluidObject}
      alt={alt || asset.description || asset.title || ""}
      className={className}
    />
  ) : asset?.fixed ? (
    <Img
      fixed={asset.fixed as FixedObject}
      alt={alt || asset.description || asset.title || ""}
      className={className}
    />
  ) : asset?.file?.url ? (
    <StyledSvgImg
      src={asset.file.url}
      alt={alt || asset.description || asset.title || ""}
      loading={loading}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      className={className}
    />
  ) : null;

export default Image;
