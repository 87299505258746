import React from "react";
import styled from "styled-components";

import Collapsible from "@finanzchef24gmbh/design-system/src/Collapsible";
import CloseIcon from "@finanzchef24gmbh/design-system/src/Icons/CloseIcon";
import NavigationMenuIcon from "@finanzchef24gmbh/design-system/src/Icons/NavigationMenuIcon";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { NavigationMenu_ContentfulNavigationMenuFragment } from "../../../../types/graphql-types";
import RelativeLink from "../../RelativeLink";
import SubLevelNavigation from "./SubLevelNavigation";

const StyledContainer = styled.div`
  display: inline-block;
  position: relative;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: none;
  }
`;

const BUTTON_HEIGHT = "30px";

const StyledButton = styled.button`
  z-index: 1;
  position: relative;
  border: none;
  background: none;
  height: ${BUTTON_HEIGHT};
  box-sizing: content-box;
`;

const MenuOverlay = styled(Layer.Overlay)`
  border-radius: ${(props) => props.theme.borders.radius.small}px;

  position: absolute;
  right: -${(props) => props.theme.spacings.small};
  top: -${(props) => props.theme.spacings.medium};
  padding: calc(${(props) => props.theme.spacings.huge} + ${BUTTON_HEIGHT})
    ${(props) => props.theme.spacings.medium}
    ${(props) => props.theme.spacings.huge};
  width: calc(100vw - ${(props) => props.theme.spacings.medium});
  box-sizing: border-box;

  &[hidden] {
    display: none;
  }
`;

const StyledLi = styled.li`
  padding-top: ${(props) => props.theme.spacings.small};
  padding-bottom: ${(props) => props.theme.spacings.small};

  &:not(:last-child) {
    border-bottom: ${(props) => props.theme.borders.width.small}px solid
      ${(props) => props.theme.palette.borders.main};
  }
`;

const StyledLiText = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
  max-width: none;
`;

const StyledRelativeLink = styled(RelativeLink)`
  display: block;
  text-decoration: none;
  max-width: none;
`;

const StyledSubLevelNavigation = styled(SubLevelNavigation)`
  padding-top: ${(props) => props.theme.spacings.big};
  padding-bottom: ${(props) => props.theme.spacings.big};
  margin-top: 0;
`;

type HamburgerNavigationProps = {
  navigation?: NavigationMenu_ContentfulNavigationMenuFragment;
  isOnDarkBackground?: boolean;
};

const HamburgerNavigation: React.FC<HamburgerNavigationProps> = ({
  navigation,
  isOnDarkBackground,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  if (!navigation) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledButton aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Spacings.Inline alignItems="center">
          <Text
            textStyle="button1"
            isOnDarkBackground={isOnDarkBackground && !isOpen}
            isUncropped
          >
            Menü
          </Text>
          {isOpen ? (
            <CloseIcon aria-hidden="true" />
          ) : (
            <NavigationMenuIcon
              isOnDarkBackground={isOnDarkBackground}
              aria-hidden="true"
            />
          )}
        </Spacings.Inline>
      </StyledButton>
      <MenuOverlay hidden={!isOpen}>
        <ul>
          {navigation.navigationLevels?.map((level, index) => {
            if (!level) {
              return null;
            }

            const topLevelLinkId = `nav-item-${level.id}`;

            return level.topLevelLink ? (
              <StyledLi key={`${level.id}-${index}`}>
                {level.subLevelLinks1 ? (
                  <Collapsible>
                    <Collapsible.Header>
                      <Collapsible.Headline>
                        <StyledLiText
                          textStyle="button1"
                          isUncropped
                          id={topLevelLinkId}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: level.topLevelLink.label || "",
                            }}
                          />
                        </StyledLiText>
                      </Collapsible.Headline>
                      <Collapsible.Chevron />
                    </Collapsible.Header>

                    <Collapsible.Content>
                      <StyledSubLevelNavigation
                        subLevelHeading={level.subLevelHeading1}
                        subLevelLinks={level.subLevelLinks1}
                        labelledBy={topLevelLinkId}
                      />

                      {level.subLevelLinks2 ? (
                        <StyledSubLevelNavigation
                          subLevelHeading={level.subLevelHeading2}
                          subLevelLinks={level.subLevelLinks2}
                          labelledBy={topLevelLinkId}
                          isSubLevel2
                        />
                      ) : null}
                    </Collapsible.Content>
                  </Collapsible>
                ) : (
                  <StyledRelativeLink href={level.topLevelLink.slug || ""}>
                    <StyledLiText textStyle="button1" isUncropped>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: level.topLevelLink.label || "",
                        }}
                      />
                    </StyledLiText>
                  </StyledRelativeLink>
                )}
              </StyledLi>
            ) : null;
          })}
        </ul>
      </MenuOverlay>
    </StyledContainer>
  );
};

export default HamburgerNavigation;
