import { graphql } from "gatsby";
import React from "react";

import { BlockNotificationBar_ContentfulBlockNotificationBarFragment } from "../../../../types/graphql-types";
import BottomStickyBar from "./BottomStickyBar";
import TopNewsBar from "./TopNewsBar";

export type BlockNotificationBarProps = {
  contentBlock: BlockNotificationBar_ContentfulBlockNotificationBarFragment;
};

type Layout = "BottomStickyBar" | "TopNewsBar";

const isValidLayout = (layout: string): layout is Layout =>
  ["BottomStickyBar", "TopNewsBar"].includes(layout);

const BlockNotificationBar: React.FC<BlockNotificationBarProps> & {
  BottomStickyBar: React.FC<BlockNotificationBarProps>;
  TopNewsBar: React.FC<BlockNotificationBarProps>;
} = (props) => {
  if (!props.contentBlock.layout || !isValidLayout(props.contentBlock.layout)) {
    return null;
  }
  const NotificationBarComponent =
    BlockNotificationBar[props.contentBlock.layout];

  return <NotificationBarComponent {...props} />;
};

BlockNotificationBar.BottomStickyBar = (props) => (
  <BottomStickyBar {...props} />
);
BlockNotificationBar.TopNewsBar = (props) => <TopNewsBar {...props} />;

export const BlockNotificationBarFragment = graphql`
  fragment BlockNotificationBar_ContentfulBlockNotificationBar on ContentfulBlockNotificationBar {
    layout
    hideTopNewsBar
    icon {
      file {
        url
      }
    }
    richText {
      json
    }
    button {
      ...Button_ContentfulButton
    }
  }
`;

export default BlockNotificationBar;
