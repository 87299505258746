import React from "react";

import Link from "@finanzchef24gmbh/design-system/src/Link";

type RelativeLinkProps = React.ComponentProps<typeof Link>;

const RelativeLink: React.FC<RelativeLinkProps> = ({ href, ...restProps }) => {
  const relativeHref = href && href[0] !== "/" ? `/${href}` : href;

  return <Link {...restProps} href={relativeHref} />;
};

export default RelativeLink;
