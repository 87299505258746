import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Badge from "@finanzchef24gmbh/design-system/src/Badge";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockProcess_ContentfulBlockProcessFragment } from "../../../types/graphql-types";
import { trackProcessItemButton } from "../../utils/trackingUtils";
import Center from "../Center";
import Image from "../Image";
import RichText from "../RichText";
import SwitchInlineStack from "../SwitchInlineStack";

type BlockProcessProps = {
  contentBlock: BlockProcess_ContentfulBlockProcessFragment;
};

const StyledCenter = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledImgWrapper = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex: 1;
    display: flex;
  }

  > div {
    width: 100%;
    height: 100%;
  }
`;

const StyledSpacingsStack = styled(Spacings.Stack)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex: 2;
  }
`;

const BlockProcess: React.FC<BlockProcessProps> = (props) => (
  <StyledCenter>
    <Spacings.Stack scale="gigantic">
      {props.contentBlock.processHeading && (
        <Center intrinsic>
          <Text
            textStyle="headline3"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: props.contentBlock.processHeading,
            }}
          />
        </Center>
      )}
      {props.contentBlock.items?.map((item, index) => {
        if (!item) {
          return null;
        }
        return (
          <SwitchInlineStack
            key={index}
            breakOn="mobileBreakpoint"
            inlineScale="huge"
          >
            {item.image && item.imagePosition === "left" && (
              <StyledImgWrapper>
                {(item.image.fluid || item.image.file?.url) && (
                  <Image asset={item.image} loading="lazy" />
                )}
              </StyledImgWrapper>
            )}
            <StyledSpacingsStack scale="medium">
              {item.badgeText && (
                <Badge
                  shouldWrap={false}
                  color="accent"
                  outline={item.badgeStyle === "outline"}
                >
                  <Text
                    isOnDarkBackground={item.badgeStyle !== "outline"}
                    priority={
                      item.badgeStyle === "outline" ? "accent" : undefined
                    }
                  >
                    {item.badgeText}
                  </Text>
                </Badge>
              )}
              {item.contentRichText?.json && (
                <Spacings.Stack scale="huge">
                  <RichText
                    document={item.contentRichText.json}
                    trackingUtil={trackProcessItemButton}
                  />
                </Spacings.Stack>
              )}
            </StyledSpacingsStack>
            {item.image && item.imagePosition === "right" && (
              <StyledImgWrapper>
                {(item.image.fluid || item.image.file?.url) && (
                  <Image asset={item.image} loading="lazy" />
                )}
              </StyledImgWrapper>
            )}
          </SwitchInlineStack>
        );
      })}
    </Spacings.Stack>
  </StyledCenter>
);

export const BlockProcessFragment = graphql`
  fragment BlockProcess_ContentfulBlockProcess on ContentfulBlockProcess {
    __typename
    processHeading: heading
    items {
      badgeText
      badgeStyle
      contentRichText {
        json
      }
      image {
        title
        description
        file {
          url
        }
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imagePosition
    }
  }
`;

export default BlockProcess;
