import React from "react";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { Contentful_ContentfulMediaItemListFragment } from "../../../../../types/graphql-types";
import Collapsible from "../../../Collapsible";

type CollapsibleListProps = {
  mediaItems: Contentful_ContentfulMediaItemListFragment["mediaItems"];
};

const CollapsibleList: React.FC<CollapsibleListProps> = ({ mediaItems }) => {
  /**
   * Halve array into 2 separate groups so that when a Collapsible is expanded
   * only the content directly below it moves down while the other group of
   * Collapsibles stay in the same position.
   */
  const halfListIndex = mediaItems ? Math.ceil(mediaItems.length / 2) : 0;
  const firstListHalf = mediaItems?.slice(0, halfListIndex);
  const secondListHalf = mediaItems?.slice(-halfListIndex);

  return (
    <>
      <Spacings.Stack scale="medium">
        {firstListHalf?.map((mediaItem) =>
          mediaItem?.__typename === "ContentfulCollapsible" ? (
            <Collapsible key={mediaItem.id} content={mediaItem} />
          ) : null,
        )}
      </Spacings.Stack>

      <Spacings.Stack scale="medium">
        {secondListHalf?.map((mediaItem) =>
          mediaItem?.__typename === "ContentfulCollapsible" ? (
            <Collapsible key={mediaItem.id} content={mediaItem} />
          ) : null,
        )}
      </Spacings.Stack>
    </>
  );
};

export default CollapsibleList;
