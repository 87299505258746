import { graphql } from "gatsby";
import React from "react";

import LockIcon from "@finanzchef24gmbh/design-system/src/Icons/LockIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { MobileTextIcon_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";

type MobileTextIconProps = {
  stickyHeaderIconMobile: MobileTextIcon_ContentfulBlockHeroFragment["stickyHeaderIconMobile"];
  stickyHeaderTextMobile: MobileTextIcon_ContentfulBlockHeroFragment["stickyHeaderTextMobile"];
};

const MobileTextIcon: React.FC<MobileTextIconProps> = ({
  stickyHeaderIconMobile,
  stickyHeaderTextMobile,
}) => {
  const iconComponents: {
    [iconName: string]: React.ComponentType;
  } = { LockIcon };

  const Icon = stickyHeaderIconMobile
    ? iconComponents[stickyHeaderIconMobile]
    : null;

  return (
    <Spacings.Inline scale="tiny" alignItems="center">
      {Icon ? <Icon /> : null}

      <Text priority="secondary" textStyle="caption">
        {stickyHeaderTextMobile}
      </Text>
    </Spacings.Inline>
  );
};

export const mobileTextIconFragments = graphql`
  fragment MobileTextIcon_ContentfulBlockHero on ContentfulBlockHero {
    stickyHeaderTextMobile
    stickyHeaderIconMobile
  }
`;

export default MobileTextIcon;
