import React from "react";
import styled, { css } from "styled-components";

import ResponsiveHelper from "@finanzchef24gmbh/design-system/src/ResponsiveHelper";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockTableOfContentsProps } from ".";
import Center from "../../Center";
import FocusLink from "../../FocusLink";
import {
  filterBlocksWithTableOfContentsInfo,
  isListOfBlocksWithTableOfContentsInfo,
} from "./utils";

const StyledTableOfContentsBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: ${(props) => props.theme.spacings.big};
  }
`;

const StyledOl = styled(Text)`
  color: ${(props) => props.theme.palette.brand[700]};
`;

const StyledLi = styled.li<{ isSubItem: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${(props) => props.theme.spacings.small};
  padding: ${(props) => props.theme.spacings.medium} 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: ${(props) => props.theme.spacings.small} 0;
  }

  :not(:first-child) {
    border-top: ${(props) => props.theme.borders.width.small}px dotted
      ${(props) => props.theme.palette.borders.light};
  }

  :last-child {
    border-bottom: ${(props) => props.theme.borders.width.small}px dotted
      ${(props) => props.theme.palette.borders.light};
  }

  ${(props) =>
    props.isSubItem &&
    css`
      margin-left: 2.375rem;
    `}
`;

const StyledNumber = styled.span`
  background-color: ${(props) => props.theme.palette.mint[600]};
  color: ${(props) => props.theme.palette.white};
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

const StyledLink = styled(FocusLink)`
  text-decoration: none;
  a& {
    color: inherit;
  }
`;

const List: React.FC<BlockTableOfContentsProps> = (props) => {
  const contentBlocksWithTableOfContentsInfo =
    filterBlocksWithTableOfContentsInfo(props.contentBlocks);

  if (
    !isListOfBlocksWithTableOfContentsInfo(contentBlocksWithTableOfContentsInfo)
  ) {
    return null;
  }

  let listNumber = 0;

  return (
    <StyledTableOfContentsBlock
      className={props.className}
      {...(props.contentBlock.removeWholeBlockFromGoogleSnippet && {
        "data-nosnippet": props.contentBlock.removeWholeBlockFromGoogleSnippet,
      })}
    >
      <Spacings.Stack scale="gigantic">
        {props.contentBlock.title ? (
          <Text
            textStyle="headline3"
            id={`toc-${props.contentBlock.id}`}
            as="h2"
            dangerouslySetInnerHTML={{ __html: props.contentBlock.title }}
          />
        ) : null}
        <StyledOl
          aria-labelledby={`toc-${props.contentBlock.id}`}
          textStyle="bodyProlonged"
          as="ol"
        >
          {contentBlocksWithTableOfContentsInfo
            .filter(
              (contentBlockWithTableOfContentsInfo) =>
                contentBlockWithTableOfContentsInfo.includeInTableOfContents,
            )
            .map((contentBlock, index) => {
              listNumber = contentBlock.isTableOfContentsSubItem
                ? listNumber
                : listNumber + 1;

              return contentBlock.title ? (
                <StyledLi
                  key={`toc-li-${index}`}
                  isSubItem={contentBlock.isTableOfContentsSubItem || false}
                >
                  {!contentBlock.isTableOfContentsSubItem && (
                    <StyledNumber aria-hidden="true" data-nosnippet>
                      {listNumber}
                    </StyledNumber>
                  )}
                  <ResponsiveHelper>
                    {(breakpoint) => (
                      <StyledLink
                        href={`#${contentBlock.slug}`}
                        dangerouslySetInnerHTML={{
                          __html: contentBlock.title || "",
                        }}
                        offsetTop={breakpoint === "mobile" ? 24 : 96}
                      />
                    )}
                  </ResponsiveHelper>
                </StyledLi>
              ) : null;
            })}
        </StyledOl>
      </Spacings.Stack>
    </StyledTableOfContentsBlock>
  );
};

export default List;
