import React from "react";

import {
  List_ContentfulListFragment,
  Scalars,
} from "../../types/graphql-types";
import List from "./List";

type RichTextListProps = {
  nodeFields: Scalars["JSON"];
};

const RichTextList: React.FC<RichTextListProps> = ({ nodeFields }) => {
  const items = nodeFields.items.map((item: Scalars["JSON"]) => {
    const itemFields = item.fields;

    return {
      designSystemIcon: itemFields.designSystemIcon,
      designSystemIconSize: itemFields.designSystemIconSize,
      icon: {
        file: {
          title: itemFields.icon?.fields?.title,
          url: itemFields.icon?.fields?.file?.url,
        },
      },
      richText: {
        json: itemFields.richText,
      },
    };
  });

  const content: List_ContentfulListFragment = {
    items,
    listType: nodeFields.listType,
  };

  return <List content={{ ...content }} />;
};

export default RichTextList;
