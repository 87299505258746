import { graphql } from "gatsby";
import React from "react";
import {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { ButtonProps as DesignSystemButtonProps } from "@finanzchef24gmbh/design-system/src/Button";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import { Button_ContentfulButtonFragment } from "../../../types/graphql-types";
import NewsletterRegistrationDialog from "../NewsletterRegistrationDialog";
import { shouldShowNewsletterRegistration } from "../NewsletterRegistrationDialog/NewsletterTwoGoForm";
import ButtonContent from "./ButtonContent";

export type CustomTextStyles = FlattenInterpolation<ThemeProps<DefaultTheme>>;

export type ButtonProps = {
  content: Button_ContentfulButtonFragment;
  customTextStyles?: CustomTextStyles;
  scale?: DesignSystemButtonProps["scale"];
  trackLink?: () => void;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  className,
  content,
  customTextStyles,
  scale,
  trackLink,
}) => {
  const dialogState = useDialogState();
  const link = content.assetDownload?.file?.url || content.buttonLink;

  return (
    <>
      {link ? (
        <a
          className={className}
          href={link}
          {...(content.openLinkInNewWindow && {
            target: "_blank",
          })}
          {...(content.isNofollowLink && {
            rel: "nofollow noopener",
          })}
          {...(content.assetDownload?.file?.url && {
            download: true,
          })}
          onClick={(event) => {
            if (
              shouldShowNewsletterRegistration(content.newsletterRegistration)
            ) {
              event.preventDefault();
              dialogState.open();
            }

            trackLink?.();
          }}
        >
          <ButtonContent
            content={content}
            customTextStyles={customTextStyles}
            scale={scale}
          />
        </a>
      ) : (
        <ButtonContent
          className={className}
          content={content}
          customTextStyles={customTextStyles}
          scale={scale}
          {...(dialogState && {
            onClick: () => dialogState.open(),
          })}
        />
      )}

      {content.newsletterRegistration ? (
        <NewsletterRegistrationDialog
          form={content.newsletterRegistration}
          dialogState={dialogState}
          downloadLink={link || ""}
        />
      ) : null}
    </>
  );
};

export const ButtonFragment = graphql`
  fragment Button_ContentfulButton on ContentfulButton {
    buttonText
    buttonLink
    assetDownload {
      file {
        url
      }
    }
    newsletterRegistration {
      ...NewsletterRegistration_ContentfulNewsletterRegistration
    }
    buttonType
    buttonIcon
    buttonIconPosition
    openLinkInNewWindow
    isNofollowLink
  }
`;

export default Button;
