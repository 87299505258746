import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";

const StyledLink = styled.a`
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: auto;
    white-space: nowrap;
  }
`;

const buildEntryRouterUrl = (options: {
  target?: string | null;
  tenant?: string | null;
  industryId?: string | null;
  selectedProductTypeCombination?: string | null;
  industryIdsForIndustrySelection?: Array<string | null> | null;
}) => {
  const tenant = options.tenant || "fc24";
  // TODO: remove the `agent` query parameter once it is no longer required
  let url = `${process.env.GATSBY_ORIGIN}/backend/0.3/entry-router?agent=${tenant}&tenant=${tenant}`;

  if (options.target) {
    url += `&target=${options.target}`;
  }

  if (options.industryId) {
    url += `&industryId=${options.industryId}`;
  }

  if (options.selectedProductTypeCombination) {
    url += `&selectedProductTypeCombination=${options.selectedProductTypeCombination}`;
  }

  if (options.industryIdsForIndustrySelection) {
    url += options.industryIdsForIndustrySelection
      .map((id) => `&industryIdsForIndustrySelection=${id}`)
      .join("");
  }

  return url;
};

type ButtonProps = React.ComponentProps<typeof Button>;

type FunnelEntryButtonProps = {
  className?: string;
  url?: string | null;
  target?: string | null;
  tenant?: string | null;
  industryId?: string | null;
  selectedProductTypeCombination?: string | null;
  industryIdsForIndustrySelection?: Array<string | null> | null;
  scale?: ButtonProps["scale"];
  buttonType?: ButtonProps["buttonType"];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
};

const FunnelEntryButton: React.FC<FunnelEntryButtonProps> = ({
  className,
  url,
  target,
  tenant,
  industryId,
  selectedProductTypeCombination,
  industryIdsForIndustrySelection,
  children,
  scale = "big",
  buttonType = "primary",
  onClick,
}) => (
  <StyledLink
    className={className}
    href={
      url ||
      buildEntryRouterUrl({
        industryId,
        industryIdsForIndustrySelection,
        selectedProductTypeCombination,
        target,
        tenant,
      })
    }
  >
    <Button
      buttonType={buttonType}
      scale={scale}
      tabIndex={-1}
      onClick={onClick}
    >
      {children}
    </Button>
  </StyledLink>
);

export default FunnelEntryButton;
