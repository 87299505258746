import { useEffect, useRef } from "react";

const useSwipe = (onSwipeLeft: () => void, onSwipeRight: () => void) => {
  const swipeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!swipeRef.current) return;

    // Check for touch event support
    if (!("ontouchstart" in window || navigator.maxTouchPoints > 0)) {
      return;
    }

    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e: TouchEvent) => {
      touchStartX = e.changedTouches[0].screenX;
    };

    const handleTouchEnd = (e: TouchEvent) => {
      touchEndX = e.changedTouches[0].screenX;
      handleSwipeGesture();
    };

    const handleSwipeGesture = () => {
      if (touchStartX - touchEndX > 50) {
        onSwipeLeft();
      }

      if (touchEndX - touchStartX > 50) {
        onSwipeRight();
      }
    };

    const wrapper = swipeRef.current;
    wrapper.addEventListener("touchstart", handleTouchStart);
    wrapper.addEventListener("touchend", handleTouchEnd);

    return () => {
      wrapper.removeEventListener("touchstart", handleTouchStart);
      wrapper.removeEventListener("touchend", handleTouchEnd);
    };
  }, [onSwipeLeft, onSwipeRight]);

  return swipeRef;
};

export default useSwipe;
