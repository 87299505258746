import React from "react";
import styled, { css } from "styled-components";

import Button, { ButtonType } from "@finanzchef24gmbh/design-system/src/Button";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { HighlightedIndustry } from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearchDialog";
import { BlockHeroProps } from ".";
import {
  trackQuickIndustrySelection,
  trackSecondaryHeroLink,
} from "../../../utils/trackingUtils";
import FunnelEntryButton from "../../FunnelEntryButton";
import Responsive from "../../Responsive";
import SwitchInlineStack from "../../SwitchInlineStack";
import CardWithBadge from "./CardWithBadge";
import IndustrySearch from "./IndustrySearch";

const StyledMobileResponsive = styled(Responsive)<{ hasSavingsBadge: boolean }>`
  ${(props) =>
    props.hasSavingsBadge &&
    css`
      padding-right: ${props.theme.spacings.gigantic};
    `};
`;

const QuickIndustrySelectionContainer = styled(Spacings.Stack)`
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const QuickIndustrySelectionButton = styled(Button)`
  height: 100%;
  width: 100%;
`;

const QuickIndustrySelectionText = styled(Spacings.Inline)`
  justify-content: center;
  font-size: 0.875em;
`;

const StyledListItem = styled.li`
  flex: 1;
`;

const QuickIndustrySelectionIcon = styled.img`
  width: 1.25em;
  height: 1.25em;
`;

const StyledSecondaryButtonLink = styled.a`
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: auto;
    white-space: nowrap;
  }
`;

const ButtonContentWrapper = styled(Spacings.Inline)`
  justify-content: center;
`;

type CallToActionProps = BlockHeroProps & {
  hasSavingsBadge: boolean;
};

const CallToAction: React.FC<CallToActionProps> = (props) => {
  if (props.contentBlock.showIndustrySelection) {
    // Case 1: Show the industry selection if the block is configured to do so.
    return (
      <CardWithBadge
        badge={props.contentBlock.badge}
        image={props.contentBlock.addonBoxImage}
        richTextSingleLine={props.contentBlock.addonBoxSingleLineRichText}
      >
        <Spacings.Stack scale="big">
          {props.contentBlock.ctaSectionTitle && (
            <div>
              <StyledMobileResponsive
                hasSavingsBadge={props.hasSavingsBadge}
                hideOn={["tablet", "desktop"]}
              >
                <label htmlFor="fc24-ac-input-dialog">
                  <Text
                    textStyle="headline4"
                    as="span"
                    dangerouslySetInnerHTML={{
                      __html: props.contentBlock.ctaSectionTitle,
                    }}
                  />
                </label>
              </StyledMobileResponsive>
              <Responsive hideOn={["mobile"]}>
                <label htmlFor="fc24-ac-input">
                  <Text
                    textStyle="headline4"
                    as="span"
                    dangerouslySetInnerHTML={{
                      __html: props.contentBlock.ctaSectionTitle,
                    }}
                  />
                </label>
              </Responsive>
            </div>
          )}

          <Spacings.Stack scale="medium">
            <IndustrySearch
              placeholder={
                props.contentBlock.industryAutocompletePlaceholder || undefined
              }
              buttonText={props.contentBlock.ctaButton?.buttonText || undefined}
              buttonTextMobile={
                props.contentBlock.ctaButtonTextMobile || undefined
              }
              selectedProductTypeCombination={
                props.contentBlock.industrySelectionProductCombinationId ||
                undefined
              }
              highlightedIndustries={
                props.contentBlock.quickIndustrySelections
                  ? props.contentBlock.quickIndustrySelections.reduce(
                      (acc, industrySelection) => {
                        if (
                          industrySelection &&
                          industrySelection.industryId &&
                          industrySelection.title
                        ) {
                          acc.push({
                            id: industrySelection.industryId,
                            name: industrySelection.title,
                          });
                        }
                        return acc;
                      },
                      [] as HighlightedIndustry[],
                    )
                  : undefined
              }
              onHighlightedIndustrySelection={(industry) => {
                trackQuickIndustrySelection({
                  industryId: industry.id,
                  productTypeCombinationId:
                    props.contentBlock.industrySelectionProductCombinationId,
                  title: industry.name,
                });
              }}
            />

            {(props.contentBlock.quickIndustrySelectionsTitle ||
              props.contentBlock.quickIndustrySelections) && (
              <QuickIndustrySelectionContainer>
                {props.contentBlock.quickIndustrySelectionsTitle && (
                  <Text
                    textStyle="headline6"
                    as="p"
                    dangerouslySetInnerHTML={{
                      __html: props.contentBlock.quickIndustrySelectionsTitle,
                    }}
                  />
                )}

                {props.contentBlock.quickIndustrySelections && (
                  <Spacings.Inline as="ul">
                    {props.contentBlock.quickIndustrySelections.map(
                      (quickIndustrySelection) => {
                        if (!quickIndustrySelection) {
                          return;
                        }

                        // TODO: remove the `agent` query parameter once it is no longer required
                        let quickLink = `${process.env.GATSBY_ORIGIN}/backend/0.3/entry-router?agent=fc24&tenant=fc24&target=interview&industryId=${quickIndustrySelection.industryId}`;

                        if (quickIndustrySelection.productTypeCombinationId) {
                          quickLink += `&selectedProductTypeCombination=${quickIndustrySelection.productTypeCombinationId}`;
                        }

                        return (
                          <StyledListItem key={quickIndustrySelection.id}>
                            <a
                              href={quickLink}
                              onClick={() =>
                                trackQuickIndustrySelection(
                                  quickIndustrySelection,
                                )
                              }
                            >
                              <QuickIndustrySelectionButton
                                scale="medium"
                                buttonType="flat"
                                tabIndex={-1}
                              >
                                <QuickIndustrySelectionText alignItems="center">
                                  {quickIndustrySelection.icon &&
                                    quickIndustrySelection.icon.file &&
                                    quickIndustrySelection.icon.file.url && (
                                      <QuickIndustrySelectionIcon
                                        src={
                                          quickIndustrySelection.icon.file.url
                                        }
                                        alt=""
                                        loading="lazy"
                                      />
                                    )}

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        quickIndustrySelection.title || "",
                                    }}
                                  />
                                </QuickIndustrySelectionText>
                              </QuickIndustrySelectionButton>
                            </a>
                          </StyledListItem>
                        );
                      },
                    )}
                  </Spacings.Inline>
                )}
              </QuickIndustrySelectionContainer>
            )}
          </Spacings.Stack>
        </Spacings.Stack>
      </CardWithBadge>
    );
  }

  const iconComponents: {
    [iconName: string]: React.ComponentType<{
      isOnDarkBackground?: boolean;
    }>;
  } = { ChevronRightIcon };
  const CTAButtonIcon = props.contentBlock.ctaButton?.buttonIcon
    ? iconComponents[props.contentBlock.ctaButton.buttonIcon]
    : null;
  const ctaButtonType = props.contentBlock.ctaButton?.buttonType || "primary";
  const SecondaryButtonIcon = props.contentBlock.secondaryButton?.buttonIcon
    ? iconComponents[props.contentBlock.secondaryButton.buttonIcon]
    : null;
  const secondaryButtonType =
    props.contentBlock.secondaryButton?.buttonType || "secondary";

  const ctaButton = (
    <SwitchInlineStack
      breakOn="mobileBreakpoint"
      alignItems="center"
      inlineScale="big"
      stackScale="medium"
    >
      {props.contentBlock.secondaryButton?.buttonText &&
        props.contentBlock.secondaryButton?.buttonLink && (
          <StyledSecondaryButtonLink
            href={props.contentBlock.secondaryButton.buttonLink}
          >
            <Button
              buttonType={secondaryButtonType as ButtonType}
              scale="big"
              tabIndex={-1}
            >
              <ButtonContentWrapper alignItems="center">
                {SecondaryButtonIcon &&
                  props.contentBlock.secondaryButton?.buttonIconPosition ===
                    "left" && (
                    <SecondaryButtonIcon
                      isOnDarkBackground={secondaryButtonType === "primary"}
                    />
                  )}

                <div>{props.contentBlock.secondaryButton.buttonText}</div>

                {SecondaryButtonIcon &&
                  props.contentBlock.secondaryButton?.buttonIconPosition ===
                    "right" && (
                    <SecondaryButtonIcon
                      isOnDarkBackground={secondaryButtonType === "primary"}
                    />
                  )}
              </ButtonContentWrapper>
            </Button>
          </StyledSecondaryButtonLink>
        )}

      {props.contentBlock.ctaButton && (
        <FunnelEntryButton
          url={props.contentBlock.ctaButton.buttonLink}
          target={props.contentBlock.industrySelectionTarget}
          tenant={props.contentBlock.tenant}
          industryId={props.contentBlock.industryId}
          selectedProductTypeCombination={
            props.contentBlock.industrySelectionProductCombinationId
          }
          industryIdsForIndustrySelection={
            props.contentBlock.industryIdsForIndustrySelection
          }
          buttonType={ctaButtonType as ButtonType}
        >
          <ButtonContentWrapper alignItems="center">
            {CTAButtonIcon &&
              props.contentBlock.ctaButton.buttonIconPosition === "left" && (
                <CTAButtonIcon
                  isOnDarkBackground={ctaButtonType === "primary"}
                />
              )}

            <Responsive hideOn={["mobile"]}>
              <div>{props.contentBlock.ctaButton.buttonText}</div>
            </Responsive>

            <Responsive hideOn={["tablet", "desktop"]}>
              <div>
                {props.contentBlock.ctaButtonTextMobile ||
                  props.contentBlock.ctaButton.buttonText}
              </div>
            </Responsive>

            {CTAButtonIcon &&
              props.contentBlock.ctaButton.buttonIconPosition === "right" && (
                <CTAButtonIcon
                  isOnDarkBackground={ctaButtonType === "primary"}
                />
              )}
          </ButtonContentWrapper>
        </FunnelEntryButton>
      )}

      {props.contentBlock.secondaryLinkText && (
        <Link
          // TODO: remove the `agent` query parameter once it is no longer required
          href={`${
            process.env.GATSBY_ORIGIN
          }/backend/0.3/entry-router?agent=fc24&tenant=fc24&target=productSelection${
            props.contentBlock.industryId
              ? `&industryId=${props.contentBlock.industryId}`
              : ""
          }`}
          onClick={() => {
            trackSecondaryHeroLink(props.contentBlock.industryId || undefined);
          }}
        >
          {props.contentBlock.secondaryLinkText}
        </Link>
      )}
    </SwitchInlineStack>
  );

  if (props.contentBlock.ctaButton?.buttonText) {
    // Case 2: A button should be shown and there is additional text
    // (i.e. a title, some text or both). Display all of that inside
    // a card.
    return (
      <CardWithBadge
        badge={props.contentBlock.badge}
        image={props.contentBlock.addonBoxImage}
        richTextSingleLine={props.contentBlock.addonBoxSingleLineRichText}
      >
        <Spacings.Stack scale="big">
          {props.contentBlock.ctaSectionTitle && (
            <Text
              textStyle="headline4"
              as="h2"
              dangerouslySetInnerHTML={{
                __html: props.contentBlock.ctaSectionTitle,
              }}
            />
          )}
          {props.contentBlock.ctaSectionText &&
            props.contentBlock.ctaSectionText.ctaSectionText && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.ctaSectionText.ctaSectionText,
                }}
              />
            )}
          {ctaButton}
        </Spacings.Stack>
      </CardWithBadge>
    );
  }

  // Case 3: There's no text for a CTA button, so don't show anything.
  return null;
};

export default CallToAction;
