import React from "react";
import styled from "styled-components";

import StarIcon from "@finanzchef24gmbh/design-system/src/Icons/StarIcon";
import { PassedProps } from "@finanzchef24gmbh/design-system/src/Icons/useMappedProps";
import { SingleRatingProps } from "@finanzchef24gmbh/design-system/src/Rating";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { ConfigProps } from "..";
import { AppProps } from "../App";
import { EkomiProps } from "../hooks/useEkomiRating";
import { buildAwinLink } from "../utils";
import CustomRating from "./CustomRating";

const StyledEkomiLink = styled.a`
  display: flex;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 100%;
    flex-direction: row;
    margin-left: 0;
  }
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const StyledRatingInline = styled(Spacings.Inline)`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledLink = styled(Text)`
  :hover {
    color: ${(props) => props.theme.palette.gray[200]};
  }
`;

type FooterProps = {
  content: AppProps["homepageContentfulData"];
  ekomi: EkomiProps;
  awinAffId: ConfigProps["config"]["awinAffId"];
  partnerName: ConfigProps["config"]["partnerName"];
};

const Footer: React.FC<FooterProps> = ({
  awinAffId,
  content,
  ekomi,
  partnerName,
}) => {
  const footerLinkUrl = buildAwinLink({
    awinAffId,
    link: content?.footerLinkUrl || "",
    partnerName,
  });

  // @ts-ignore:next-line
  const RatingStarIcon: SingleRatingProps["icon"] &
    React.ComponentType<PassedProps> = StarIcon;

  return (
    <Spacings.Inline alignItems="center" usePxUnits>
      <StyledEkomiLink
        href={ekomi.link}
        target="_blank"
        rel="nofollow noopener"
      >
        {ekomi.imgSrc && (
          <img
            src={ekomi.imgSrc}
            alt={`eKomi Siegel ${ekomi.seal} für Finanzchef24`}
          />
        )}
      </StyledEkomiLink>

      <StyledTextContainer>
        <Spacings.Inline scale="massive" usePxUnits>
          {content?.footerText ? (
            <StyledText
              textStyle="caption"
              isOnDarkBackground
              isStrong
              isUncropped
              usePxFontSize
            >
              {content.footerText}
            </StyledText>
          ) : null}

          {ekomi.rating && (
            <StyledRatingInline alignItems="center" usePxUnits>
              <StyledText
                textStyle="caption"
                isOnDarkBackground
                isUncropped
                usePxFontSize
              >
                ekomi: {ekomi.rating}/5
              </StyledText>
              <CustomRating
                icon={RatingStarIcon}
                score={(ekomi.rating / 5) * 100}
                iconScale="small"
                spacingScale="tiny"
              />
            </StyledRatingInline>
          )}
        </Spacings.Inline>

        {content?.footerLinkText && content.footerLinkUrl ? (
          <StyledLink
            textStyle="caption"
            as="a"
            isOnDarkBackground
            isUncropped
            href={footerLinkUrl}
            target="_blank"
            rel="nofollow noopener"
            usePxFontSize
          >
            {content.footerLinkText}
          </StyledLink>
        ) : null}
      </StyledTextContainer>
    </Spacings.Inline>
  );
};

export default Footer;
