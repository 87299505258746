import Img, { FluidObject, GatsbyImageProps } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { CardsProps } from ".";
import { trackCardLink } from "../../../utils/trackingUtils";
import Button from "../../Button";

const StyledImageWithButtonGrid = styled.div`
  display: grid;
  gap: ${(props) => props.theme.spacings.big};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const StyledImageWithButtonImage = styled(Img)<GatsbyImageProps>`
  width: 100%;
  height: 15rem;

  @media (min-width: 30em) {
    height: 20rem;
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    height: 10rem;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ImageWithButtonCards: React.FC<CardsProps> = ({ cards }) => (
  <StyledImageWithButtonGrid>
    {cards?.map((card, index) => {
      if (!card) {
        return null;
      }

      return (
        <Spacings.Stack key={index}>
          {card.image?.fluid && (
            <StyledImageWithButtonImage
              fluid={card.image.fluid as FluidObject}
              alt={card.image.description || card.image.title || ""}
              imgStyle={{ objectPosition: "top center" }}
            />
          )}
          {card.linkUrl && (
            <StyledButton
              content={{
                buttonLink: card.linkUrl,
                buttonText: card.linkText,
              }}
              trackLink={() => trackCardLink("categories", card.linkUrl)}
            />
          )}
        </Spacings.Stack>
      );
    })}
  </StyledImageWithButtonGrid>
);

export default ImageWithButtonCards;
