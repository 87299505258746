import { graphql } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { BlockTeam_ContentfulBlockTeamFragment } from "../../../../types/graphql-types";

import Button from "../../Button";
import Center from "../../Center";
import Filter from "./Filter";
import FullContent from "./FullContent";
import PreviewContent from "./PreviewContent";
import {
  Filter as FilterType,
  getActiveFilter,
  getVisibleDepartment,
} from "./utils";

const StyledCenter = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledFilter = styled(Filter)`
  margin-right: ${(props) => props.theme.spacings.small};
  margin-bottom: ${(props) => props.theme.spacings.small};
`;

const StyledCtaButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
`;

export type BlockTeamProps = {
  contentBlock: BlockTeam_ContentfulBlockTeamFragment;
};

const BlockTeam: React.FC<BlockTeamProps> = (props) => {
  const [activeFilter, setActiveFilter] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);

  const visibleDepartment = useMemo(() => {
    if (activeFilter !== "") {
      return getVisibleDepartment(
        activeFilter as FilterType,
        props.contentBlock,
      );
    }
  }, [activeFilter]);

  useEffect(() => {
    setActiveFilter(
      getActiveFilter() ||
        (props.contentBlock.allDepartmentsFilter as FilterType),
    );
  }, []);

  return (
    <StyledCenter>
      <Spacings.Stack scale="huge">
        {props.contentBlock.allDepartmentsFilter !== "No filters" && (
          <StyledFiltersWrapper>
            {visibleDepartment?.filters.map((filter, filterIndex) => (
              <StyledFilter
                isActive={filter === activeFilter && !isAnimating}
                key={filterIndex}
                tabIndex={-1}
                onClick={() => {
                  setIsAnimating(true);

                  setTimeout(() => {
                    setActiveFilter(filter);
                    setIsAnimating(false);
                  }, 500);
                }}
              >
                {filter}
              </StyledFilter>
            ))}
          </StyledFiltersWrapper>
        )}

        {props.contentBlock.hasFadeEffect ? (
          <PreviewContent visibleDepartment={visibleDepartment} />
        ) : (
          <FullContent
            isAnimating={isAnimating}
            visibleDepartment={visibleDepartment}
          />
        )}

        {props.contentBlock.ctaButton?.buttonLink && (
          <StyledCtaButton
            content={{ ...props.contentBlock.ctaButton }}
            scale="big"
          />
        )}
      </Spacings.Stack>
    </StyledCenter>
  );
};

export const BlockTeamFragment = graphql`
  fragment BlockTeam_ContentfulBlockTeam on ContentfulBlockTeam {
    allDepartmentsFilter
    allDepartmentsRichText {
      json
    }
    departments {
      filter
      aboveImagesRichText {
        json
      }
      employees {
        contentful_id
        name
        jobTitle
        professionalPhoto {
          fluid(maxWidth: 136, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        funPhoto {
          fluid(maxWidth: 136, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      belowImagesRichText {
        json
      }
    }
    hasFadeEffect
    ctaButton {
      ...Button_ContentfulButton
    }
  }
`;

export default BlockTeam;
