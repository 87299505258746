import { BlockTeamProps } from "..";
import { BlockTeam_ContentfulBlockTeamFragment } from "../../../../../types/graphql-types";

export type Filter =
  | "No filters"
  | "Alle Bereiche"
  | "People & Culture"
  | "Finance"
  | "Partner Management & Insurance Services"
  | "Sales & Service"
  | "Marketing"
  | "Product Development"
  | "Geschäftsleitung"
  /**
   * TODO:
   * Remove "Geschäftsführer" once content editors have updated to "Geschäftsleitung"
   * Remove "Geschäftsführer" from filter options in Department content type on Contentful
   */
  | "Geschäftsführer"
  | "Business Development";

type Department = NonNullable<
  BlockTeam_ContentfulBlockTeamFragment["departments"]
>[0];

type Employee = NonNullable<NonNullable<Department>["employees"]>[0];

export type VisibleDepartment = {
  aboveImagesRichText: NonNullable<Department>["aboveImagesRichText"];
  belowImagesRichText: NonNullable<Department>["belowImagesRichText"];
  employees: [Employee?];
  filters: [Filter];
};

/*
 * Durstenfeld shuffle:
 * https://stackoverflow.com/a/12646864/5133172
 */
const shuffleArray = (array: Array<{} | null>) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

export const getVisibleDepartment = (
  activeFilter: Filter,
  content: BlockTeamProps["contentBlock"],
) => {
  const visibleDepartment: VisibleDepartment = {
    aboveImagesRichText: content.allDepartmentsRichText || undefined,
    belowImagesRichText: undefined,
    employees: [],
    filters: [content.allDepartmentsFilter as Filter],
  };

  content.departments?.forEach((department) => {
    if (!department) {
      return null;
    }

    visibleDepartment.filters.push(department.filter as Filter);

    if (department.filter === activeFilter) {
      visibleDepartment.employees = department.employees as [Employee];
      visibleDepartment.aboveImagesRichText = department.aboveImagesRichText;
      visibleDepartment.belowImagesRichText =
        department.belowImagesRichText || undefined;
    } else if (activeFilter === content.allDepartmentsFilter) {
      // Remove duplicate if an employee belongs to more than one department
      const departmentEmployees = department.employees?.filter(
        (departmentEmployee) =>
          !visibleDepartment.employees.find(
            (visibleDepartmentEmployee) =>
              departmentEmployee?.contentful_id ===
              visibleDepartmentEmployee?.contentful_id,
          ),
      );

      visibleDepartment.employees.push(...(departmentEmployees as [Employee]));
    }
  });

  // Show 18 random employees when rendering preview content
  if (content.hasFadeEffect) {
    const shuffledEmployees = shuffleArray(
      visibleDepartment.employees as [Employee],
    );

    const eighteenRandomEmployees = shuffledEmployees.slice(0, 18);
    visibleDepartment.employees = eighteenRandomEmployees as [Employee];
  }

  return visibleDepartment;
};

export const getActiveFilter = () => {
  const filters: { [key: string]: string } = {
    "business-development": "Business Development",
    finance: "Finance",
    // TODO: Remove "Geschäftsführer" once content editors have updated to "Geschäftsleitung"
    geschaftsfuhrer: "Geschäftsführer",
    geschaftsleitung: "Geschäftsleitung",
    marketing: "Marketing",
    "partner-management-insurance-services":
      "Partner Management & Insurance Services",
    "people-culture": "People & Culture",
    "product-development": "Product Development",
    "sales-service": "Sales & Service",
  };

  return filters[window.location.hash.replace("#", "")];
};
