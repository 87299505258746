import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import FormError from "@finanzchef24gmbh/design-system/src/FormError";
import FormField from "@finanzchef24gmbh/design-system/src/FormField";
import FormInput from "@finanzchef24gmbh/design-system/src/FormInput";
import FormLabel from "@finanzchef24gmbh/design-system/src/FormLabel";
import WarningIcon from "@finanzchef24gmbh/design-system/src/Icons/WarningIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { NewsletterRegistrationDialogProps } from ".";
import { trackSuccessfulNewsletterRegistration } from "../../utils/trackingUtils";
import Center from "../Center";
import Markdown from "../Markdown";

const StyledCenter = styled(Center)`
  max-width: 80ch;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: 0 ${(props) => props.theme.spacings.big}
      ${(props) => props.theme.spacings.big};
    flex-direction: row;
  }
`;

const StyledImg = styled.img`
  width: 12rem;
  order: -1;
  margin-bottom: ${(props) => props.theme.spacings.big};
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 15rem;
    order: 1;
    margin-left: ${(props) => props.theme.spacings.huge};
    margin-bottom: 0;
  }
`;

const StyledFormSwitcher = styled(Spacings.Stack)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
    > * + * {
      margin: 0;
    }
  }
`;

const StyledFormField = styled(FormField)`
  flex: 1;
`;

const StyledFormInput = styled(FormInput)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const StyledButton = styled(Button)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: ${(props) => props.theme.borders.radius.medium}px;
    border-bottom-right-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
    border-bottom-left-radius: 0;
  }
`;

const StyledDescription = styled(Markdown)`
  display: none;
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: block;
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  flex-shrink: 0;
`;

export const shouldShowNewsletterRegistration = (
  newsletterRegistration:
    | NewsletterRegistrationDialogProps["form"]
    | null
    | undefined,
) => {
  const isNewsletterRegistrationRequired = !!newsletterRegistration;
  const isRegisteredAlready =
    window.localStorage.getItem(
      `newsletterRegistration-${newsletterRegistration?.formId}`,
    ) !== null;

  return isNewsletterRegistrationRequired && !isRegisteredAlready;
};

const NewsletterTwoGoForm: React.FC<NewsletterRegistrationDialogProps> = ({
  form,
  dialogState,
  downloadLink,
}) => {
  const [invalidRegistrationError, setInvalidRegistrationError] = useState("");
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data: { email: string }) => {
    try {
      const response = await fetch(
        `https://api.newsletter2go.com/forms/submit/${form.formId}`,
        {
          body: JSON.stringify({
            recipient: {
              email: data.email,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        },
      );
      if (!response.ok) {
        setInvalidRegistrationError(
          form.errorDescription?.childMarkdownRemark?.html || "",
        );
      } else {
        window.localStorage.setItem(
          `newsletterRegistration-${form.formId}`,
          "true",
        );
        dialogState.close();
        window.open(downloadLink);
        trackSuccessfulNewsletterRegistration(form.formId);
      }
    } catch (err) {
      setInvalidRegistrationError(
        form.errorDescription?.childMarkdownRemark?.html || "",
      );
    }
  };

  return (
    <StyledCenter intrinsic>
      <Spacings.Stack scale="big">
        <Spacings.Stack scale="huge">
          <Spacings.Stack scale="medium">
            {invalidRegistrationError !== "" && form.errorTitle ? (
              <Text textStyle="headline3">{form.errorTitle}</Text>
            ) : form.title ? (
              <Text textStyle="headline3">{form.title}</Text>
            ) : null}

            {form.description?.childMarkdownRemark?.html ? (
              <StyledDescription
                priority="secondary"
                textStyle="headline4"
                as="p"
                dangerouslySetInnerHTML={{
                  __html: form.description.childMarkdownRemark.html,
                }}
              />
            ) : null}
          </Spacings.Stack>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Spacings.Stack scale="big">
              <Spacings.Stack scale="medium">
                {form.label ? (
                  <label htmlFor="email">
                    <FormLabel textStyle="body">{form.label}</FormLabel>
                  </label>
                ) : null}

                <StyledFormSwitcher>
                  <StyledFormField scale="medium">
                    <StyledFormInput
                      invalid={errors.email ? true : false}
                      id="email"
                      name="email"
                      placeholder={form.inputFieldPlaceholder || undefined}
                      type="email"
                      ref={register({
                        pattern: /^\S+@\S+$/i,
                        required: true,
                      })}
                    />
                  </StyledFormField>
                  <StyledButton type="submit" buttonType="primary">
                    {form.buttonText}
                  </StyledButton>
                </StyledFormSwitcher>

                {errors.email &&
                errors.email.type === "required" &&
                form.invalidEmailError ? (
                  <Spacings.Inline>
                    <StyledWarningIcon scale="tiny" color="error" />
                    <FormError>{form.invalidEmailError}</FormError>
                  </Spacings.Inline>
                ) : null}
              </Spacings.Stack>

              {form.privacyPolicy?.childMarkdownRemark?.html ? (
                <Markdown
                  priority="secondary"
                  dangerouslySetInnerHTML={{
                    __html: form.privacyPolicy.childMarkdownRemark.html,
                  }}
                />
              ) : null}
            </Spacings.Stack>
          </form>
        </Spacings.Stack>

        {invalidRegistrationError !== "" ? (
          <Spacings.Inline>
            <StyledWarningIcon
              scale="tiny"
              color="error"
              data-testid="warning-icon"
            />
            <Markdown
              priority="error"
              dangerouslySetInnerHTML={{
                __html: invalidRegistrationError,
              }}
            />
          </Spacings.Inline>
        ) : null}
      </Spacings.Stack>

      {form.illustration?.file?.url ? (
        <StyledImg src={form.illustration.file.url} alt="" loading="lazy" />
      ) : null}
    </StyledCenter>
  );
};

export default NewsletterTwoGoForm;
