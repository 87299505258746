import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled from "styled-components";

import CheckboxChecked from "./CheckboxChecked.svg";

export const StyledCheckboxChecked = styled(CheckboxChecked)`
  color: inherit;
  background: ${(props) => props.theme.palette.white};
  border-radius: 2px;

  path:first-child {
    visibility: hidden;
  }
`;

export const Container = styled.label`
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
  line-height: 1;
  display: inline-flex;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked + ${/* sc-selector */ StyledCheckboxChecked} {
    color: ${(props) => props.theme.palette.brand.dark};
    background: ${(props) => props.theme.palette.brand.dark};

    path:first-child {
      color: ${(props) => props.theme.palette.white};
      visibility: visible;
    }
  }

  &:focus + ${/* sc-selector */ StyledCheckboxChecked} {
    box-shadow: 0 0 0 0.15em
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }

  &:hover:not(:checked) + ${/* sc-selector */ StyledCheckboxChecked} {
    background: ${(props) => props.theme.palette.gray[50]};
  }
`;

type CheckboxProps = Omit<
  JSX.IntrinsicElements["input"],
  "type" | "className" | "style"
> &
  Pick<JSX.IntrinsicElements["label"], "className" | "style">;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, style, ...inputProps }, ref) => (
    <Container className={className} style={style}>
      <Input type="checkbox" {...inputProps} ref={ref} />
      <StyledCheckboxChecked />
    </Container>
  ),
);

export default Checkbox;
