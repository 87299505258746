import transparentize from "polished/lib/color/transparentize";
import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";

import CloseIcon from "@finanzchef24gmbh/design-system/src/Icons/CloseIcon";
import PhoneCircleIcon from "@finanzchef24gmbh/design-system/src/Icons/PhoneCircleIcon";
import PhoneIcon from "@finanzchef24gmbh/design-system/src/Icons/PhoneIcon";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { StackProps } from "@finanzchef24gmbh/design-system/src/Stack";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { trackMainNavigationLink } from "../../utils/trackingUtils";
import Button from "../Button";
import Responsive from "../Responsive";

type HasFourthDropdown = { hasFourthDropdown: boolean };

const StyledPhonePopup = styled.div<HasFourthDropdown>`
  position: absolute;
  top: ${(props) => props.theme.spacings.huge};
  left: calc(50% - ${(props) => props.theme.spacings.big});
  transform: translateX(-50%);
  width: 18.75rem;

  &::before {
    content: " ";
    display: inline-block;
    height: ${(props) => props.theme.spacings.medium};
    width: ${(props) => props.theme.spacings.medium};
    background: ${(props) => props.theme.palette.white};
    transform: rotate(45deg);
    position: absolute;
    box-shadow:
      -3px 0 2px -2px ${(props) => transparentize(0.9, props.theme.palette.black)},
      0px -3px 2px -2px
        ${(props) => transparentize(0.9, props.theme.palette.black)};
    top: ${(props) => props.theme.spacings.medium};
    left: calc(50% + ${(props) => props.theme.spacings.medium});
  }

  @media (min-width: 22.5em) {
    width: 19.75rem;
    left: calc(50% - ${(props) => props.theme.spacings.huge});

    &::before {
      left: calc(50% + ${(props) => props.theme.spacings.big});
    }
  }

  @media (min-width: 25em) {
    left: calc(50% - ${(props) => props.theme.spacings.gigantic});

    &::before {
      left: calc(
        50% + ${(props) => props.theme.spacings.huge} +
          ${(props) => props.theme.spacings.big}
      );
    }
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    top: ${(props) => props.theme.spacings.medium};
    visibility: hidden;

    &::before {
      left: calc(50% - ${(props) => props.theme.spacings.small});
    }
  }

  @media (min-width: ${(props) =>
      props.hasFourthDropdown ? "93.75em" : "80em"}) {
    left: -50%;
    transform: none;
  }
`;

const StyledMobileResponsive = styled(Responsive)`
  position: relative;
`;

const StyledMobileInset = styled(Spacings.Inset)`
  position: relative;
  z-index: 1;
`;

const StyledCloseIconWrapper = styled(Responsive)`
  align-self: flex-end;
`;

const StyledDesktopResponsive = styled.div<HasFourthDropdown>`
  cursor: default;
  position: relative;
  display: none;

  &:hover ${StyledPhonePopup} {
    visibility: visible;
    z-index: 2;
  }

  &:focus-within ${StyledPhonePopup} {
    visibility: visible;
    z-index: 1;
  }

  @media (min-width: ${(props) =>
      props.hasFourthDropdown ? "81em" : props.theme.layout.tabletBreakpoint}) {
    display: block;
  }
`;

const StyledLayerOverlay = styled(Layer.Overlay)`
  padding: 0 0 ${(props) => props.theme.spacings.gigantic};
  margin-top: ${(props) => props.theme.spacings.big};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding: ${(props) => props.theme.spacings.medium} 0
      ${(props) => props.theme.spacings.big};
  }
`;

const StyledLink = styled(Link)`
  font-feature-settings: "tnum" off;
  text-decoration: none;

  span {
    text-decoration: none;
  }

  @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    text-decoration: underline;

    span {
      color: inherit;
    }
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    margin-top: ${(props) => props.theme.spacings.huge};
  }
`;

const phoneNumber = "089 716 772 999";
const phoneNumberLink = `tel:${phoneNumber.replace(/ /gi, "")}`;
const callBackLink = `${process.env.GATSBY_ORIGIN}/service/callback`;

type PhonePopupProps = {
  closePhonePopup?: Dispatch<SetStateAction<boolean>>;
  hasFourthDropdown?: boolean;
};

const PhonePopup: React.FC<PhonePopupProps> = ({
  closePhonePopup,
  hasFourthDropdown,
}) => (
  <StyledPhonePopup hasFourthDropdown={hasFourthDropdown || false}>
    <StyledLayerOverlay>
      <Spacings.Stack scale="medium" alignItems="center">
        <StyledCloseIconWrapper hideOn={["desktop"]}>
          <Spacings.Inset scale="medium">
            <CloseIcon
              onClick={() => closePhonePopup && closePhonePopup(false)}
            />
          </Spacings.Inset>
        </StyledCloseIconWrapper>

        <Text priority="secondary">Kostenlose Expertenberatung:</Text>
        <StyledLink
          href={phoneNumberLink}
          aria-label={`${phoneNumber} anrufen`}
          onClick={() => trackMainNavigationLink(phoneNumberLink)}
        >
          <Text textStyle="headline3" as="span">
            {phoneNumber}
          </Text>
        </StyledLink>
        <Text>Mo - Fr von 8 - 17 Uhr</Text>

        <StyledButton
          content={{
            buttonIcon: "PhoneCallbackIcon",
            buttonIconPosition: "left",
            buttonLink: callBackLink,
            buttonText: "Rückruf vereinbaren",
            buttonType: "flat",
          }}
          scale="big"
          trackLink={() => trackMainNavigationLink(callBackLink)}
        />
      </Spacings.Stack>
    </StyledLayerOverlay>
  </StyledPhonePopup>
);

type PhoneNumberProps = {
  alignItems?: StackProps["alignItems"];
  isOnDarkBackground?: boolean;
} & HasFourthDropdown;

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  hasFourthDropdown,
  isOnDarkBackground,
}) => {
  const [showMobilePhonePopup, setShowMobilePhonePopup] = useState(false);

  return (
    <React.Fragment>
      <StyledMobileResponsive hideOn={["desktop"]}>
        <StyledMobileInset
          onClick={() => setShowMobilePhonePopup(!showMobilePhonePopup)}
        >
          <PhoneCircleIcon
            scale="huge"
            isOnDarkBackground={isOnDarkBackground}
          />
        </StyledMobileInset>

        {showMobilePhonePopup && (
          <PhonePopup closePhonePopup={setShowMobilePhonePopup} />
        )}
      </StyledMobileResponsive>

      <StyledDesktopResponsive hasFourthDropdown={hasFourthDropdown}>
        <Spacings.Inline alignItems="center">
          <PhoneIcon isOnDarkBackground={isOnDarkBackground} />
          <Text as="span" isOnDarkBackground={isOnDarkBackground}>
            {phoneNumber}
          </Text>
        </Spacings.Inline>

        <PhonePopup hasFourthDropdown={hasFourthDropdown} />
      </StyledDesktopResponsive>
    </React.Fragment>
  );
};

export default PhoneNumber;
