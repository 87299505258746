import React from "react";
import styled from "styled-components";

import { trackVideoPlayer } from "../utils/trackingUtils";

const POSTER = {
  FIT: "thumb",
  FORMAT: "jpg",
  WIDTH: 640,
};

const VIDEO = {
  PRELOAD: "metadata",
};

const Video = styled.video`
  cursor: pointer;
  max-width: ${(props) => props.theme.layout.containerWidth};
  width: 100%;
`;

type VideoProps = {
  src?: string;
  poster?: string;
};

const VideoPlayer: React.FC<VideoProps> = ({ src, poster }) => (
  <Video
    controls
    preload={VIDEO.PRELOAD}
    src={src}
    poster={
      poster
        ? `${poster}?w=${POSTER.WIDTH}&fit=${POSTER.FIT}&fm=${POSTER.FORMAT}`
        : undefined
    }
    onPlay={() => trackVideoPlayer(src)}
  />
);
export default VideoPlayer;
