import styled from "styled-components";

const Responsive = styled.div<{
  hideOn: Array<"mobile" | "tablet" | "desktop">;
}>`
  display: ${(props) => (props.hideOn.includes("mobile") ? "none" : "block")};
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: ${(props) => (props.hideOn.includes("tablet") ? "none" : "block")};
  }
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: ${(props) =>
      props.hideOn.includes("desktop") ? "none" : "block"};
  }
`;

export default Responsive;
