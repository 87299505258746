import { graphql, useStaticQuery } from "gatsby";
import Img, { FixedObject } from "gatsby-image";
import React, { useEffect } from "react";
import styled from "styled-components";

import Card from "@finanzchef24gmbh/design-system/src/Card";
import StarIcon from "@finanzchef24gmbh/design-system/src/Icons/svg/star.svg";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { KununuInfoQuery, Maybe } from "../../../../../types/graphql-types";
import { formatNumber } from "../../../../utils";
import Button from "../../../Button";
import Center from "../../../Center";
import Rating from "../../../Rating";
import SwitchInlineStack from "../../../SwitchInlineStack";
import Score from "../BlockCustomerReviews/Score";
import content from "./content";

const StyledBlockWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.gray[25]};
  border-top: 1px solid ${(props) => props.theme.palette.gray[100]};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray[100]};
  padding: ${(props) => props.theme.spacings.gigantic} 0;
`;

const StyledEmployeeReviewsHeading = styled(Text)`
  text-align: center;
`;

const StyledCard = styled(Card)`
  max-width: 26rem;

  @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    order: -1;
    margin-top: 0;
    margin-bottom: ${(props) => props.theme.spacings.huge};
  }
`;

const StyledCardHeading = styled(Text)`
  text-align: center;
`;

const StyledRatingSwitchInlineStack = styled(SwitchInlineStack)`
  flex-direction: column-reverse;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
  }
`;

const StyledSlash = styled.span`
  font-size: 2.2em;
  color: ${(props) => props.theme.palette.gray[400]};
  font-weight: ${(props) => props.theme.typography.weights.regular};
  margin: 0 0 ${(props) => props.theme.spacings.small} 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: 3.2em;
  }
`;

type BlockEmployeeReviewsProps = {
  useEnglishContent: boolean;
};

const BlockEmployeeReviews: React.FC<BlockEmployeeReviewsProps> = ({
  useEnglishContent,
}) => {
  const contentBlock = useEnglishContent ? content.en : content.de;

  const data = useStaticQuery<Maybe<KununuInfoQuery>>(graphql`
    query KununuInfo {
      kununuInfo {
        rating
        reviewsCount
      }
      kununuTopCompanyBadge: contentfulAsset(
        contentful_id: { eq: "6lzZu0xTABnM9Ot2bIesl3" }
      ) {
        description
        title
        fixed(width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      kununuOpenCompanyBadge: contentfulAsset(
        contentful_id: { eq: "2mHocdtQSgOMpPRpo1j122" }
      ) {
        description
        title
        fixed(width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  `);

  useEffect(() => {
    if (!data?.kununuInfo?.rating) {
      // eslint-disable-next-line no-console
      console.error(
        new Error(`Kununu rating is missing: ${JSON.stringify(data)}`),
      );
    }
    if (!data?.kununuInfo?.reviewsCount) {
      // eslint-disable-next-line no-console
      console.error(
        new Error(
          `Number of Kununu reviews is missing: ${JSON.stringify(data)}`,
        ),
      );
    }
  }, []);

  const rating = data?.kununuInfo?.rating
    ? Math.round(data.kununuInfo.rating * 10) / 10
    : 4.1;

  const reviewsCount = data?.kununuInfo?.reviewsCount || 53;

  return (
    <StyledBlockWrapper>
      <Center>
        <Spacings.Inset scale="medium">
          <Spacings.Stack alignItems="center" scale="huge">
            {contentBlock.heading && (
              <StyledEmployeeReviewsHeading
                textStyle="headline4"
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: contentBlock.heading,
                }}
              />
            )}
            <SwitchInlineStack
              breakOn="tabletBreakpoint"
              inlineScale="gigantic"
              stackScale="big"
              alignItems="center"
            >
              {data?.kununuTopCompanyBadge && (
                <Img
                  fixed={data.kununuTopCompanyBadge.fixed as FixedObject}
                  alt={
                    data.kununuTopCompanyBadge.description ||
                    data.kununuTopCompanyBadge.title ||
                    undefined
                  }
                />
              )}
              <StyledCard>
                <Spacings.Inset scale="big">
                  <Spacings.Stack scale="big" alignItems="center">
                    {contentBlock.cardHeading && (
                      <StyledCardHeading textStyle="headline5" as="h3">
                        {contentBlock.cardHeading}
                      </StyledCardHeading>
                    )}
                    <StyledRatingSwitchInlineStack
                      breakOn="mobileBreakpoint"
                      inlineScale="huge"
                    >
                      <Spacings.Stack scale="medium">
                        <Rating
                          icon={StarIcon}
                          score={(rating / 5) * 100}
                          iconScale="huge"
                          spacingScale="tiny"
                        />
                        <Text textStyle="caption" priority="secondary">
                          bei {formatNumber(reviewsCount)} Bewertungen
                        </Text>
                      </Spacings.Stack>
                      <Spacings.Inline>
                        <Score>{rating}</Score>
                        <StyledSlash>/</StyledSlash>
                        <Score isMaxScore>5</Score>
                      </Spacings.Inline>
                    </StyledRatingSwitchInlineStack>
                    {contentBlock.button?.buttonLink && (
                      <Button content={{ ...contentBlock.button }} />
                    )}
                  </Spacings.Stack>
                </Spacings.Inset>
              </StyledCard>
              {data?.kununuOpenCompanyBadge && (
                <Img
                  fixed={data.kununuOpenCompanyBadge.fixed as FixedObject}
                  alt={
                    data.kununuOpenCompanyBadge.description ||
                    data.kununuOpenCompanyBadge.title ||
                    undefined
                  }
                />
              )}
            </SwitchInlineStack>
          </Spacings.Stack>
        </Spacings.Inset>
      </Center>
    </StyledBlockWrapper>
  );
};

export default BlockEmployeeReviews;
