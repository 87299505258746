import { graphql } from "gatsby";
import React from "react";

import { BlockHighlights_ContentfulBlockHighlightsFragment } from "../../../../types/graphql-types";
import Center from "../../Center";
import Horizontal from "./Horizontal";
import Vertical from "./Vertical";

export type HighlightsProps = {
  contentBlock: BlockHighlights_ContentfulBlockHighlightsFragment;
};

type HighlightLayout = "horizontal" | "vertical";

const isValidLayout = (layout: string): layout is HighlightLayout =>
  ["horizontal", "vertical"].includes(layout);

const Highlights: React.FC<HighlightsProps> & {
  vertical: React.FC<HighlightsProps>;
  horizontal: React.FC<HighlightsProps>;
} = (props) => {
  if (!props.contentBlock.layout || !isValidLayout(props.contentBlock.layout)) {
    return null;
  }
  const HighlightsComponent = Highlights[props.contentBlock.layout];
  return (
    <Center>
      <HighlightsComponent {...props} />
    </Center>
  );
};

Highlights.vertical = (props) => <Vertical {...props} />;
Highlights.horizontal = (props) => <Horizontal {...props} />;

export const blockHighlightsFragment = graphql`
  fragment BlockHighlights_ContentfulBlockHighlights on ContentfulBlockHighlights {
    id
    type: __typename
    title
    layout
    showListOfInsurancePartners
    highlightImageSize
    firstHighlightTitle
    firstHighlightDescription {
      childMarkdownRemark {
        html
      }
    }
    overrideFirstDefaultExternalLinkAttributes
    firstHighlightImage {
      title
      description
      file {
        url
      }
      fluid(maxWidth: 360) {
        ...GatsbyContentfulFluid_withWebp
        src
      }
    }
    secondHighlightTitle
    secondHighlightDescription {
      childMarkdownRemark {
        html
      }
    }
    overrideSecondDefaultExternalLinkAttributes
    secondHighlightImage {
      title
      description
      file {
        url
      }
      fluid(maxWidth: 360) {
        ...GatsbyContentfulFluid_withWebp
        src
      }
    }
    thirdHighlightTitle
    thirdHighlightDescription {
      childMarkdownRemark {
        html
      }
    }
    overrideThirdDefaultExternalLinkAttributes
    thirdHighlightImage {
      title
      description
      file {
        url
      }
      fluid(maxWidth: 360) {
        ...GatsbyContentfulFluid_withWebp
        src
      }
    }
    ctaButton {
      buttonText
      buttonLink
    }
    isCardView
  }
`;

export default Highlights;
