import React, { useContext } from "react";
import styled from "styled-components";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Fc24Breadcrumbs from "@finanzchef24gmbh/design-system/src/Breadcrumbs";
import { ResponsiveContainerProps } from "@finanzchef24gmbh/design-system/src/ResponsiveContainer";

import Center from "../../Center";
import PageContext from "../../../context/PageContext";

const StyledCenter = styled(Center)`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    overflow-x: scroll;
  }
`;

const StyledBreadCrumbContainer = styled.div`
  padding-bottom: 1.5rem;
`;

const Breadcrumbs = () => {
  const pageContext = useContext(PageContext);

  if (pageContext?.crumbs === undefined || pageContext.crumbs.length === 0)
    return null;

  // due to Contentful's Maybe type we need to check if the values are available
  function labelAndSlugAreAvailable(
    object: any,
  ): object is { label: string; slug: string } {
    return (
      object &&
      typeof object.label === "string" &&
      typeof object.slug === "string"
    );
  }

  const crumbsWithoutHomePage = pageContext.crumbs.filter(
    ({ slug }) => slug !== "/",
  );
  const validCrumbs = crumbsWithoutHomePage.filter(labelAndSlugAreAvailable);

  const root = process.env.GATSBY_ORIGIN || "/";

  const links = [
    {
      label: "Startseite",
      slug: root,
      showOn: ["tablet", "desktop"] as ResponsiveContainerProps["showOn"],
    },
    ...validCrumbs.map((crumb) => ({
      ...crumb,
      slug: `${root}/${crumb.slug}`,
    })),
  ];

  return (
    <StyledBreadCrumbContainer>
      <StyledCenter>
        <Spacings.Inset scale="big">
          <Fc24Breadcrumbs crumbs={links} />
        </Spacings.Inset>
      </StyledCenter>
    </StyledBreadCrumbContainer>
  );
};

export default Breadcrumbs;
