import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { CardsProps } from ".";
import { trackCardLink } from "../../../utils/trackingUtils";
import Image from "../../Image";
import RelativeLink from "../../RelativeLink";
import {
  StyledCard,
  StyledCardImage,
  StyledContainerLink,
  StyledLinkText,
} from "./SharedStyles";

const StyledSpacingsInset = styled(Spacings.Inset)`
  margin-left: -${(props) => props.theme.spacings.medium};
  margin-right: -${(props) => props.theme.spacings.medium};
  overflow-x: auto;
`;

const StyledMiniCardContainerLink = styled(StyledContainerLink)`
  :last-of-type {
    padding-right: ${(props) => props.theme.spacings.medium};
  }
`;

const StyledMiniCard = styled(StyledCard)`
  width: 13.3125rem;
`;

const StyledMiniCardImage = styled(StyledCardImage)`
  height: 7.5rem;
`;

const MiniCards: React.FC<CardsProps> = ({ cards }) => (
  <StyledSpacingsInset scale="medium">
    <Spacings.Inline scale="medium">
      {cards?.map((card, index) => {
        if (!card) {
          return null;
        }

        return (
          <StyledMiniCardContainerLink
            key={index}
            href={card.linkUrl || undefined}
            onClick={() => trackCardLink("categories", card.linkUrl)}
          >
            <StyledMiniCard isHighlighted={false} isInteractive>
              {(card.image?.fluid || card.image?.file?.url) && (
                <StyledMiniCardImage>
                  <Image asset={card.image} loading="lazy" />
                </StyledMiniCardImage>
              )}

              <Spacings.Inset scale="medium">
                <Spacings.Stack scale="medium">
                  {card.title && (
                    <Text
                      textStyle="headline5"
                      as="span"
                      dangerouslySetInnerHTML={{
                        __html: card.title,
                      }}
                    />
                  )}
                  {card.linkText && card.linkUrl && (
                    <RelativeLink href={card.linkUrl}>
                      <StyledLinkText textStyle="bodyProlonged">
                        {card.linkText}
                      </StyledLinkText>
                    </RelativeLink>
                  )}
                </Spacings.Stack>
              </Spacings.Inset>
            </StyledMiniCard>
          </StyledMiniCardContainerLink>
        );
      })}
    </Spacings.Inline>
  </StyledSpacingsInset>
);

export default MiniCards;
