import { graphql } from "gatsby";
import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";

import Badge from "@finanzchef24gmbh/design-system/src/Badge";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, {
  fontSizeByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { BlockBlogs_ContentfulBlockBlogsFragment } from "../../../types/graphql-types";
import { trackCardLink } from "../../utils/trackingUtils";
import Center from "../Center";
import RelativeLink from "../RelativeLink";
import Responsive from "../Responsive";
import RichText from "../RichText";

const StyledCenter = styled(Center)<{ isSingleColumn: boolean }>`
  max-width: ${(props) =>
    props.isSingleColumn ? "60ch" : props.theme.layout.articleWidth};
`;

const CardsGridContainer = styled.div<{ gridColumns: number }>`
  display: grid;
  gap: ${(props) => props.theme.spacings.huge}
    ${(props) => props.theme.spacings.medium};

  ${(props) =>
    props.gridColumns > 1 &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        grid-template-columns: repeat(${props.gridColumns}, 1fr);
      }
    `}
`;

const StyledCard = styled.div<{
  gridColumns: number;
  hasBigThumbnail: boolean;
}>`
  position: relative;

  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        grid-column: 1 / ${props.gridColumns + 1};
        display: grid;
        grid-template-columns: repeat(${props.gridColumns}, 1fr);
        column-gap: ${props.theme.spacings.medium};

        > a {
          grid-column: 1 / ${props.gridColumns};
        }
      }
    `}
`;

const StyledBadgeWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledImg = styled(Img)<
  GatsbyImageFluidProps & { hasBigThumbnail: boolean }
>`
  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        height: 100%;
      }
    `}

  img {
    border-radius: ${(props) => props.theme.borders.radius.medium}px;
  }
`;

const StyledCategory = styled(Text)<{ hasBigThumbnail: boolean }>`
  color: ${(props) => props.theme.palette.mint[600]};
  margin-top: ${(props) => props.theme.spacings.tiny};

  ${(props) =>
    props.hasBigThumbnail &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        margin-top: 0;
      }
    `}
`;

const StyledRelativeLink = styled(RelativeLink)`
  text-decoration: none;

  span {
    text-decoration: none;
  }
`;

const StyledHeading = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
  line-height: 1.1;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline4}rem;
  }
`;

type BlockBlogsProps = {
  contentBlock: BlockBlogs_ContentfulBlockBlogsFragment;
  latestDynamicBlogs?: {
    [key: string]: BlockBlogs_ContentfulBlockBlogsFragment["blogCards"];
  };
};

const BlockBlogs: React.FC<BlockBlogsProps> = (props) => {
  const blogCards =
    props.contentBlock.blogCards ||
    (props.contentBlock.contentful_id &&
      props.latestDynamicBlogs?.[props.contentBlock.contentful_id]);

  const cardLayoutToNumbersArray = props.contentBlock?.cardLayout
    ?.split("-")
    .map(Number) || [1];

  // set grid columns based on highest number of columns in cardLayout
  const gridColumns = Math.max(...cardLayoutToNumbersArray);
  // the first card always has a big thumbnail unless cardLayout 3-1-3 is used
  const bigThumbnailIndex =
    cardLayoutToNumbersArray[0] > 1 ? cardLayoutToNumbersArray[0] : 0;

  return (
    <StyledCenter isSingleColumn={gridColumns === 1}>
      {blogCards && (
        <CardsGridContainer gridColumns={gridColumns}>
          {blogCards.map((card, index) => {
            if (!card) {
              return null;
            }

            const heading = card.contentBlocks?.[0]?.heading;
            const hasBigThumbnail =
              gridColumns === 1 || index === bigThumbnailIndex;

            // Gründerstories uses a pre-existing drupal slug that does not match the category label
            const categorySlug = card.category
              ?.toLowerCase()
              .replace("gründerstories", "gruenderstories");

            return (
              <StyledCard
                key={index}
                hasBigThumbnail={hasBigThumbnail}
                gridColumns={gridColumns}
              >
                {props.contentBlock.showBadges && card.badgeText && (
                  <StyledBadgeWrapper>
                    <Badge color="accent">
                      <Text
                        as="div"
                        dangerouslySetInnerHTML={{
                          __html: card.badgeText,
                        }}
                        isOnDarkBackground
                      />
                    </Badge>
                  </StyledBadgeWrapper>
                )}

                {card.pageLink?.slug && (
                  <RelativeLink
                    href={card.pageLink?.slug}
                    onClick={() => trackCardLink("blogs", card.pageLink?.slug)}
                  >
                    {card.previewImage?.fluid && (
                      <StyledImg
                        fluid={card.previewImage.fluid as FluidObject}
                        alt={heading || ""}
                        hasBigThumbnail={hasBigThumbnail}
                      />
                    )}
                  </RelativeLink>
                )}

                <Spacings.Stack scale="small">
                  {card.category && (
                    <StyledRelativeLink
                      href={`/blog/${categorySlug}`}
                      onClick={() =>
                        trackCardLink("blogs", `/blog/${categorySlug}`)
                      }
                    >
                      <StyledCategory
                        hasBigThumbnail={hasBigThumbnail}
                        isStrong
                        isUncropped
                      >
                        {card.category}
                      </StyledCategory>
                    </StyledRelativeLink>
                  )}

                  <Spacings.Stack scale="big">
                    {heading && card.pageLink?.slug && (
                      <StyledRelativeLink
                        href={`/${card.pageLink?.slug}`}
                        onClick={() =>
                          trackCardLink("blogs", card.pageLink?.slug)
                        }
                      >
                        <StyledHeading
                          textStyle={
                            hasBigThumbnail ? "headline3" : "headline5"
                          }
                          as="span"
                          dangerouslySetInnerHTML={{
                            __html: heading,
                          }}
                        />
                      </StyledRelativeLink>
                    )}

                    {card.richTextSummary?.json &&
                      (gridColumns === 1 ? (
                        <RichText document={card.richTextSummary.json} />
                      ) : (
                        <Responsive
                          hideOn={
                            hasBigThumbnail
                              ? ["mobile"]
                              : ["mobile", "tablet", "desktop"]
                          }
                        >
                          <RichText document={card.richTextSummary.json} />
                        </Responsive>
                      ))}
                  </Spacings.Stack>
                </Spacings.Stack>
              </StyledCard>
            );
          })}
        </CardsGridContainer>
      )}
    </StyledCenter>
  );
};

export const BlockBlogsFragment = graphql`
  fragment BlockBlogs_ContentfulBlockBlogs on ContentfulBlockBlogs {
    contentful_id
    cardLayout
    showBadges
    blogCards: cards {
      __typename
      ... on ContentfulBasicLandingPage {
        contentBlocks {
          ... on ContentfulBlockBlogHero {
            heading
          }
        }
        previewImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        badgeText
        pageLink {
          slug
        }
        category
        richTextSummary {
          json
        }
      }
    }
  }
`;

export default BlockBlogs;
