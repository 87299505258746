/* eslint-disable sort-keys */
const constants = {
  BLOCK_HERO: {
    ctaCard: {
      width: "40em",
    },
  },
};

export default constants;
