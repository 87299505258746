import { graphql } from "gatsby";
import React from "react";

import {
  BlockTableOfContents_ContentBlocksFragmentFragment,
  BlockTableOfContents_ContentfulBlockTableOfContentsFragment,
} from "../../../../types/graphql-types";
import List from "./List";
import StickyBar from "./StickyBar";

export type BlockTableOfContentsProps = {
  className: string;
  contentBlock: BlockTableOfContents_ContentfulBlockTableOfContentsFragment;
  contentBlocks: Array<BlockTableOfContents_ContentBlocksFragmentFragment | null>;
};

type Layout = "StickyBar" | "List";

const BlockTableOfContents: React.FC<BlockTableOfContentsProps> & {
  StickyBar: React.FC<BlockTableOfContentsProps>;
  List: React.FC<BlockTableOfContentsProps>;
} = (props) => {
  const layout = (props.contentBlock.layout as Layout) || "List";

  const TableOfContentsComponent = BlockTableOfContents[layout];

  return <TableOfContentsComponent {...props} />;
};

BlockTableOfContents.StickyBar = (props) => <StickyBar {...props} />;
BlockTableOfContents.List = (props) => <List {...props} />;

export const blockTableOfContentsFragment = graphql`
  fragment BlockTableOfContents_ContentfulBlockTableOfContents on ContentfulBlockTableOfContents {
    id
    title
    type: __typename
    removeWholeBlockFromGoogleSnippet
    layout
    button {
      ...Button_ContentfulButton
    }
  }
  fragment BlockTableOfContents_ContentBlocksFragment on Node {
    id
    type: __typename
    ... on ContentfulBlockMediaItemListsCard {
      includeInTableOfContents
      isTableOfContentsSubItem
      slug
      title
      tableOfContentsLabel
    }
    ... on ContentfulBlockParagraph {
      includeInTableOfContents
      isTableOfContentsSubItem
      slug
      title
      tableOfContentsLabel
    }
    ... on ContentfulBlockDownloads {
      includeInTableOfContents
      isTableOfContentsSubItem
      slug
      title
      tableOfContentsLabel
    }
    ... on ContentfulBlockFaq {
      includeInTableOfContents
      isTableOfContentsSubItem
      slug
      title
      tableOfContentsLabel
    }
  }
`;

export default BlockTableOfContents;
