import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import { LogoQuery, Maybe } from "../../../types/graphql-types";

type LogoImgPros = {
  hideOnDesktop?: boolean;
};

const StyledWrapper = styled.span`
  display: flex;
`;

const LogoImg = styled.img<LogoImgPros>`
  display: inline-block;

  ${(props) =>
    props.hideOnDesktop &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        display: none;
      }
    `}
`;

type LogoWithSloganImgPros = {
  showOnDesktop?: boolean;
};

const LogoWithSloganImg = styled.img<LogoWithSloganImgPros>`
  display: none;

  ${(props) =>
    props.showOnDesktop &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        display: inline-block;
      }
    `}
`;

type LogoProps = {
  isOnDarkBackground?: boolean;
  showSloganOnDesktop?: boolean;
};

const Logo: React.FC<LogoProps> = ({
  isOnDarkBackground,
  showSloganOnDesktop,
}) => {
  const data = useStaticQuery<Maybe<LogoQuery>>(graphql`
    query Logo {
      brandLogoWhite: contentfulAsset(
        contentful_id: { eq: "32pWfzpLB06DEjpIqrmj7p" }
      ) {
        file {
          url
        }
      }
      brandLogoColored: contentfulAsset(
        contentful_id: { eq: "3Sc0seQHUrzAu6pcck1Myz" }
      ) {
        file {
          url
        }
      }
      brandLogoSloganWhite: contentfulAsset(
        contentful_id: { eq: "JxX1oCG1YcfLKvMaqbMxz" }
      ) {
        file {
          url
        }
      }
      brandLogoSloganColored: contentfulAsset(
        contentful_id: { eq: "2Xzb7oYROFRXUwOTNnfPHg" }
      ) {
        file {
          url
        }
      }
    }
  `);
  if (!data) {
    return null;
  }
  return (
    <StyledWrapper>
      <LogoImg
        hideOnDesktop={showSloganOnDesktop}
        src={
          (isOnDarkBackground
            ? data.brandLogoWhite?.file?.url
            : data.brandLogoColored?.file?.url) || undefined
        }
        width={120}
        height={45}
        alt="Finanzchef24 GmbH"
        loading="eager"
      />
      <LogoWithSloganImg
        showOnDesktop={showSloganOnDesktop}
        src={
          (isOnDarkBackground
            ? data.brandLogoSloganWhite?.file?.url
            : data.brandLogoSloganColored?.file?.url) || undefined
        }
        width={325}
        height={45}
        alt="Finanzchef24 GmbH Gewerbe einfach gut versichert"
        loading="eager"
      />
    </StyledWrapper>
  );
};

export default Logo;
