export const COOKIES = {
  GENERAL_DESCRIPTION:
    "Wir verwenden verschiedene Arten von Cookies, um Ihnen den bestmöglichen Service zu bieten. Details und Widerspruchsmöglichkeiten finden Sie in unseren",
  GENERAL_HEADLINE: "Wir verwenden Cookies",
  GENERAL_OVERVIEW: "Eine Übersicht aller Cookies finden Sie",
  LEVELS: {
    FUNCTIONALITY: {
      DESCRIPTION:
        "Durch diese Cookies kann unsere Website sicherstellen, dass all unsere Dienste ordnungsgemäß funktionieren. Diese Cookies können von uns oder von Websites Dritter gesetzt werden.",
      HEADLINE: "Erweiterte Funktionalität",
    },
    STRICTLY_NECESSARY: {
      DESCRIPTION:
        "Diese Cookies sind für die Nutzung dieser Website und ihrer Funktionen unbedingt erforderlich, z. B. für das Festlegen Ihrer Datenschutzeinstellungen oder für das Ausfüllen von Formularen und können daher nicht deaktiviert werden.",
      HEADLINE: "Unbedingt erforderliche Cookies",
    },
    TARGETING: {
      DESCRIPTION:
        "Durch solche Cookies können unsere und andere Websiten Ihnen zielgerichtet für Sie relevante Inhalte und Anzeigen anbieten.",
      HEADLINE: "Targeting",
    },
    TRACKING: {
      DESCRIPTION:
        "Diese Cookies erlauben es uns, durch sammeln von nicht-personenbezogenen Nutzerstatistiken unsere Website besser auf Ihre Bedürfnisse anzupassen",
      HEADLINE: "Tracking",
    },
  },
  OPTIONS_DESCRIPTION:
    "Cookies können Ihr Nutzererlebnis auf unserer Website verbessern, da diese etwa Informationen zu Ihren bevorzugten Einstellungen oder Ihrem Endgerät hinterlegen. Weil wir Ihre Privatsphäre respektieren, können Sie die Verwendung bestimmter Cookies ablehnen. Bitte beachten Sie, dass sich dies auf Ihr Nutzerelebnis auf dieser Website auswirken kann.",
  OPTIONS_HEADLINE: "Cookie-Einstellung verwalten",
};

export const COOKIE_TABLE_CONTENTFUL =
  "https://assets.ctfassets.net/ta73me28xrs3/7EaPEZQvcHcBTag50fgk6b/51be6a6d553a796a0033dd7a4e12bdb9/Datenschutz-Cookie-Tabelle.pdf";
