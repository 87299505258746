const content = {
  de: {
    button: {
      buttonLink: "https://www.kununu.com/de/finanzchef24",
      buttonText: "Kununu-Bewertungen lesen",
      buttonType: "flat",
      isNofollowLink: true,
      openLinkInNewWindow: true,
    },
    cardHeading: "82% empfehlen Finanzchef24 weiter!",
    heading: "Das sagen unsere Mitarbeiter und Mitarbeiterinnen",
  },
  en: {
    button: {
      buttonLink: "https://www.kununu.com/de/finanzchef24",
      buttonText: "Read Kununu-Reviews",
      buttonType: "flat",
      isNofollowLink: true,
      openLinkInNewWindow: true,
    },
    cardHeading: "82% recommend Finanzchef24!",
    heading: "What our employees have to say",
  },
};

export default content;
