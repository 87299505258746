import styled, { css } from "styled-components";

import { Theme } from "../createHomePageTheme";

const SwitchInlineStack = styled.div<{
  breakOn: keyof Theme["layout"];
  stackScale?: keyof Theme["spacings"];
  inlineScale?: keyof Theme["spacings"];
  alignItems?: string;
  switchColumnOrder?: boolean;
}>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "stretch")};
  flex-direction: column;

  ${(props) =>
    props.switchColumnOrder
      ? css`
          > * {
            margin: ${props.stackScale
                ? props.theme.spacings[props.stackScale]
                : props.theme.spacings.small}
              0 0;
          }

          > * + * {
            order: -1;
            margin: 0;
          }
        `
      : css`
          > * + * {
            margin: ${props.stackScale
                ? props.theme.spacings[props.stackScale]
                : props.theme.spacings.small}
              0 0;
          }
        `};

  && {
    @media (min-width: ${(props) => props.theme.layout[props.breakOn]}) {
      flex-direction: row;

      ${(props) =>
        props.switchColumnOrder &&
        css`
          > * {
            margin: 0;
          }

          > * + * {
            order: 0;
          }
        `};

      > * + * {
        margin: 0 0 0
          ${(props) =>
            props.inlineScale
              ? props.theme.spacings[props.inlineScale]
              : props.theme.spacings.small};
      }
    }
  }
`;

export default SwitchInlineStack;
