import styled from "styled-components";

import Card from "@finanzchef24gmbh/design-system/src/Card";
import Text from "@finanzchef24gmbh/design-system/src/Text";

export const StyledCard = styled(Card)`
  border: 0;
  cursor: pointer;
`;

export const StyledCardImage = styled.div`
  width: 100%;
  border-radius: ${(props) => props.theme.borders.radius.medium}px
    ${(props) => props.theme.borders.radius.medium}px 0 0;
  overflow: hidden;
  position: relative;

  > div,
  img {
    width: 100%;
    height: inherit;
  }

  > img {
    object-fit: cover;
    object-position: center center;
  }
`;

export const StyledContainerLink = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
  }
`;

export const StyledLinkText = styled(Text)`
  color: inherit;
`;
