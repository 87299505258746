import React from "react";
import styled from "styled-components";

import Badge, { BadgeColor } from "@finanzchef24gmbh/design-system/src/Badge";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockPromotions_ContentfulBlockPromotionsFragment } from "../../../../types/graphql-types";
import { trackCardLink } from "../../../utils/trackingUtils";
import Image from "../../Image";
import NewsletterRegistrationDialog from "../../NewsletterRegistrationDialog";
import { shouldShowNewsletterRegistration } from "../../NewsletterRegistrationDialog/NewsletterTwoGoForm";

const StyledLink = styled.a`
  width: 100%;
  height: 10rem;
  position: relative;
  z-index: 1;
  text-decoration: none;
`;

const StyledBadgeWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageContainer = styled.div`
  width: inherit;
  height: inherit;
  background-color: #fff;

  > div,
  img {
    width: inherit;
    max-width: 100%;
    height: inherit;
  }
`;

type ImageWithBadgeProps = {
  card: NonNullable<
    BlockPromotions_ContentfulBlockPromotionsFragment["cards"]
  >[0];
};

const ImageWithBadge: React.FC<ImageWithBadgeProps> = ({ card }) => {
  const dialogState = useDialogState();

  const link =
    card?.button?.assetDownload?.file?.url || card?.button?.buttonLink;

  return (
    <>
      {card?.image && card.button?.newsletterRegistration?.useHubSpotForm ? (
        <StyledLink
          href="#"
          onClick={(event) => {
            event.preventDefault();
            dialogState.open();
          }}
        >
          {card.badgeText ? (
            <StyledBadgeWrapper>
              <Badge color={card.badgeColor as BadgeColor}>
                <Text
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: card.badgeText,
                  }}
                  isOnDarkBackground
                />
              </Badge>
            </StyledBadgeWrapper>
          ) : null}

          <ImageContainer>
            {card.image.fixed || card.image.file?.url ? (
              <Image asset={card.image} loading="lazy" />
            ) : null}
          </ImageContainer>
        </StyledLink>
      ) : card?.image && link ? (
        <StyledLink
          href={link || ""}
          target={card.button?.openLinkInNewWindow ? "_blank" : "_self"}
          {...(card.button?.isNofollowLink && {
            rel: "nofollow noopener",
          })}
          {...(card.button?.assetDownload?.file?.url && {
            download: true,
          })}
          onClick={(event) => {
            if (
              shouldShowNewsletterRegistration(
                card.button?.newsletterRegistration,
              )
            ) {
              event.preventDefault();
              dialogState.open();
            }

            trackCardLink("promotions", link);
          }}
        >
          {card.badgeText ? (
            <StyledBadgeWrapper>
              <Badge color={card.badgeColor as BadgeColor}>
                <Text
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: card.badgeText,
                  }}
                  isOnDarkBackground
                />
              </Badge>
            </StyledBadgeWrapper>
          ) : null}

          <ImageContainer>
            {card.image?.fixed || card.image?.file?.url ? (
              <Image asset={card.image} loading="lazy" />
            ) : null}
          </ImageContainer>
        </StyledLink>
      ) : null}

      {card?.button?.newsletterRegistration ? (
        <NewsletterRegistrationDialog
          form={card.button.newsletterRegistration}
          dialogState={dialogState}
          downloadLink={link || ""}
        />
      ) : null}
    </>
  );
};

export default ImageWithBadge;
