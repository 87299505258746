import { graphql } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";

import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import InfoBox from "@finanzchef24gmbh/design-system/src/InfoBox";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  BlockDownloads_ContentfulBlockDownloadsFragment,
  NewsletterRegistration_ContentfulNewsletterRegistrationFragment,
} from "../../../types/graphql-types";
import useTableOfContentsInView from "../../hooks/useTableOfContentsInView";
import Center from "../Center";
import NewsletterRegistrationDialog from "../NewsletterRegistrationDialog";
import { shouldShowNewsletterRegistration } from "../NewsletterRegistrationDialog/NewsletterTwoGoForm";

const StyledMediaItemImage = styled.img`
  width: 4em;
  height: 4em;
`;

const StyledLinkWrapper = styled.div`
  &::before {
    content: "→ ";
    color: ${(props) => props.theme.palette.brand.dark};
  }
`;

const AssetListItem = styled.li`
  &::before {
    content: "→ ";
    color: ${(props) => props.theme.palette.brand.dark};
  }
`;

const FileDetailsText = styled(Text)`
  text-transform: uppercase;
`;

const StyledDownloadsBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

type FileDetailsProps = {
  file: {
    details?: {
      size?: number | null;
    } | null;
    fileName?: string | null;
  };
};

const FileDetails: React.FC<FileDetailsProps> = ({ file }) => {
  const size =
    file.details && file.details.size
      ? `${Math.round(file.details.size / 1000)}kb`
      : null;

  const extensionStartIndex = file.fileName?.lastIndexOf(".");
  const fileType = file.fileName?.substr(
    extensionStartIndex ? extensionStartIndex + 1 : 0,
  );

  const info = [size, fileType].filter(Boolean).join(" | ");

  return (
    <FileDetailsText as="span" isUncropped priority="secondary">
      [{info}]
    </FileDetailsText>
  );
};

type BlockDownloadsProps = {
  indexNumber?: number;
  contentBlock: BlockDownloads_ContentfulBlockDownloadsFragment;
  className?: string;
};

const BlockDownloads: React.FC<BlockDownloadsProps> = (props) => {
  const titleId = props.contentBlock.slug || props.contentBlock.id;
  const [downloadLink, setDownloadLink] = useState("");
  const dialogState = useDialogState();

  const setRefs = useTableOfContentsInView(
    props.contentBlock.includeInTableOfContents || false,
    props.contentBlock.slug,
  );

  const onDownloadClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string | null | undefined,
    newsletterRegistration:
      | NewsletterRegistration_ContentfulNewsletterRegistrationFragment
      | null
      | undefined,
  ) => {
    if (shouldShowNewsletterRegistration(newsletterRegistration)) {
      event.preventDefault();
      setDownloadLink(link!);
      dialogState.open();
    }
  };

  return (
    <StyledDownloadsBlock
      ref={setRefs}
      id={titleId}
      className={props.className}
    >
      <Spacings.Stack scale="gigantic">
        {props.contentBlock.title ? (
          <Text
            textStyle="headline3"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: `${props.indexNumber ? `${props.indexNumber}. ` : ""}${
                props.contentBlock.title
              }`,
            }}
          />
        ) : null}

        {props.contentBlock.newsletterRegistration?.useHubSpotForm ? (
          <InfoBox
            icon={
              props.contentBlock.icon?.file?.url ? (
                <StyledMediaItemImage
                  src={props.contentBlock.icon.file.url}
                  alt=""
                  loading="lazy"
                />
              ) : null
            }
          >
            <Text textStyle="headline5" as="h3">
              {props.contentBlock.downloadHeading}
            </Text>

            <StyledLinkWrapper>
              <Link
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  dialogState.open();
                }}
              >
                {props.contentBlock.linkText}
              </Link>
            </StyledLinkWrapper>
          </InfoBox>
        ) : (
          <Spacings.Stack as="ul" aria-labelledby={titleId}>
            {props.contentBlock.assetsGroups &&
              props.contentBlock.assetsGroups.map(
                (assetGroup, index) =>
                  assetGroup && (
                    <li key={index}>
                      <InfoBox
                        icon={
                          assetGroup.icon?.file?.url ? (
                            <StyledMediaItemImage
                              src={assetGroup.icon.file.url}
                              alt=""
                              loading="lazy"
                            />
                          ) : null
                        }
                      >
                        <Text textStyle="headline5" as="h3" id={assetGroup.id}>
                          {assetGroup.title}
                        </Text>

                        {assetGroup.assets && (
                          <Spacings.Stack
                            scale="tiny"
                            as="ul"
                            aria-labelledby={assetGroup.id}
                          >
                            {assetGroup.assets.map(
                              (asset) =>
                                asset?.file?.url && (
                                  <AssetListItem key={asset.id}>
                                    <Link
                                      href={asset.file.url}
                                      onClick={(event) =>
                                        onDownloadClick(
                                          event,
                                          asset?.file?.url,
                                          props.contentBlock
                                            .newsletterRegistration,
                                        )
                                      }
                                      target="_blank"
                                      download
                                    >
                                      {asset.title}
                                    </Link>{" "}
                                    <FileDetails file={asset.file} />
                                  </AssetListItem>
                                ),
                            )}
                          </Spacings.Stack>
                        )}
                      </InfoBox>
                    </li>
                  ),
              )}
          </Spacings.Stack>
        )}
      </Spacings.Stack>

      {props.contentBlock.newsletterRegistration ? (
        <NewsletterRegistrationDialog
          form={props.contentBlock.newsletterRegistration}
          dialogState={dialogState}
          downloadLink={downloadLink}
        />
      ) : null}
    </StyledDownloadsBlock>
  );
};

export const blockDownloadsFragment = graphql`
  fragment BlockDownloads_ContentfulBlockDownloads on ContentfulBlockDownloads {
    id
    title
    slug
    includeInTableOfContents
    assetsGroups {
      id
      title
      icon {
        file {
          url
        }
      }
      assets {
        id
        title
        file {
          url
          fileName
          details {
            size
          }
        }
      }
    }
    newsletterRegistration {
      ...NewsletterRegistration_ContentfulNewsletterRegistration
    }
    downloadHeading
    icon {
      file {
        url
      }
    }
    linkText
  }
`;

export default BlockDownloads;
