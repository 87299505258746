import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { StickyHeroHeader_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";
import Center from "../../../Center";
import Responsive from "../../../Responsive";
import KnownIndustryEntry from "./KnownIndustryEntry";
import UnknownIndustryEntry from "./UnknownIndustryEntry";

const Container = styled(Layer.Raised)<{ visible?: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  z-index: 3;
  margin: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${(props) => props.theme.spacings.small};
  padding-bottom: ${(props) => props.theme.spacings.small};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    bottom: auto;
    top: 0;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    /*
    * Fixes a Safari rendering intersection bug that causes an image with transform: rotateY to
    * partially overlap the dialog.
    * Example page: https://www.finanzchef24.de/versicherung/architekt
    * Solution: https://stackoverflow.com/a/18167565/5133172
    */
    transform: translateZ(100vw);
  }
`;

const ContentWrapper = styled(Spacings.Inline)`
  justify-content: space-between;

  > * + * {
    @media (max-width: ${(props) =>
        props.theme.layout.mobileMaxWidthBreakpoint}) {
      margin-left: 0;
    }
  }
`;

const StickyHeroHeaderTrigger = styled.div`
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

type CompoundComponents = {
  Trigger: typeof StickyHeroHeaderTrigger;
};

type StickyHeroHeaderProps = {
  contentBlock: StickyHeroHeader_ContentfulBlockHeroFragment;
  visible?: boolean;
};

type StickyHeroHeaderComponent = React.FC<StickyHeroHeaderProps> &
  CompoundComponents;

const StickyHeroHeader: StickyHeroHeaderComponent = ({
  contentBlock,
  visible,
}) => (
  <Container visible={visible} invertShadow>
    <Center>
      <ContentWrapper alignItems="center">
        <Spacings.Stack>
          <Responsive hideOn={["mobile"]}>
            {(contentBlock.stickyHeaderTitle ||
              contentBlock.ctaSectionTitle) && (
              <Text
                textStyle="headline5"
                as="p"
                dangerouslySetInnerHTML={{
                  __html: (contentBlock.stickyHeaderTitle ||
                    contentBlock.ctaSectionTitle)!,
                }}
              />
            )}

            {contentBlock.stickyHeaderSubtitle && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: contentBlock.stickyHeaderSubtitle,
                }}
              />
            )}
          </Responsive>
        </Spacings.Stack>

        {contentBlock.ctaButton?.buttonLink ||
        contentBlock.industryId ||
        !contentBlock.showIndustrySelection ? (
          <KnownIndustryEntry contentBlock={contentBlock} />
        ) : (
          <UnknownIndustryEntry contentBlock={contentBlock} />
        )}
      </ContentWrapper>
    </Center>
  </Container>
);

StickyHeroHeader.Trigger = StickyHeroHeaderTrigger;

export const stickyHeroHeaderFragments = graphql`
  fragment StickyHeroHeader_ContentfulBlockHero on ContentfulBlockHero {
    ctaSectionTitle
    ctaButton {
      buttonLink
    }
    industryId
    industrySelectionProductCombinationId
    stickyHeaderTitle
    stickyHeaderSubtitle
    showIndustrySelection

    ...KnownIndustryEntry_ContentfulBlockHero
    ...UnknownIndustryEntry_ContentfulBlockHero
  }
`;

export default StickyHeroHeader;
