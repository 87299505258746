import transparentize from "polished/lib/color/transparentize";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { createGlobalStyle, css, ThemeProvider } from "styled-components";

import designSystemTheme from "@finanzchef24gmbh/design-system/src/designTokens";
import getGlobalDesignSystemStyles from "@finanzchef24gmbh/design-system/src/globalStyles";
import { createHomepageTheme } from "../createHomePageTheme";
import InterUiRegularWoff from "../fonts/Inter-Regular.woff";
import InterUiRegularWoff2 from "../fonts/Inter-Regular.woff2";
import InterUiSemiBoldWoff from "../fonts/Inter-SemiBold.woff";
import InterUiSemiBoldWoff2 from "../fonts/Inter-SemiBold.woff2";
import { isTouchDevice } from "../utils/touchDevice";

const GlobalStyles = createGlobalStyle`
  ${getGlobalDesignSystemStyles()}

  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiSemiBoldWoff2}') format('woff2'),
          url('${InterUiSemiBoldWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: optional;
  }
  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiRegularWoff2}') format('woff2'),
          url('${InterUiRegularWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: optional;
  }

  ${(props) => {
    const backgroundGradient = `linear-gradient(${props.theme.palette.gray[50]} 600px, ${props.theme.palette.white} 800px);`;
    return css`
      body {
        font-family: "Inter UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-feature-settings: "tnum";
        min-height: 100vh;
        background: ${backgroundGradient};
        @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
          background: ${backgroundGradient};
          background-size: 100%, 100%;
          background-repeat: no-repeat;
          background-attachment: scroll, scroll, scroll;
        }
      }

      /* Adjust the cookie consent styling. The CSS for the cookie-consent used
         "!important" in some places, so we have to as well. In addition, we wrap
         all the overrides within the body element to achieve a higher specificity
         for our styles compared to the native styles from the cookie consent
         (which just use classes). */
      body {
        .cc_overlay_lock,
        .cookie-consent-preferences-overlay {
          background: ${props.theme.palette.backgrounds.transparent};
          overflow-y: scroll;
        }

        .cc_dialog.interstitial {
          position: absolute;
          max-width: ${props.theme.layout.containerWidth};
          margin: ${props.theme.spacings.big} auto;
          box-sizing: border-box;
          top: 0;
          left: ${props.theme.spacings.medium};
          right: ${props.theme.spacings.medium};
          padding: ${props.theme.spacings.medium};
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            padding: ${props.theme.spacings.big};
          }
        }

        .cc_css_reboot,
        .cookie-consent-preferences-overlay,
        .cookie-consent-preferences-overlay
          .cookie-consent-preferences-dialog
          .cc_cp_container
          .cc_cp_content
          ul.cc_cp_m_menu {
          font-family: "Inter UI", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        }

        .light.cc_dialog .cc_dialog_headline,
        .light.cc_dialog .cc_dialog_text,
        .light.cc_dialog button.cc_b_cp {
          color: ${props.theme.palette.gray.main};
        }

        .light.cookie-consent-preferences-overlay
          .cookie-consent-preferences-dialog
          .cc_cp_container {
          /* Override the font color */
          .cc_cp_head p,
          .cc_cp_head h2,
          .cc_cp_content .cc_cp_m_menu li[active="true"] a[href],
          .cc_cp_head .cc_cp_head_lang_selector select,
          .cc_cp_content .cc_cp_m_content,
          .cc_cp_content .cc_cp_m_content h1,
          .cc_cp_content .cc_cp_m_content p,
          .cc_cp_footer .cc_cp_f_powered_by {
            color: ${props.theme.palette.gray.main} !important;
          }
          .cc_cp_content .cc_cp_m_menu li a[href],
          .cc_cp_footer .cc_cp_f_powered_by a {
            color: ${props.theme.palette.gray.light};
          }
          .cc_cp_content .cc_cp_m_content a[href] {
            color: ${props.theme.palette.brand.main};
          }

          /* Override the background color */
          .cc_cp_content .cc_cp_m_content,
          .cc_cp_content .cc_cp_m_menu li[active="true"] {
            background: ${props.theme.palette.backgrounds.white};
          }
          .cc_cp_content .cc_cp_m_menu,
          .cc_cp_content .cc_cp_m_menu li,
          .cc_cp_footer {
            background: ${props.theme.palette.gray[50]};
          }

          /* Override the border color */
          .cc_cp_head,
          .cc_cp_content .cc_cp_m_menu li {
            border-bottom-color: ${props.theme.palette.gray[100]};
          }
          .cc_cp_content .cc_cp_m_menu li,
          .cc_cp_footer {
            border-top-color: ${props.theme.palette.gray[100]};
          }

          /* Override the border radius */
          .cc_cp_head {
            border-top-left-radius: ${props.theme.borders.radius.small}px;
            border-top-right-radius: ${props.theme.borders.radius.small}px;
          }
          .cc_cp_footer {
            border-bottom-left-radius: ${props.theme.borders.radius.small}px;
            border-bottom-right-radius: ${props.theme.borders.radius.small}px;
          }
        }

        .light.cc_dialog,
        .cookie-consent-preferences-overlay
          .cookie-consent-preferences-dialog
          .cc_cp_container {
          /* Dialog, DialogLayout and Layer.Overlay styles */
          background: ${props.theme.palette.backgrounds.white};
          border-radius: ${props.theme.borders.radius.small}px;
          box-shadow:
            0 2px 5px 0 ${transparentize(0.5, props.theme.palette.black)},
            0 6px 9px 0 ${transparentize(0.9, props.theme.palette.black)};
        }

        /* Make the headline look like textStyle="h3" */
        .light.cc_dialog .cc_dialog_headline {
          font-size: 1.5rem;
          font-weight: 400;
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            font-size: 1.875rem;
          }
        }

        /* Make the text smaller on mobile */
        .cc_dialog .cc_dialog_text {
          font-size: 0.875em;
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            font-size: 1em;
          }
        }

        /* Change the button styles to look like a primary button */
        .light.cc_dialog button.cc_b_ok,
        .light.cookie-consent-preferences-overlay
          .cookie-consent-preferences-dialog
          .cc_cp_container
          .cc_cp_footer
          .cc_cp_f_save
          button {
          opacity: 1;
          font-size: 1.125em;
          font-weight: 400;
          /* The padding is chosen to get as close as possible to a big button.
           We can't use the "regular" 16px here, because the text inside the
           button is not cropped. */
          padding: 9px 24px;

          /* Primary button styles */
          border: ${props.theme.borders.width.medium}px solid;
          border-radius: 2px;
          background-color: ${props.theme.palette.accent.main};
          border-color: ${props.theme.palette.accent.main};

          &:focus {
            box-shadow: 0 0 0 3px ${props.theme.palette.accent[200]};
          }
          &:not(:disabled):active {
            background-color: ${props.theme.palette.accent[700]};
            border-color: ${props.theme.palette.accent[700]};
          }
          &:not(:active):not(:disabled):hover {
            background-color: ${props.theme.palette.accent[600]};
            border-color: ${props.theme.palette.accent[600]};
          }
        }

        /* Change the button styles to look like a link */
        .light.cc_dialog button.cc_b_cp {
          all: inherit;
          display: block;
          padding-top: ${props.theme.spacings.medium};
          margin-left: 0 !important;
          cursor: pointer;
          color: ${props.theme.palette.brand.main};
          background-color: ${props.theme.palette.backgrounds.white};
          &:hover {
            text-decoration: underline;
          }
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            display: inline;
            padding-top: 0;
            padding-left: ${props.theme.spacings.big};
          }
        }

        /* Change the backgorund color of the toggles */
        .cookie-consent-preferences-overlay {
          input[type="checkbox"].checkbox_cookie_consent:checked
            + label:before {
            background: ${props.theme.palette.brand.light};
          }
        }
      }
    `;
  }}

  /*
  Overwrite the browsers defaults for these elements as some browsers e.g.
  Chrome set these elements to be system-ui which is more specific than the
  bodys font family.
  Also a CSS reset is not enough here, as it only sets the font to inherit
  which causes those elements to inherit the default browser styles again.
  */
  input, textarea, select, button {
    font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  strong {
    font-weight: bold;
  }
`;

type LayoutProps = {
  containerWidth: number;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children, containerWidth }) => {
  const homepageTheme = createHomepageTheme(designSystemTheme, containerWidth);

  useEffect(() => {
    if (!isTouchDevice()) {
      document.body.classList.add("device-can-hover");
    }
  }, []);

  return (
    <ThemeProvider theme={homepageTheme}>
      <GlobalStyles />
      <Helmet>
        <link
          rel="preload"
          href={InterUiRegularWoff2}
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href={InterUiSemiBoldWoff2}
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Helmet>
      {children}
    </ThemeProvider>
  );
};

export default Layout;
