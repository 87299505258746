import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import RichText from "../../RichText";
import { VisibleDepartment } from "./utils";

const StyledRichTextStack = styled(Spacings.Stack)<{ isAnimating?: boolean }>`
  opacity: ${(props) => (props.isAnimating ? 0 : 1)};
  transition: opacity 0.5s ease;
`;

const StyledFullEmployeesGrid = styled.div<{ isAnimating?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5.625rem, 1fr));
  gap: ${(props) => props.theme.spacings.big}
    ${(props) => props.theme.spacings.small};
  margin: ${(props) => props.theme.spacings.small} 0;
  text-align: center;
  opacity: ${(props) => (props.isAnimating ? 0 : 1)};
  transition: opacity 0.5s ease;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    grid-template-columns: repeat(auto-fill, 8.5rem);
    column-gap: ${(props) => props.theme.spacings.medium};
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;

  > div {
    border-radius: 50%;

    &:first-of-type {
      opacity: 0;
    }
  }

  &:hover {
    > div {
      &:first-of-type {
        opacity: 1;
      }

      &:last-of-type {
        opacity: 0;
      }
    }
  }
`;

const StyledProfessionalPhoto = styled(Img)<GatsbyImageFluidProps>`
  width: 100%;
  position: absolute !important; /* gatsby-image inlines relative position */
  top: 0;
  left: 0;
`;

const StyledJobTitle = styled(Text)`
  line-height: ${(props) => props.theme.typography.lineHeights.headline};
`;

type FullContentProps = {
  visibleDepartment?: VisibleDepartment;
  isAnimating: boolean;
};

const FullContent: React.FC<FullContentProps> = ({
  isAnimating,
  visibleDepartment,
}) => (
  <>
    {visibleDepartment?.aboveImagesRichText && (
      <StyledRichTextStack scale="huge" isAnimating={isAnimating}>
        <RichText
          document={visibleDepartment.aboveImagesRichText.json}
          priority="secondary"
          textStyle="bodyProlonged"
        />
      </StyledRichTextStack>
    )}
    <Spacings.Stack scale="huge">
      <StyledFullEmployeesGrid isAnimating={isAnimating}>
        {visibleDepartment?.employees.map((employee, employeeIndex) => {
          if (!employee) {
            return null;
          }

          return (
            <Spacings.Stack key={employeeIndex} scale="medium">
              <StyledImageWrapper>
                <Img
                  fluid={employee.funPhoto?.fluid as FluidObject}
                  alt={`Finanzchef24 - ${employee.name}`}
                />
                <StyledProfessionalPhoto
                  fluid={employee.professionalPhoto?.fluid as FluidObject}
                  alt={`Finanzchef24 - ${employee.name}`}
                />
              </StyledImageWrapper>
              <Spacings.Stack>
                <Text>{employee.name}</Text>
                {employee.jobTitle && (
                  <StyledJobTitle textStyle="caption" priority="secondary">
                    {employee.jobTitle}
                  </StyledJobTitle>
                )}
              </Spacings.Stack>
            </Spacings.Stack>
          );
        })}
      </StyledFullEmployeesGrid>
      {visibleDepartment?.belowImagesRichText && (
        <StyledRichTextStack scale="huge" isAnimating={isAnimating}>
          <RichText document={visibleDepartment.belowImagesRichText.json} />
        </StyledRichTextStack>
      )}
    </Spacings.Stack>
  </>
);

export default FullContent;
