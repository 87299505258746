import React from "react";

import BaseIndustrySearch from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearch";
import { HighlightedIndustry } from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearchDialog";

type IndustrySearchProps = {
  buttonText?: string;
  buttonTextMobile?: string;
  elementId?: string;
  highlightedIndustries?: HighlightedIndustry[];
  onHighlightedIndustrySelection: (
    highlightedIndustry: HighlightedIndustry,
  ) => void;
  placeholder?: string;
  selectedProductTypeIds?: string[];
  selectedProductTypeCombination?: string;
  showHighlightedIndustriesInTheAutocomplete?: boolean;
};

const IndustrySearch: React.FC<IndustrySearchProps> = ({
  buttonText,
  buttonTextMobile,
  elementId = "fc24-ac-input",
  highlightedIndustries,
  onHighlightedIndustrySelection,
  placeholder,
  selectedProductTypeIds,
  selectedProductTypeCombination,
  showHighlightedIndustriesInTheAutocomplete,
}) => (
  <BaseIndustrySearch
    mobileId={`${elementId}-dialog`}
    desktopId={elementId}
    messages={{
      button: buttonText || "Los geht's",
      buttonMobile: buttonTextMobile,
      dialogHeadline: "Ihre Tätigkeit:",
      errorMessageEmptyInput: "Bitte geben Sie Ihre Tätigkeit ein.",
      errorMessageEntryRouter:
        "Es gab ein Problem bei der Auswahl Ihrer Tätigkeit. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
      errorMessageQuery:
        "Es gab ein Problem beim Laden der Tätigkeiten. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
      errorTitle: "Es gibt ein Problem.",
      highlightedIndustriesHeadline: "Meist gewählte Tätigkeiten:",
      highlightedIndustriesTitle: "Meist gewählte Tätigkeiten:",
      loading: "Tätigkeiten werden geladen...",
      notFound: (inputValue) =>
        `Tätigkeit **${inputValue}** nicht gefunden? **Kontaktieren Sie uns!**`,
    }}
    backendUrl={`${process.env.GATSBY_ORIGIN}/backend`}
    placeholder={placeholder || "Bitte Tätigkeit eingeben..."}
    selectedProductTypeIds={selectedProductTypeIds}
    selectedProductTypeCombination={selectedProductTypeCombination}
    allowSubmitWithoutSelection
    highlightedIndustries={highlightedIndustries}
    onHighlightedIndustrySelection={onHighlightedIndustrySelection}
    showHighlightedIndustriesInTheAutocomplete={
      showHighlightedIndustriesInTheAutocomplete
    }
    onNavigation={(entryRouterResult) => {
      if (entryRouterResult.application === "customer-frontend") {
        location.assign(
          `${process.env.GATSBY_CUSTOMER_FRONTEND_ORIGIN || ""}${
            entryRouterResult.maskedPath || entryRouterResult.path
          }`,
        );
      } else {
        location.assign(
          `${process.env.GATSBY_ORIGIN || ""}/app/#${entryRouterResult.path}`,
        );
      }
    }}
    tenant="fc24"
  />
);

export default IndustrySearch;
