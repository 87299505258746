import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockCategories_ContentfulBlockCategoriesFragment } from "../../../../types/graphql-types";
import Center from "../../Center";
import RelativeLink from "../../RelativeLink";
import BigCards from "./BigCards";
import ImageWithButtonCards from "./ImageWithButtonCards";
import MiniCards from "./MiniCards";

const StyledCenter = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledHeading = styled(Text)<{ isHeadingCentered: boolean }>`
  ${(props) =>
    props.isHeadingCentered &&
    css`
      text-align: center;
    `}
`;

const StyledBlockLink = styled(RelativeLink)`
  text-align: center;
`;

const StyledBlock = styled.div`
  background-color: ${(props) => props.theme.palette.gray[25]};
  border-top: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.gray[100]};
  border-bottom: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.gray[100]};
  padding: ${(props) => props.theme.spacings.gigantic} 0;
`;

type BlockCategoriesProps = {
  contentBlock: BlockCategories_ContentfulBlockCategoriesFragment;
};

export type CardsProps = {
  cards: BlockCategoriesProps["contentBlock"]["cards"];
};

const BlockCategories: React.FC<BlockCategoriesProps> = (props) => {
  const blockContent = (
    <StyledCenter>
      <Spacings.Stack scale="huge">
        {props.contentBlock.heading && (
          <StyledHeading
            textStyle={
              props.contentBlock.layout === "big cards"
                ? "headline3"
                : "headline4"
            }
            as="h2"
            dangerouslySetInnerHTML={{
              __html: props.contentBlock.heading,
            }}
            isHeadingCentered={props.contentBlock.isHeadingCentered || false}
          />
        )}

        {props.contentBlock.layout === "big cards" && (
          <BigCards cards={props.contentBlock.cards} />
        )}

        {props.contentBlock.layout === "mini cards" && (
          <MiniCards cards={props.contentBlock.cards} />
        )}

        {props.contentBlock.layout === "image with button" && (
          <ImageWithButtonCards cards={props.contentBlock.cards} />
        )}

        {props.contentBlock.linkUrl && (
          <StyledBlockLink href={props.contentBlock.linkUrl}>
            {props.contentBlock.linkText}
          </StyledBlockLink>
        )}
      </Spacings.Stack>
    </StyledCenter>
  );

  return props.contentBlock.layout === "mini cards" ? (
    <StyledBlock>{blockContent}</StyledBlock>
  ) : (
    blockContent
  );
};

export const blockCategoriesFragment = graphql`
  fragment BlockCategories_ContentfulBlockCategories on ContentfulBlockCategories {
    heading
    isHeadingCentered
    cards {
      image {
        title
        description
        file {
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      linkText
      linkUrl
    }
    layout
    linkText
    linkUrl
  }
`;

export default BlockCategories;
