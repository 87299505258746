import React from "react";
import styled from "styled-components";

import Text, { TextProps } from "../Text";

export type FormLabelProps = TextProps &
  React.PropsWithoutRef<JSX.IntrinsicElements["p"]>;

const FormLabel: React.FC<React.PropsWithChildren<FormLabelProps>> = (
  props,
) => <Text textStyle="headline6" as="span" {...props} />;

const StyledFormLabel = styled(FormLabel)``;

export default StyledFormLabel;
