import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { useContext } from "react";
import styled from "styled-components";

import { CookieConsentContext } from "@finanzchef24gmbh/insurance-product-system/src/components/CookieConsentContextProvider";
import { Maybe, NoCookiesImageQuery } from "../../types/graphql-types";
import { GlobalStateContext } from "./GlobalStateContextProvider";
import Responsive from "./Responsive";

const PLAYER = {
  ALLOW_PARAMS: [
    "accelerometer",
    "autoplay",
    "encrypted-media",
    "gyroscope",
    "picture-in-picture",
  ],
  MODEST_BRANDING: 1,
  REL: 0,
};

const VideoWrapper = styled.div`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
`;

const VideoPlayer = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

type VideoProps = {
  src: string;
};

const alt =
  "Wiedergabe nicht möglich: Für das Abspielen des Videos sind zusätzliche Cookies erforderlich. Sie können diese in unseren Cookie-Einstellungen unter 'erweiterte Funktionalität' zulassen.";

const YouTubePlayer: React.FC<VideoProps> = ({ src }) => {
  const { cookieConsentLevels } = useContext(CookieConsentContext);
  const { globalState } = useContext(GlobalStateContext);

  const data = useStaticQuery<Maybe<NoCookiesImageQuery>>(graphql`
    query NoCookiesImage {
      noCookiesDesktopImage: contentfulAsset(
        contentful_id: { eq: "1kawTwuG2uc09Z73XGiL8d" }
      ) {
        fluid {
          ...GatsbyContentfulFluid_withWebp
          src
        }
      }
      noCookiesMobileImage: contentfulAsset(
        contentful_id: { eq: "4OfzlQ2J6RD1D8GUePMs4Q" }
      ) {
        fluid {
          ...GatsbyContentfulFluid_withWebp
          src
        }
      }
    }
  `);

  const noCookiesImages =
    data?.noCookiesDesktopImage?.fluid && data?.noCookiesMobileImage?.fluid;

  if (cookieConsentLevels?.functionality) {
    return (
      <VideoWrapper>
        <VideoPlayer
          src={`${src}?modestbranding=${PLAYER.MODEST_BRANDING}&rel=${PLAYER.REL}`}
          frameBorder="0"
          allow={PLAYER.ALLOW_PARAMS.join(";")}
          allowFullScreen
        />
      </VideoWrapper>
    );
  }

  if (cookieConsentLevels && noCookiesImages) {
    return (
      <>
        <Responsive hideOn={["mobile"]}>
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              globalState.cookieConsentLinkRef.current?.click();
            }}
          >
            <Img
              fluid={data?.noCookiesDesktopImage?.fluid as FluidObject}
              alt={alt}
            />
          </a>
        </Responsive>

        <Responsive hideOn={["tablet", "desktop"]}>
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              globalState.cookieConsentLinkRef.current?.click();
            }}
          >
            <Img
              fluid={data?.noCookiesMobileImage?.fluid as FluidObject}
              alt={alt}
            />
          </a>
        </Responsive>
      </>
    );
  }

  return null;
};

export default YouTubePlayer;
