import React from "react";
import styled, { css } from "styled-components";

import PhoneCircleIcon from "@finanzchef24gmbh/design-system/src/Icons/PhoneCircleIcon";
import PhoneIcon from "@finanzchef24gmbh/design-system/src/Icons/PhoneIcon";
import Link from "@finanzchef24gmbh/design-system/src/Link";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { StackProps } from "@finanzchef24gmbh/design-system/src/Stack";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import Responsive from "../Responsive";

const StyledLink = styled(Link)<{ isOnDarkBackground?: boolean }>`
  font-feature-settings: "tnum" off;
  text-decoration: none;

  ${(props) =>
    props.isOnDarkBackground &&
    css`
      svg {
        color: ${props.theme.palette.white};
      }

      &:hover svg {
        color: ${props.theme.palette.white};
      }
    `}
`;

const phoneNumber = "089 716 772 999";

type PhoneNumberProps = {
  alignItems?: StackProps["alignItems"];
  isOnDarkBackground?: boolean;
  isResponsive?: boolean;
};

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  isOnDarkBackground,
  alignItems = "flex-end",
  isResponsive = false,
}) => (
  <React.Fragment>
    {isResponsive ? (
      <Responsive hideOn={["desktop"]}>
        <StyledLink
          href={`tel:${phoneNumber.replace(/ /gi, "")}`}
          aria-label={`${phoneNumber} anrufen`}
          icon={<PhoneCircleIcon scale="huge" />}
          isOnDarkBackground={isOnDarkBackground}
        />
      </Responsive>
    ) : null}
    <Responsive hideOn={isResponsive ? ["mobile", "tablet"] : []}>
      <Spacings.Stack alignItems={alignItems}>
        <StyledLink
          href={`tel:${phoneNumber.replace(/ /gi, "")}`}
          aria-label={`${phoneNumber} anrufen`}
          icon={<PhoneIcon />}
          isOnDarkBackground={isOnDarkBackground}
        >
          <Text
            textStyle="headline4"
            as="span"
            isOnDarkBackground={isOnDarkBackground}
          >
            {phoneNumber}
          </Text>
        </StyledLink>
        <Text textStyle="caption" isOnDarkBackground={isOnDarkBackground}>
          Mo. - Fr. von 8 - 17 Uhr
        </Text>
      </Spacings.Stack>
    </Responsive>
  </React.Fragment>
);

export default PhoneNumber;
