import styled from "styled-components";

import Center from "@finanzchef24gmbh/design-system/src/Center";

export const CENTER_SIDE_PADDING = "medium";

export default styled(Center).attrs((props) => ({
  max: props.theme.layout.containerWidth,
}))`
  padding: 0 ${(props) => props.theme.spacings[CENTER_SIDE_PADDING]};
`;
