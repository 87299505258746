import React from "react";
import styled, { css } from "styled-components";

import VideoPlayer from "../../VideoPlayer";
import YouTubePlayer from "../../YouTubePlayer";

const MediaItemListsCardIllustrationVideoWrapper = styled.div<{
  isFullWidth?: boolean;
}>`
  width: 100%;

  ${(props) =>
    !props.isFullWidth &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        width: 50%;
      }
    `};
`;

type VideoProps = {
  videoPlayer: {
    videoUrl?: string | null;
    posterUrl?: string | null;
  };
  youtubeUrl?: string | null;
};

const Video: React.FC<VideoProps> = ({ videoPlayer, youtubeUrl }) =>
  youtubeUrl ? (
    <MediaItemListsCardIllustrationVideoWrapper isFullWidth>
      <YouTubePlayer src={youtubeUrl} />
    </MediaItemListsCardIllustrationVideoWrapper>
  ) : videoPlayer.videoUrl ? (
    <MediaItemListsCardIllustrationVideoWrapper>
      <VideoPlayer
        src={videoPlayer.videoUrl || undefined}
        poster={videoPlayer.posterUrl || undefined}
      />
    </MediaItemListsCardIllustrationVideoWrapper>
  ) : null;

export default Video;
