import { graphql, useStaticQuery } from "gatsby";
import transparentize from "polished/lib/color/transparentize";
import React, { useState } from "react";
import styled, { css, useTheme } from "styled-components";

import Dialog from "@finanzchef24gmbh/design-system/src/Dialog";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import QuestionCircleIcon from "@finanzchef24gmbh/design-system/src/Icons/QuestionCircleIcon";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  BadgeQuery,
  Maybe,
  SavingsBadge_ContentfulSavingsBadgeFragment,
} from "../../../../../types/graphql-types";
import Center from "../../../Center";
import Responsive from "../../../Responsive";
import Overlay from "./Overlay";
import { priceOrPercentage } from "./utils";

const StyledBadgeButtonContainer = styled.button`
  transform: rotate(-3deg);
  filter: drop-shadow(
    2px 4px 6px ${(props) => transparentize(0.7, props.theme.palette.black)}
  );
  background: transparent;
  border: 0;
  padding: 0;

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    cursor: pointer;
  }
`;

const StyledBadgeImg = styled.img`
  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    width: 6.75rem;
    height: 6.75rem;
  }
`;

const StyledSpacingsStack = styled(Spacings.Stack)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  transform: rotate(-3deg) translateY(-0.1875rem);
`;

const StyledBadgeText = styled(Text)`
  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    font-size: 0.85rem;
  }
`;

const StyledSavingsPercentage = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    margin-top: 0.4rem;
    font-size: 1.5rem;
  }
`;

const StyledSparenContainerInline = styled(Spacings.Inline)`
  margin-top: 0.4rem;

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    margin-top: 0.2rem;
  }
`;

const StyledQuestionCircleIcon = styled(QuestionCircleIcon)`
  color: ${(props) => props.theme.palette.white};

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    width: 0.75em;
  }
`;

const StyledOverlayResponsive = styled(Responsive)<{
  showOverlay: boolean;
  isOldHeroConcept?: boolean;
}>`
  position: absolute;
  top: -100%;
  left: 0;
  visibility: hidden;

  ${(props) =>
    props.isOldHeroConcept
      ? css`
          transform: translateX(-100%);
        `
      : css`
          transform: translateX(-95%);

          @media (min-width: 78.75em) {
            transform: translateX(-100%);
          }
        `};

  &::before {
    content: " ";
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background: ${(props) => props.theme.palette.white};
    transform: rotate(135deg) translateX(50%);
    position: absolute;
    box-shadow:
      -3px 0 2px -2px ${(props) => transparentize(0.9, props.theme.palette.black)},
      0px -3px 2px -2px
        ${(props) => transparentize(0.9, props.theme.palette.black)};
    top: 11rem;
    right: 0;
  }

  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    visibility: ${(props) => (props.showOverlay ? "visible" : "hidden")};
    width: calc(100vw - 1rem);
    max-width: 30rem;
    left: 100%;

    &::before {
      left: calc(100% - ${(props) => props.theme.spacings.massive});
    }
  }
`;

// TODO: remove isOldHeroConcept and related code once OldHeroConcept has been deleted
const StyledContainer = styled.div<{ isOldHeroConcept?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate(0.5rem, -3.2rem);

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    transform: translate(0.5rem, -5.2rem);
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    transform: translate(3.5rem, -5.2rem);

    ${(props) =>
      props.isOldHeroConcept &&
      css`
        left: 0;
        right: auto;
        transform: translate(-2.5rem, 7.5rem);
      `};

    &:hover ${StyledOverlayResponsive} {
      visibility: visible;
    }
  }
`;

const StyledLayerOverlay = styled(Layer.Overlay)`
  margin-right: ${(props) => props.theme.spacings.medium};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
`;

const StyledCenter = styled(Center)`
  padding: 0;
`;

const StyledCenterDialog = styled(StyledCenter)`
  max-width: 35.375rem;
`;

const StyledOverlayInset = styled(Spacings.Inset)`
  @media (max-width: ${(props) =>
      props.theme.layout.tabletMaxWidthBreakpoint}) {
    padding: ${(props) => props.theme.spacings.big};
  }
`;

type SavingsBadgeProps = {
  content: SavingsBadge_ContentfulSavingsBadgeFragment;
  isOldHeroConcept?: boolean;
};

const SavingsBadge: React.FC<SavingsBadgeProps> = ({
  content,
  isOldHeroConcept,
}) => {
  const theme = useTheme();
  const dialogState = useDialogState();
  const [showOverlay, setShowOverlay] = useState(false);

  const showOverlayOrDialog = () => {
    if (
      window.matchMedia(`(min-width: ${theme.layout.tabletBreakpoint})`).matches
    ) {
      setShowOverlay(!showOverlay);
    } else {
      dialogState.open();
    }
  };

  const data = useStaticQuery<Maybe<BadgeQuery>>(graphql`
    query Badge {
      badge: contentfulAsset(contentful_id: { eq: "69TmPyqk4o8g3SHC62whxZ" }) {
        file {
          url
        }
      }
    }
  `);

  const header = (
    <Text textStyle="headline4" as="span">
      {content.overlayHeading}
    </Text>
  );

  const savingsBadgeWithHtml = !!content.badgeTextAsHtml;
  const overlayVisibilitySettings: Array<"mobile" | "tablet" | "desktop"> =
    savingsBadgeWithHtml
      ? ["mobile", "tablet", "desktop"]
      : ["mobile", "tablet"];

  return (
    <>
      <StyledContainer isOldHeroConcept={isOldHeroConcept}>
        {data?.badge?.file?.url && (
          <StyledBadgeButtonContainer onClick={showOverlayOrDialog}>
            <StyledBadgeImg
              src={data.badge.file.url}
              width="123"
              height="123"
              alt=""
              loading="eager"
            />
            <StyledSpacingsStack scale="small" alignItems="center">
              {content.badgeTextAsHtml?.badgeTextAsHtml ? (
                <StyledBadgeText
                  as="span"
                  isOnDarkBackground
                  dangerouslySetInnerHTML={{
                    __html: content.badgeTextAsHtml?.badgeTextAsHtml,
                  }}
                />
              ) : (
                <>
                  <StyledBadgeText as="span" isOnDarkBackground>
                    Bis zu
                  </StyledBadgeText>
                  <StyledSavingsPercentage
                    textStyle="headline3"
                    as="span"
                    isOnDarkBackground
                  >
                    {priceOrPercentage({
                      expensivePrice: content.overlaySecondCardExpensivePrice,
                      favourablePrice: content.overlayFirstCardFavourablePrice,
                      priceToShow: content.badgePrice,
                    })}
                  </StyledSavingsPercentage>
                  <StyledSparenContainerInline scale="tiny" alignItems="center">
                    <StyledBadgeText as="span" isOnDarkBackground>
                      sparen
                    </StyledBadgeText>
                    <StyledQuestionCircleIcon scale="tiny" />
                  </StyledSparenContainerInline>
                </>
              )}
            </StyledSpacingsStack>
          </StyledBadgeButtonContainer>
        )}

        <StyledOverlayResponsive
          hideOn={overlayVisibilitySettings}
          showOverlay={showOverlay}
        >
          <StyledLayerOverlay>
            <StyledCenter>
              <StyledOverlayInset scale="huge">
                <Spacings.Stack scale="huge">
                  {content.overlayHeading && header}
                  <Overlay content={content} />
                </Spacings.Stack>
              </StyledOverlayInset>
            </StyledCenter>
          </StyledLayerOverlay>
        </StyledOverlayResponsive>
      </StyledContainer>

      <Dialog
        visible={dialogState.visible}
        onClose={dialogState.close}
        closeLabel="Dialog schließen"
        toggleRef={dialogState.toggle.ref}
        header={
          <StyledCenterDialog>
            {content.overlayHeading && header}
          </StyledCenterDialog>
        }
        content={
          <StyledCenterDialog>
            <Spacings.Stack scale="huge">
              <Overlay content={content} />
            </Spacings.Stack>
          </StyledCenterDialog>
        }
      />
    </>
  );
};

export const SavingsBadgeFragment = graphql`
  fragment SavingsBadge_ContentfulSavingsBadge on ContentfulSavingsBadge {
    badgePrice
    overlayHeading
    badgeTextAsHtml {
      badgeTextAsHtml
    }
    overlayDescriptionRichText {
      json
    }
    overlayFirstCardLogo {
      title
      description
      fixed(width: 109, height: 35, quality: 100) {
        ...GatsbyContentfulFixed_withWebp
      }
      file {
        url
      }
    }
    overlayFirstCardFavourablePrice
    overlaySecondCardLogo {
      title
      description
      fixed(width: 109, height: 35, quality: 100) {
        ...GatsbyContentfulFixed_withWebp
      }
      file {
        url
      }
    }
    overlaySecondCardExpensivePrice
    overlayPriceDifference
    overlayCollapsibleFirstColumnRichText {
      json
    }
    overlayCollapsibleSecondColumnRichText {
      json
    }
    date
  }
`;

export default SavingsBadge;
