import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockHero_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";
import RichTextLink from "../../../RichTextLink";

const StyledAddonBoxInsetSquish = styled(Spacings.InsetSquish)`
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.theme.palette.brand[100]};
  border-radius: 0 0 ${(props) => props.theme.borders.radius.medium}px
    ${(props) => props.theme.borders.radius.medium}px;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-top: ${(props) => props.theme.spacings.big};
    padding-bottom: ${(props) => props.theme.spacings.big};
  }
`;

const StyledSpacingsInline = styled(Spacings.Inline)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    align-items: center;
  }
`;

const StyledImg = styled.img`
  width: 2rem;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 1.5rem;
  }
`;

const StyledText = styled(Text)`
  line-height: ${(props) => props.theme.typography.lineHeights.headline};

  b {
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
  }
`;

const StyledRichTextLink = styled(RichTextLink)`
  display: inline-block;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
    margin-top: ${(props) => props.theme.spacings.small};
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledText>
        {children.filter(
          (child: React.ReactNodeArray | string) => child !== "",
        )}
      </StyledText>
    ),
    [INLINES.HYPERLINK]: (node, children: any) => (
      <StyledRichTextLink link={node.data.uri}>
        {children[0]}
      </StyledRichTextLink>
    ),
  },
};

type AddonBoxProps = {
  image: BlockHero_ContentfulBlockHeroFragment["addonBoxImage"];
  richTextSingleLine: BlockHero_ContentfulBlockHeroFragment["addonBoxSingleLineRichText"];
};

const AddonBox: React.FC<AddonBoxProps> = ({ image, richTextSingleLine }) => (
  <StyledAddonBoxInsetSquish scale="medium">
    <StyledSpacingsInline scale="small" alignItems="flex-start">
      {image?.file?.url && <StyledImg src={image.file.url} loading="eager" />}
      <Spacings.Stack scale="medium">
        {richTextSingleLine?.json &&
          documentToReactComponents(richTextSingleLine.json, options)}
      </Spacings.Stack>
    </StyledSpacingsInline>
  </StyledAddonBoxInsetSquish>
);

export default AddonBox;
