import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Responsive from "../../Responsive";
import RichText from "../../RichText";
import { VisibleDepartment } from "./utils";

const StyledRichTextStack = styled(Spacings.Stack)<{ isAnimating?: boolean }>`
  opacity: ${(props) => (props.isAnimating ? 0 : 1)};
  transition: opacity 0.5s ease;
`;

const StyledPreviewEmployeesGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5.625rem, 1fr));
  gap: ${(props) => props.theme.spacings.small};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 25%,
      white 90%
    );
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    grid-template-columns: repeat(auto-fill, 8.5rem);
    gap: ${(props) => props.theme.spacings.medium};
  }
`;

const StyledPreviewPhoto = styled(Img)<GatsbyImageFluidProps>`
  border-radius: 50%;
`;

type PreviewContentProps = {
  visibleDepartment?: VisibleDepartment;
};

const PreviewContent: React.FC<PreviewContentProps> = ({
  visibleDepartment,
}) => (
  <>
    {visibleDepartment?.aboveImagesRichText && (
      <StyledRichTextStack scale="huge">
        <RichText document={visibleDepartment.aboveImagesRichText.json} />
      </StyledRichTextStack>
    )}
    <StyledPreviewEmployeesGrid>
      {visibleDepartment?.employees.map((employee, employeeIndex) => {
        if (!employee) {
          return null;
        }

        const previewPhoto = (
          <StyledPreviewPhoto
            key={employeeIndex}
            fluid={employee.professionalPhoto?.fluid as FluidObject}
            alt={`Finanzchef24 - ${employee.name}`}
          />
        );

        if (employeeIndex > 8) {
          return <Responsive hideOn={["mobile"]}>{previewPhoto}</Responsive>;
        }

        return previewPhoto;
      })}
    </StyledPreviewEmployeesGrid>
  </>
);

export default PreviewContent;
