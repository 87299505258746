import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Widget from "@finanzchef24gmbh/widget/src/App";
import {
  BlockWidget_ContentfulBlockWidgetFragment,
  EkomiInfoQuery,
  EkomiSealQuery,
  Maybe,
} from "../../../types/graphql-types";
import Center from "../Center";

export type BlockWidgetProps = {
  contentBlock: BlockWidget_ContentfulBlockWidgetFragment;
};

const BlockWidget: React.FC<BlockWidgetProps> = (props) => {
  const data = useStaticQuery<Maybe<EkomiInfoQuery & EkomiSealQuery>>(graphql`
    query {
      ekomiInfo {
        fb_avg
      }
      ekomiSeal: contentfulAsset(
        contentful_id: { eq: "4mxgfVujaxmFHzWdFNPial" }
      ) {
        fluid(maxWidth: 36, quality: 36) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <Center intrinsic>
      <Widget
        homepageContentfulData={props.contentBlock}
        homepageEkomi={{
          imgSrc: data?.ekomiSeal?.fluid?.srcWebp || "",
          link: "https://www.ekomi.de/bewertungen-finanzchef24.html",
          rating: parseFloat(data?.ekomiInfo?.fb_avg || "") || 4.9,
          seal: "Gold",
        }}
        homepageOrigin={process.env.GATSBY_ORIGIN}
        customerFrontendOrigin={process.env.GATSBY_CUSTOMER_FRONTEND_ORIGIN}
        partnerName="internal"
      />
    </Center>
  );
};

export const BlockWidgetFragment = graphql`
  fragment BlockWidget_ContentfulBlockWidget on ContentfulBlockWidget {
    footerLinkText
    footerLinkUrl
    footerText
    heading
    tabs {
      ctaButtonText
      ctaSectionText {
        json
      }
      ctaSectionTitle
      icon
      industrySelectionProductCombinationId
      industrySelectionTarget
      quickIndustrySelections {
        industryId
        productTypeCombinationId
        title
      }
      tabLabel
    }
  }
`;

export default BlockWidget;
