import Img, { FluidObject } from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { HighlightsProps } from ".";
import { addExternalLinkAttributes } from "../../../utils/index";
import Markdown from "../../Markdown";

const StyledCard = styled(Card)`
  margin-top: -${(props) => props.theme.spacings.big};
  margin-left: ${(props) => props.theme.spacings.medium};
  margin-right: ${(props) => props.theme.spacings.medium};
  z-index: 1;
  max-width: 50ch;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    margin: 0;
  }
`;

const StyledImage = styled.div`
  order: -1;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    order: 0;
    width: 23em;
  }
  & img {
    border-radius: ${(props) => props.theme.borders.radius.medium}px;
  }
`;

const StyledVerticalHighlight = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
    align-items: center;
    > * + * {
      margin-left: -${(props) => props.theme.spacings.big};
    }
  }
`;

const Vertical: React.FC<HighlightsProps> = (props) => (
  <Spacings.Stack scale="big" alignItems="center">
    {props.contentBlock.title ? (
      <Text
        textStyle="headline4"
        as="h2"
        dangerouslySetInnerHTML={{ __html: props.contentBlock.title }}
      />
    ) : null}
    <StyledVerticalHighlight>
      <StyledImage>
        {props.contentBlock.firstHighlightImage?.fluid ? (
          <Img
            fluid={props.contentBlock.firstHighlightImage.fluid as FluidObject}
            alt={
              props.contentBlock.firstHighlightImage.description ||
              props.contentBlock.firstHighlightImage.title ||
              ""
            }
          />
        ) : null}
      </StyledImage>
      <StyledCard>
        <Spacings.Inset scale="huge">
          <Spacings.Stack scale="big">
            {props.contentBlock.firstHighlightTitle ? (
              <Text
                textStyle="headline5"
                as="h3"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.firstHighlightTitle,
                }}
              />
            ) : null}

            {props.contentBlock.firstHighlightDescription?.childMarkdownRemark
              ?.html ? (
              <Markdown
                priority="secondary"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock
                    .overrideFirstDefaultExternalLinkAttributes
                    ? props.contentBlock.firstHighlightDescription
                        .childMarkdownRemark.html
                    : addExternalLinkAttributes(
                        props.contentBlock.firstHighlightDescription
                          .childMarkdownRemark.html,
                      ),
                }}
              />
            ) : null}
          </Spacings.Stack>
        </Spacings.Inset>
      </StyledCard>
    </StyledVerticalHighlight>
    <StyledVerticalHighlight>
      <StyledCard>
        <Spacings.Inset scale="huge">
          <Spacings.Stack scale="big">
            {props.contentBlock.secondHighlightTitle ? (
              <Text
                textStyle="headline5"
                as="h3"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.secondHighlightTitle,
                }}
              />
            ) : null}

            {props.contentBlock.secondHighlightDescription?.childMarkdownRemark
              ?.html ? (
              <Markdown
                priority="secondary"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock
                    .overrideSecondDefaultExternalLinkAttributes
                    ? props.contentBlock.secondHighlightDescription
                        .childMarkdownRemark.html
                    : addExternalLinkAttributes(
                        props.contentBlock.secondHighlightDescription
                          .childMarkdownRemark.html,
                      ),
                }}
              />
            ) : null}
          </Spacings.Stack>
        </Spacings.Inset>
      </StyledCard>
      <StyledImage>
        {props.contentBlock.secondHighlightImage?.fluid ? (
          <Img
            fluid={props.contentBlock.secondHighlightImage.fluid as FluidObject}
            alt={
              props.contentBlock.secondHighlightImage.description ||
              props.contentBlock.secondHighlightImage.title ||
              ""
            }
          />
        ) : null}
      </StyledImage>
    </StyledVerticalHighlight>
    <StyledVerticalHighlight>
      <StyledImage>
        {props.contentBlock.thirdHighlightImage?.fluid ? (
          <Img
            fluid={props.contentBlock.thirdHighlightImage.fluid as FluidObject}
            alt={
              props.contentBlock.thirdHighlightImage.description ||
              props.contentBlock.thirdHighlightImage.title ||
              ""
            }
          />
        ) : null}
      </StyledImage>
      <StyledCard>
        <Spacings.Inset scale="huge">
          <Spacings.Stack scale="big">
            {props.contentBlock.thirdHighlightTitle ? (
              <Text
                textStyle="headline5"
                as="h3"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.thirdHighlightTitle,
                }}
              />
            ) : null}

            {props.contentBlock.thirdHighlightDescription?.childMarkdownRemark
              ?.html ? (
              <Markdown
                priority="secondary"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock
                    .overrideThirdDefaultExternalLinkAttributes
                    ? props.contentBlock.thirdHighlightDescription
                        .childMarkdownRemark.html
                    : addExternalLinkAttributes(
                        props.contentBlock.thirdHighlightDescription
                          .childMarkdownRemark.html,
                      ),
                }}
              />
            ) : null}
          </Spacings.Stack>
        </Spacings.Inset>
      </StyledCard>
    </StyledVerticalHighlight>

    {props.contentBlock.ctaButton?.buttonText &&
    props.contentBlock.ctaButton.buttonLink ? (
      <div>
        <a href={props.contentBlock.ctaButton.buttonLink} tabIndex={-1}>
          <Button buttonType="primary" scale="big">
            {props.contentBlock.ctaButton.buttonText}
          </Button>
        </a>
      </div>
    ) : null}
  </Spacings.Stack>
);

export default Vertical;
