import React from "react";
import styled from "styled-components";

import { BlockUsp_ContentfulBlockUspFragment } from "../../../../types/graphql-types";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 50vw;
`;

type ImageProps = {
  image: BlockUsp_ContentfulBlockUspFragment["image"];
};

const Image: React.FC<ImageProps> = ({ image }) => (
  <div>
    {image?.file?.url ? (
      <StyledImage src={image.file.url} loading="lazy" />
    ) : null}
  </div>
);

export default Image;
