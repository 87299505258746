import React from "react";

import Link from "@finanzchef24gmbh/design-system/src/Link";
import { isExternalLink } from "../utils";
import RelativeLink from "./RelativeLink";

type RichTextLinkProps = {
  children: React.ReactNode;
  className?: string;
  link: string;
  trackLink?: () => void;
};

const RichTextLink: React.FC<RichTextLinkProps> = ({
  children,
  className,
  link,
  trackLink,
}) =>
  isExternalLink(link) ? (
    <Link
      className={className}
      href={link}
      rel="nofollow noopener"
      target="_blank"
      onClick={trackLink}
    >
      {children}
    </Link>
  ) : (
    <RelativeLink className={className} href={link} onClick={trackLink}>
      {children}
    </RelativeLink>
  );

export default RichTextLink;
