import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockFaq_ContentfulBlockFaqFragment } from "../../../../types/graphql-types";
import useTableOfContentsInView from "../../../hooks/useTableOfContentsInView";
import Center from "../../Center";
import Question from "./Question";

const StyledFaqBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledImage = styled.img`
  align-self: flex-start;
`;

type BlockFAQProps = {
  indexNumber?: number;
  contentBlock: BlockFaq_ContentfulBlockFaqFragment;
  className?: string;
};

const BlockFAQ: React.FC<BlockFAQProps> = (props) => {
  const setRefs = useTableOfContentsInView(
    props.contentBlock.includeInTableOfContents || false,
    props.contentBlock.slug,
  );

  return (
    <StyledFaqBlock
      ref={setRefs}
      id={props.contentBlock.slug || props.contentBlock.id}
      className={props.className}
    >
      <Spacings.Stack scale={props.contentBlock.title ? "gigantic" : "massive"}>
        {props.contentBlock.title ? (
          <Text
            textStyle="headline3"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: `${props.indexNumber ? `${props.indexNumber}. ` : ""}${
                props.contentBlock.title
              }`,
            }}
          />
        ) : props.contentBlock.image?.file?.url ? (
          <StyledImage
            id={props.contentBlock.slug || undefined}
            src={props.contentBlock.image.file.url}
            alt={props.contentBlock.slug || undefined}
          />
        ) : null}

        <ul aria-describedby={props.contentBlock.slug || props.contentBlock.id}>
          {props.contentBlock.questions?.map((question, index) =>
            question ? (
              <Question
                key={index}
                question={question.question}
                answer={question.answer}
                overrideDefaultExternalLinkAttributes={
                  question.overrideDefaultExternalLinkAttributes
                }
                showAnswerByDefault={question.showAnswerByDefault}
              />
            ) : null,
          )}
        </ul>
      </Spacings.Stack>
    </StyledFaqBlock>
  );
};

export const BlockFAQFragment = graphql`
  fragment BlockFAQ_ContentfulBlockFaq on ContentfulBlockFaq {
    id
    title
    image {
      file {
        url
      }
    }
    slug
    includeInTableOfContents
    questions {
      ...FaqQuestion_ContentfulFaqQuestion
    }
  }
`;

export default BlockFAQ;
