import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockMediaItemListsCard_ContentfulBlockMediaItemListsCardFragment } from "../../../../types/graphql-types";
import useTableOfContentsInView from "../../../hooks/useTableOfContentsInView";
import Center from "../../Center";
import InsurerLogosGray from "../../InsurerLogosGray";
import Markdown from "../../Markdown";
import SwitchInlineStack from "../../SwitchInlineStack";
import MediaItemList from "./MediaItemList";
import Video from "./Video";

const StyledGrayBackground = styled.div`
  background-color: ${(props) => props.theme.palette.gray[50]};
  padding: ${(props) => props.theme.spacings.gigantic} 0;
`;

const StyledCenter = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledCenterWithCardView = styled(StyledCenter)<{ hasTitle: boolean }>`
  padding-top: ${(props) =>
    props.hasTitle ? props.theme.spacings.gigantic : props.theme.spacings.huge};
  padding-bottom: ${(props) =>
    props.hasTitle ? props.theme.spacings.gigantic : props.theme.spacings.huge};
`;

const StyledTitle = styled(Text)<{ isTitleCentred: boolean }>`
  ${(props) =>
    props.isTitleCentred &&
    css`
      text-align: center;
    `}
`;

const StyledCtaLink = styled.a`
  align-self: center;
`;

const StyledSwitchInlineStack = styled(SwitchInlineStack)<{
  hasIllustration: boolean;
}>`
  flex-direction: ${(props) =>
    props.hasIllustration ? "column-reverse" : "column"};
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledDescriptionMarkdown = styled(Markdown)`
  width: 100%;
`;

const StyledIllustration = styled.img`
  width: 10em;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

type MediaItemListsCardProps = {
  indexNumber?: number;
  contentBlock: BlockMediaItemListsCard_ContentfulBlockMediaItemListsCardFragment;
  className?: string;
};

const BlockMediaItemListsCard: React.FC<MediaItemListsCardProps> = (props) => {
  const setRefs = useTableOfContentsInView(
    props.contentBlock.includeInTableOfContents || false,
    props.contentBlock.slug,
  );

  const hasTopLevelContent = {
    illustration:
      props.contentBlock.illustration?.file?.contentType?.includes("image"),
    text: props.contentBlock.title || props.contentBlock.description,
    video:
      props.contentBlock.youtubeUrl ||
      props.contentBlock.illustration?.file?.contentType?.includes("video"),
  };

  const hasAnyTopLevelContent = Object.values(hasTopLevelContent).some(Boolean);

  const content = (
    <Spacings.Stack scale="massive">
      {props.contentBlock.showListOfInsurancePartners ? (
        <InsurerLogosGray />
      ) : null}

      <Spacings.Stack scale="gigantic">
        {hasAnyTopLevelContent ? (
          <Spacings.Stack scale="gigantic">
            {props.contentBlock.title ? (
              <StyledTitle
                isTitleCentred={props.contentBlock.isTitleCentred || false}
                textStyle="headline3"
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: `${
                    props.indexNumber ? `${props.indexNumber}. ` : ""
                  }${props.contentBlock.title}`,
                }}
              />
            ) : null}

            {props.contentBlock.description ||
            hasTopLevelContent.illustration ||
            hasTopLevelContent.video ? (
              <StyledSwitchInlineStack
                hasIllustration={hasTopLevelContent.illustration || false}
                breakOn="mobileBreakpoint"
                inlineScale="huge"
                stackScale="gigantic"
              >
                {props.contentBlock.description?.childMarkdownRemark?.html ? (
                  <StyledDescriptionMarkdown
                    priority="secondary"
                    textStyle="bodyProlonged"
                    dangerouslySetInnerHTML={{
                      __html:
                        props.contentBlock.description.childMarkdownRemark.html,
                    }}
                  />
                ) : null}

                {hasTopLevelContent.illustration ? (
                  <StyledIllustration
                    src={
                      props.contentBlock.illustration?.file?.url || undefined
                    }
                    alt={
                      props.contentBlock.illustration?.description ||
                      props.contentBlock.illustration?.title ||
                      ""
                    }
                    loading="lazy"
                  />
                ) : (
                  <Video
                    videoPlayer={{
                      posterUrl: props.contentBlock.thumbnail?.file?.url,
                      videoUrl: props.contentBlock.illustration?.file?.url,
                    }}
                    youtubeUrl={props.contentBlock.youtubeUrl}
                  />
                )}
              </StyledSwitchInlineStack>
            ) : null}
          </Spacings.Stack>
        ) : null}

        <Spacings.Stack scale="gigantic">
          {props.contentBlock.mediaItemLists?.map((mediaItemList) =>
            mediaItemList ? (
              <MediaItemList key={mediaItemList.id} content={mediaItemList} />
            ) : null,
          )}
        </Spacings.Stack>

        {props.contentBlock.ctaButton?.buttonText &&
        props.contentBlock.ctaButton.buttonLink ? (
          <StyledCtaLink href={props.contentBlock.ctaButton.buttonLink}>
            <Button buttonType="primary" scale="big" tabIndex={-1}>
              {props.contentBlock.ctaButton.buttonText}
            </Button>
          </StyledCtaLink>
        ) : null}
      </Spacings.Stack>
    </Spacings.Stack>
  );

  return props.contentBlock.isCardView ? (
    <Center
      ref={setRefs}
      id={props.contentBlock.slug || props.contentBlock.id}
      className={props.className}
    >
      <Card>
        <StyledCenterWithCardView hasTitle={Boolean(props.contentBlock.title)}>
          {content}
        </StyledCenterWithCardView>
      </Card>
    </Center>
  ) : props.contentBlock.hasGrayBackground ? (
    <StyledGrayBackground
      ref={setRefs}
      id={props.contentBlock.slug || props.contentBlock.id}
      className={props.className}
    >
      <StyledCenter>{content}</StyledCenter>
    </StyledGrayBackground>
  ) : (
    <StyledCenter
      ref={setRefs}
      id={props.contentBlock.slug || props.contentBlock.id}
      className={props.className}
    >
      {content}
    </StyledCenter>
  );
};

export const blockMediaItemListsCardFragment = graphql`
  fragment BlockMediaItemListsCard_ContentfulBlockMediaItemListsCard on ContentfulBlockMediaItemListsCard {
    id
    type: __typename
    title
    isTitleCentred
    slug
    includeInTableOfContents
    description {
      childMarkdownRemark {
        html
      }
    }
    showListOfInsurancePartners
    isCardView
    hasGrayBackground
    illustration {
      title
      description
      file {
        url
        contentType
      }
    }
    thumbnail {
      file {
        url
      }
    }
    mediaItemLists {
      id
      ...Contentful_ContentfulMediaItemList
    }
    ctaButton {
      buttonText
      buttonLink
    }
    youtubeUrl
  }
`;

export default BlockMediaItemListsCard;
