import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { KnownIndustryEntry_ContentfulBlockHeroFragment } from "../../../../../types/graphql-types";
import { trackStickyBarButton } from "../../../../utils/trackingUtils";
import FunnelEntryButton from "../../../FunnelEntryButton";
import Responsive from "../../../Responsive";
import MobileTextIcon from "./MobileTextIcon";

const StyledContainer = styled.div`
  @media (max-width: ${(props) =>
      props.theme.layout.mobileMaxWidthBreakpoint}) {
    width: 100%;
  }
`;

const StyledFunnelEntryButton = styled(FunnelEntryButton)`
  width: 100%;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props) => props.theme.spacings.small};
  margin: auto 0;
`;

type KnownIndustryEntryProps = {
  contentBlock: KnownIndustryEntry_ContentfulBlockHeroFragment;
};

const KnownIndustryEntry: React.FC<KnownIndustryEntryProps> = ({
  contentBlock,
}) => (
  <StyledContainer>
    <Responsive hideOn={["mobile"]}>
      <FunnelEntryButton
        target={contentBlock.industrySelectionTarget}
        tenant={contentBlock.tenant}
        buttonType="primary"
        url={contentBlock.ctaButton?.buttonLink}
        industryId={contentBlock.industryId}
        selectedProductTypeCombination={
          contentBlock.industrySelectionProductCombinationId
        }
        industryIdsForIndustrySelection={
          contentBlock.industryIdsForIndustrySelection
        }
        scale="medium"
        onClick={() => trackStickyBarButton("known-industry-entry")}
      >
        {contentBlock.stickyHeaderCTAButtonText ||
          contentBlock.ctaButton?.buttonText}
      </FunnelEntryButton>
    </Responsive>

    <Responsive hideOn={["tablet", "desktop"]}>
      <Spacings.Stack scale="small" alignItems="center">
        <StyledFunnelEntryButton
          target={contentBlock.industrySelectionTarget}
          tenant={contentBlock.tenant}
          buttonType="primary"
          url={contentBlock.ctaButton?.buttonLink}
          industryId={contentBlock.industryId}
          industryIdsForIndustrySelection={
            contentBlock.industryIdsForIndustrySelection
          }
          selectedProductTypeCombination={
            contentBlock.industrySelectionProductCombinationId
          }
          scale="big"
          onClick={() => trackStickyBarButton("known-industry-entry")}
        >
          {contentBlock.stickyHeaderCtaButtonTextMobile ||
            contentBlock.stickyHeaderCTAButtonText ||
            contentBlock.ctaButton?.buttonText}
          <StyledChevronRightIcon scale="big" isOnDarkBackground />
        </StyledFunnelEntryButton>

        <MobileTextIcon
          stickyHeaderIconMobile={contentBlock.stickyHeaderIconMobile}
          stickyHeaderTextMobile={contentBlock.stickyHeaderTextMobile}
        />
      </Spacings.Stack>
    </Responsive>
  </StyledContainer>
);

export const knownIndustryEntryFragments = graphql`
  fragment KnownIndustryEntry_ContentfulBlockHero on ContentfulBlockHero {
    tenant
    ctaButton {
      buttonText
      buttonLink
    }
    industryId
    industrySelectionProductCombinationId
    industryIdsForIndustrySelection
    industrySelectionTarget
    stickyHeaderCTAButtonText
    stickyHeaderCtaButtonTextMobile
    ...MobileTextIcon_ContentfulBlockHero
  }
`;

export default KnownIndustryEntry;
