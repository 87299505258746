import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import InfoBox from "@finanzchef24gmbh/design-system/src/InfoBox";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockInfoBox_ContentfulBlockInfoBoxFragment } from "../../../types/graphql-types";
import {
  addExternalLinkAttributes,
  addTargetBlankToAssetLinks,
} from "../../utils/index";
import Center from "../Center";
import Markdown from "../Markdown";

const StyledInfoBoxBlock = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledMediaItemImage = styled.img`
  width: 4em;
  height: 4em;
`;

const StyledMarkdown = styled(Markdown)`
  max-width: none;
`;

type BlockInfoboxProps = {
  contentBlock: BlockInfoBox_ContentfulBlockInfoBoxFragment;
};

const BlockInfoBox: React.FC<BlockInfoboxProps> = (props) => (
  <StyledInfoBoxBlock>
    <InfoBox
      icon={
        props.contentBlock.icon?.file?.url ? (
          <StyledMediaItemImage
            src={props.contentBlock.icon.file.url}
            alt=""
            loading="lazy"
          />
        ) : null
      }
    >
      {props.contentBlock.title ? (
        <Text
          textStyle="headline5"
          as="h2"
          dangerouslySetInnerHTML={{ __html: props.contentBlock.title }}
        />
      ) : null}
      {props.contentBlock.description?.childMarkdownRemark?.html ? (
        <StyledMarkdown
          priority="secondary"
          dangerouslySetInnerHTML={{
            __html: props.contentBlock.overrideDefaultExternalLinkAttributes
              ? addTargetBlankToAssetLinks(
                  props.contentBlock.description.childMarkdownRemark.html,
                )
              : addExternalLinkAttributes(
                  addTargetBlankToAssetLinks(
                    props.contentBlock.description.childMarkdownRemark.html,
                  ),
                ),
          }}
        />
      ) : null}
    </InfoBox>
  </StyledInfoBoxBlock>
);

export const infoboxFragment = graphql`
  fragment BlockInfoBox_ContentfulBlockInfoBox on ContentfulBlockInfoBox {
    id
    type: __typename
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    overrideDefaultExternalLinkAttributes
    icon {
      file {
        url
      }
    }
  }
`;

export default BlockInfoBox;
