import React from "react";

import { ButtonProps } from "@finanzchef24gmbh/design-system/src/Button";
import {
  Button_ContentfulButtonFragment,
  Scalars,
} from "../../types/graphql-types";
import Button from "./Button";

type RichTextButtonProps = {
  nodeFields: Scalars["JSON"];
  trackingUtil?: (_: string | null | undefined) => void;
  scale?: ButtonProps["scale"];
};

const RichTextButton: React.FC<RichTextButtonProps> = ({
  nodeFields,
  trackingUtil,
  scale,
}) => {
  const content: Button_ContentfulButtonFragment = {
    assetDownload: {
      file: {
        url: nodeFields.assetDownload?.fields?.file?.url,
      },
    },
    buttonIcon: nodeFields.buttonIcon,
    buttonIconPosition: nodeFields.buttonIconPosition,
    buttonLink: nodeFields.buttonLink,
    buttonText: nodeFields.buttonText,
    buttonType: nodeFields.buttonType,
    isNofollowLink: nodeFields.isNofollowLink,
    openLinkInNewWindow: nodeFields.openLinkInNewWindow,
  };

  if (nodeFields.newsletterRegistration) {
    const { fields: newsletterFields } = nodeFields.newsletterRegistration;

    content.newsletterRegistration = {
      buttonText: newsletterFields.buttonText,
      description: {
        childMarkdownRemark: {
          html: newsletterFields.description,
        },
      },
      errorDescription: {
        childMarkdownRemark: {
          html: newsletterFields.errorDescription,
        },
      },
      errorTitle: newsletterFields.errorTitle,
      formId: newsletterFields.formId,
      illustration: {
        file: {
          url: newsletterFields.illustration?.fields?.file?.url,
        },
      },
      inputFieldPlaceholder: newsletterFields.inputFieldPlaceholder,
      invalidEmailError: newsletterFields.invalidEmailError,
      label: newsletterFields.label,
      privacyPolicy: {
        childMarkdownRemark: {
          html: newsletterFields.privacyPolicy,
        },
      },
      title: newsletterFields.title,
    };
  }

  const link = content.assetDownload?.file?.url || content.buttonLink;

  return content.newsletterRegistration?.useHubSpotForm ? (
    <Button content={{ ...content }} scale={scale} />
  ) : (
    <Button
      content={{ ...content }}
      scale={scale}
      {...(trackingUtil && {
        trackLink: () => trackingUtil(link),
      })}
    />
  );
};

export default RichTextButton;
